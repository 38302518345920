// Models
import { Tag } from '../models/Tag';
import { RGBColor } from '../models/RGBColor';

/**
 * Create a list of tags with the quantity of each class, the color assigned, and the price and score if they exist.
 * @param {Array} predictions - Array of objects from image recognition.
 * @param {Array} resultTags - Array of tags with the class, alias, and color of each class.
 * @returns {Array} tagsList - Array of tags for canvas.
 */
const initElementsTagsList = (predictions, resultTags = []) => {
	const rgbObj = new RGBColor();
	const tagsMap = new Map();
	const tagDataMap = new Map(resultTags.map(tag => [tag.name, tag]));

	predictions.forEach(prediction => {
		const tagData = tagDataMap.get(prediction.clase);

		if (tagData) {
			prediction.clase = tagData.alias;
			prediction.color = [tagData.color.red, tagData.color.green, tagData.color.blue];
			prediction.data = tagData.data;
		}
		else {
			prediction.color = rgbObj.getColor();
		}

		if (prediction.score !== '0.0') {
			const existingTag = tagsMap.get(prediction.clase) || { quantity: 0, data: { color: prediction.color, data: prediction.data } };
			existingTag.quantity++;
			Object.assign(existingTag.data);

			if (prediction.precio) existingTag.data.price = prediction.precio;
			if (prediction.score) existingTag.data.score = prediction.score;

			tagsMap.set(prediction.clase, existingTag);
		}
	});

	return Array.from(tagsMap, ([name, { quantity, data }], index) => {
		const tag = new Tag(index, name, data.color);
		tag.quantity = quantity;
		Object.assign(tag, data);
		return tag;
	});
}

export { initElementsTagsList };
