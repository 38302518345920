import {
	CLEAR_ALL_FRAUDS_DATA,
	CLEAR_ALL_FRAUDS_ERRORS,
	CLEAR_FRAUD_SINGLE_ERROR,
	ALL_FRAUDS_LOADING,
	ALL_FRAUDS_GET,
	ALL_FRAUDS_ERROR,
	FRAUD_SEARCH_LOADING,
	FRAUD_SEARCH_GET,
	FRAUD_SEARCH_ERROR
} from '../actions/types';

const initialState = {
	search_payload_fraud_loading: false,
	all_frauds_loading: false,
	all_frauds: { count: 0, frauds: [] },
	payload_fraud: {},
	frauds_errors: {}
}

export default function stepsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_FRAUDS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_FRAUDS_ERRORS:
			return {
				...state,
				frauds_errors: {}
			}
		case CLEAR_FRAUD_SINGLE_ERROR:
			return {
				...state,
				frauds_errors: Object.keys(state.frauds_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.frauds_errors[key];
					}
					return acc;
				}, {})
			}
		case ALL_FRAUDS_LOADING:
			return {
				...state,
				all_frauds_loading: action.payload
			}
		case ALL_FRAUDS_GET:
			return {
				...state,
				all_frauds: {
					count: action.payload.count,
					frauds: action.payload.frauds
				}
			}
		case FRAUD_SEARCH_LOADING:
			return {
				...state,
				search_payload_fraud_loading: action.payload
			}
		case FRAUD_SEARCH_GET:
			return {
				...state,
				payload_fraud: action.payload
			}
		case FRAUD_SEARCH_ERROR:
		case ALL_FRAUDS_ERROR:
			return {
				...state,
				frauds_errors: {
					...state.frauds_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
