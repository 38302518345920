import React, { useEffect, useState } from 'react';

// Packages
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utils
import formatString from '../../utils/formatString';

const ReconTable = (props) => {
	const { resultInfo, tagsList } = props;

	const { t } = useTranslation();

	const [tagListCount, setTagListCount] = useState({ products: 0, count: 0 });

	useEffect(() => {
		if (tagsList.length > 0) {
			let count = 0;
			tagsList.forEach((item) => {
				count += item.quantity;
			})
			setTagListCount({ ...tagListCount, products: tagsList.length, count: count });
		}
	}, [tagsList]);

	return (
		<Table className='table text-center' responsive='lg'>
			<thead className='table-head'>
				<tr className='align-middle'>
					{Object.keys(resultInfo.custom).map((key, idx) => (
						<th key={idx} scope='col'>{formatString(key)}</th>
					))}
					<th>{t('reconTable.productCount')}</th>
					<th>{t('reconTable.frontCount')}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					{Object.values(resultInfo.custom).map((value, idx) => (
						<td key={idx}>{value}</td>
					))}
					<td>{tagListCount.products}</td>
					<td>{tagListCount.count}</td>
				</tr>
			</tbody>
		</Table>
	);
}

ReconTable.propTypes = {
	resultInfo: PropTypes.object.isRequired,
	tagsList: PropTypes.array.isRequired
}

export default ReconTable;
