import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// React Bootstrap
import { Container, Toast, ToastContainer } from 'react-bootstrap';

const ToastAlert = () => {
	const { alert_show, alert_info } = useSelector(state => state.alert);

	const [icon, setIcon] = useState('');

	useEffect(() => {
		switch (alert_info.icon) {
			case 'success':
				setIcon('bi bi-check-circle-fill ms-1 me-2');
			break;
			case 'danger':
				setIcon('bi bi-exclamation-triangle-fill ms-1 me-2');
			break;
			case 'info':
				setIcon('bi bi-info-circle-fill ms-1 me-2');
			break;
			default:
				break;
		}
	}, [alert_info]);

	return (
		<Container className='custom-toast'>
			<ToastContainer position='top-center'>
				<Toast
					className='d-inline-block'
					style={{ backgroundColor: alert_info.variant, border: alert_info.variant }}
					show={alert_show}
				>
					<Toast.Body className='text-white'>
						<i className={icon}></i> {alert_info.msg}
					</Toast.Body>
				</Toast>
			</ToastContainer>
		</Container>
	);
}

export default ToastAlert;
