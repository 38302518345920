import { fabric } from "fabric";

//http://fabricjs.com/fabric-intro-part-3
//Define class fabric.PercepthorImage from from fabric.Image
/**
 * Shows a small message with sweetalert2
 * @extends fabric.Image
 * @param  {Number}    id Id
 * @param  {Image}  image a HTMLImageElement
 * @param  {String} name Image name
 * @param  {String} extension Image extension
 * @param  {String} width Image width
 * @param  {String} height Image height
 * @param  {Array<Result>} listResults List of Result for this images from data source
 * @return {PercepthorImage}  PercepthorArticle Object
 */
export var PercepthorImage = fabric.util.createClass(fabric.Image, {
	type: 'percepthorImage',
	// initialize can be of type function(options) or function(property, options), like for text.
	// no other signatures allowed.
	initialize: function(options) { //Constructor
		options || (options = { });

		this.callSuper('initialize', options);
		this.set({id : 'img' });
		this.set({image : options.image || null });
		this.set('name', options.name || '');  //Atributos nuevos
		this.set('extension', options.extension || '' );
		this.set('width', options.width || null );  
		this.set('height', options.height || null );
		this.set({listResults : options.listResults || [] });
		this.set({left : 0 }); //Ajustamos arriba a la izq la imagen de manera permanente y deshabiliotamos los controles
		this.set({top : 0 });
		this.set({selectable : false }); //Deshabilitamos los controles
		this.set({hasBorders : false });
		this.set({hasControls : false });
		this.set({hasRotatingPoint : false });
	},

	toObject: function() {
		return fabric.util.object.extend(this.callSuper('toObject'), {
			id: this.get('id'),
			image: this.get('image'),
			name: this.get('name'),
			extension: this.get('extension'),
			width: this.get('width'),
			height: this.get('height'),
			listResults: this.get('listResults'),
		});
	},

	_render: function(ctx) { //This run when execute canvas.requestRenderAll() or its objects is modificaded
		this.callSuper('_render', ctx);
	},

	_fromObject : function(object, callback) {
		return fabric.Object._fromObject('PercepthorImage', object, callback);
	}
});
