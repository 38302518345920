import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { useTranslation } from 'react-i18next';

// Components
import SelectButton from '../utils/SelectButton';
import Alert from '../utils/Alert';
import LocationsTable from './LocationsTable';
import LocationRecords from './LocationRecords';
import PayloadInfo from '../payload/PayloadInfo';

// Actions
import { get_organizations, get_workspaces, get_modules, get_cycles } from '../../actions/organizationActions';
import { get_locations, locations_reset, get_location_records } from '../../actions/locationActions';
import { get_payload_info, clear_payload_info } from '../../actions/payloadActions';
import { get_indicators_progress } from '../../actions/indicatorsActions';
import { clear_issue_info, get_issue_info } from '../../actions/issueActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import errorExists from '../../utils/errorExists';

const Search = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isFirstRender = useRef(true);
  const payloadRef = useRef();
  const issueRef = useRef();

  const { user } = useSelector(state => state.auth);

  const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const { locations, error, location_loading, location_records } = useSelector(state => state.location);
  
  const { payload_info, payloads_errors } = useSelector(state => state.payload);

  const { issue_info, issues_error } = useSelector(state => state.issues);

	const { indicators_progress } = useSelector(state => state.indicators);

  const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);
  const [searchBy, setSearchBy] = useState(null);
  const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });

  const [locationCode, setLocationCode] = useState('');
  const [locationName, setLocationName] = useState('');
  const [payloadId, setPayloadId] = useState('');
  const [issueId, setIssueId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [locationInfo, setLocationInfo] = useState({ name: '', code: '' });

  const [displayLocations, setDisplayLocations] = useState(false);
  const [displayRecords, setDisplayRecords] = useState(false);
  const [displayPayloadInfo, setDisplayPayloadInfo] = useState(false);
  const [displayIssueInfo, setDisplayIssueInfo] = useState(false);

  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);

  useEffect(() => {
    dispatch(get_organizations());
  }, []);

  useEffect(() => {
    let lsOrganization = localStorage.getItem('organization');
    let lsWorkspace = localStorage.getItem('workspace');

    if (organizations.length <= 1) {
      setOrgFilters({
        ...orgFilters,
        organization: user.organization,
        workspace: lsWorkspace === null ? '' : lsWorkspace
      });
      localStorage.setItem('organization', user.organization);
    }
    else {
      setOrgFilters({
        ...orgFilters, 
        organization: lsOrganization === null ? '' : lsOrganization, 
        workspace: lsWorkspace === null ? '' : lsWorkspace
      });
    }
  }, [organizations]);

  useEffect(() => {
    if (orgFilters.organization !== '') {
      dispatch(get_workspaces({ organization: orgFilters.organization }));
      dispatch(get_modules({ organization: orgFilters.organization }));
    }

    if (orgFilters.workspace !== '') {
      if (orgFilters.module !== '') {
        dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
      }
    }
    else {
      setSearchBy(null);
      setDisplayRecords(false);
      setLocationCode('');
    }
  }, [orgFilters]);

  const onChangeSearchMode = (e) => {
    setDisplayLocations(false);
    setDisplayRecords(false);
    setDisplayPayloadInfo(false);
		setDisplayIssueInfo(false);

    setLocationName('');
    setLocationCode('');
    setPayloadId('');
    setLocationId('');
    setLocationInfo({ name: '', code: '' });
    dispatch(locations_reset());
    dispatch(clear_payload_info());
		dispatch(clear_issue_info());

    if (e !== null && e !== undefined){
      setSearchBy(e.value);
    }
    else {
      setSearchBy(null);
    }
  }

  const onChangeOrgFilters = (e, name) => {
    let filters = {...orgFilters};
    let keys = Object.keys(filters);
    
    let idx = keys.indexOf(name);
    for (let index = idx; index < keys.length; index++) {
      const key = keys[index];
      filters[key] = '';
    }

    if (e !== null){
      filters[name] = e.value;
    }

    localStorage.setItem('organization', filters.organization);
    localStorage.setItem('workspace', filters.workspace);

    setOrgFilters(filters);
  }

  useEffect(() => {
    if (!isEmpty(payload_info)) {
			dispatch(get_indicators_progress({
				location: payload_info?.location?._id?.$oid,
				route: payload_info?.route?._id?.$oid
			}));

      payloadRef.current?.scrollIntoView();
    }
  }, [payload_info]);

	useEffect(() => {
    if (!isEmpty(issue_info)) {
      issueRef.current?.scrollIntoView();
    }
  }, [issue_info]);

  const searchLocation = (e) => {
    e.preventDefault();

    setLocationId('');
    setLocationInfo({ name: '', code: '' });

    if (searchBy === 'name') {
      dispatch(get_locations({ organization: orgFilters.organization, name: locationName, skip: skip, limit: limit }));
      setDisplayLocations(true);
    }
    else if (searchBy === 'code') {
      dispatch(get_locations({ organization: orgFilters.organization, code: locationCode }));
    }
    else if (searchBy === 'payloadRef') {
      dispatch(clear_payload_info());
      dispatch(get_payload_info(payloadId));
      setDisplayPayloadInfo(true);
    }
		else { // issueRef
			dispatch(clear_issue_info());
			dispatch(get_issue_info(issueId));
			setDisplayIssueInfo(true);
		}
  }

  const searchLocationRecords = (e) => {
    e.preventDefault();
    
    if (orgFilters.organization === '' || orgFilters.workspace === '' || orgFilters.module === '' || searchBy === null) {
      setAlert({ display: true, type: 'danger', msg: t('search.errorAlertMsg') });
      setDisplayRecords(false);
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }
    setAlert(initialAlert);
    
    if (searchBy === 'name') {
      if (locationId === '') {
        setAlert({ display: true, type: 'danger', msg: t('search.errorLocationAlertMsg') });
        setDisplayRecords(false);
        window.scroll({ top: 0, behavior: 'smooth' });
        return;
      }
      setAlert(initialAlert);

      dispatch(get_location_records(locationId, { module: orgFilters.module, cycle: orgFilters.cycle }));
    }
    else if (searchBy === 'code') {
      let location_id = locations.locations[0]?._id;

      if (location_id === undefined || location_id === null || location_id === '') {
        setAlert({ display: true, type: 'danger', msg: t('search.errorLocationAlertMsg') });
        setDisplayRecords(false);
        window.scroll({ top: 0, behavior: 'smooth' });
        return;
      }
      setAlert(initialAlert);

      dispatch(get_location_records(location_id, { module: orgFilters.module, cycle: orgFilters.cycle }));
    }

    setDisplayRecords(true);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(get_locations({ organization: orgFilters.organization, name: locationName, skip: skip, limit: limit }));
  }, [skip]);

  return ( 
    <div id={localStorage.getItem('theme')}>
    <div className='container container-padding mt-3'>
      <h2 className='text-center'>{t('search.title')}</h2>
      <form onSubmit={searchLocationRecords}>
        {alert.display ? <Alert type={alert.type} msg={alert.msg} /> : null}

        {organizations.length > 1 &&
          <div className='mb-3'>
            <label className='form-label'>
              <i className='bi bi-house ms-1 me-2'></i> {t('search.filters.organization')}
            </label>
            <SelectButton
              options={organizations !== undefined ? organizations : ['No organizations']}
              name='organization'
              value={orgFilters.organization === '' ? null : orgFilters.organization}
              onChange={onChangeOrgFilters}
            />
            <div id='organizationHelp' className='form-text'>{t('search.filters.orgLegend')}</div>
          </div>
        }
        
        <div className='mb-3'>
          <label className='form-label'>
            <i className='bi bi-list ms-1 me-2'></i> {t('search.filters.workspace')}
          </label>
          <SelectButton
            options={workspaces !== undefined ? workspaces : ['No workspaces']}
            name='workspace'
            value={orgFilters.workspace === '' ? null : orgFilters.workspace}
            onChange={onChangeOrgFilters}
          />
          <div id='workspaceHelp' className='form-text'>{t('search.filters.workspaceLegend')}</div>
        </div>

        <div className='mb-3'>
          <label className='form-label'>
            <i className='bi bi-box2 ms-1 me-2'></i> {t('search.filters.module')}
          </label>
          <SelectButton
            options={modules !== undefined ? modules : ['No modules']}
            name='module'
            value={orgFilters.module === '' ? null : orgFilters.module}
            onChange={onChangeOrgFilters}
          />
          <div id='moduleHelp' className='form-text'>{t('search.filters.moduleLegend')}</div>
        </div>

        <div className='mb-3'>
          <label className='form-label'>
            <i className='bi bi-search ms-1 me-2'></i> {t('search.filters.searchFor')}
          </label>
          <SelectButton
            options={[
              {name: `${t('search.filters.searchForOptions.name')}`, value: 'name'},
              {name: `${t('search.filters.searchForOptions.code')}`, value: 'code'},
              {name: `${t('search.filters.searchForOptions.payloadRef')}`, value: 'payloadRef'},
              {name: `${t('search.filters.searchForOptions.issueRef')}`, value: 'issueRef'}
            ]}
            name='searchBy'
            value={searchBy}
            onChange={onChangeSearchMode}
          />
          <div id='searchForHelp' className='form-text'>{t('search.filters.searchForLegend')}</div>
        </div>

        {searchBy === 'name' &&
          <div>
            {locationId !== '' && 
              <Alert 
                type='success'
                msg={
                  <Fragment>
                    <strong>{t('search.nameOption.alert.msg')}</strong> — 
                    {t('search.nameOption.alert.name')} {locationInfo.name} - {t('search.nameOption.alert.code')} {locationInfo.code}
                  </Fragment>
                }
              />
            }
            
            <div className='mb-3'>
              <label className='form-label' htmlFor='storeNameInput'>
                <i className='bi bi-shop ms-1 me-2'></i> {t('search.nameOption.location')}
              </label>
              <div className='d-flex flex-row'>
                <input className='form-control me-2' id='storeNameInput' aria-describedby='storeNameHelp'
                  name={locationName} value={locationName} onChange={(e) => { setLocationName(e.target.value) }}
                />
                <button className='btn simple-btn' type='button' onClick={(e) => searchLocation(e)}>
                  <i className='bi bi-search'></i>
                </button>
              </div>
              <div className='form-text' id='storeNameHelp'>{t('search.nameOption.locationLegend')}</div>
            </div>
            
            {displayLocations
              ? <LocationsTable
                  locationsCount={locations.count}
                  locations={locations.locations}
                  skip={skip}
                  setSkip={setSkip}
                  limit={limit}
                  locationId={locationId}
                  setLocationId={setLocationId}
                  setLocationInfo={setLocationInfo}
                />
              : null
            }
          </div>
        }
        {searchBy === 'code' &&
          <div>
            {error.isError && error.type === 'locations'
              ? <Alert type='danger' msg={t('search.codeOption.errorAlert.msg')}/> 
              : locations.count > 0 && locationCode !== '' 
                ? <Alert type='success'
                    msg={
                      <Fragment>
                        <strong>{t('search.codeOption.alert.msg')}</strong> — {t('search.codeOption.alert.name')} {locations.locations[0]?.name} - 
                        {t('search.codeOption.alert.code')} {locations.locations[0]?.code}
                      </Fragment>
                    }
                  />
                : null
            }
            
            <div className='mb-3'>
              <label className='form-label' htmlFor='storeCodeInput'>
                <i className='bi bi-upc ms-1 me-2'></i> {t('search.codeOption.location')}
              </label>
              <div className='d-flex flex-row'>
                <input className='form-control me-2' id='storeCodeInput' aria-describedby='storeCodeHelp'
                  name={locationCode} value={locationCode} onChange={(e) => { setLocationCode(e.target.value) }}
                />
                <button className='btn simple-btn' type='button' onClick={(e) => searchLocation(e)}>
                  <i className='bi bi-search'></i>
                </button>
              </div>
              <div className='form-text' id='storeCodeHelp'>{t('search.codeOption.locationLegend')}</div>
            </div>
          </div>
        }
        {searchBy === 'payloadRef' &&
          <div>
            {errorExists(payloads_errors, 'payload_info')
              ? <Alert type='danger' msg={t('search.payloadRefOption.errorAlert.msg')}/> 
              : !isEmpty(payload_info)
                ? <Alert type='success' msg={<strong>{t('search.payloadRefOption.alert.msg')}</strong>}/>
                : null
            }
            
            <div className='mb-3'>
              <label className='form-label' htmlFor='payloadRefInput'>
                <i className='bi bi-fingerprint ms-1 me-2'></i> {t('search.payloadRefOption.input')}
              </label>
              <div className='d-flex flex-row'>
                <input className='form-control me-2' id='payloadRefInput' aria-describedby='payloadRefHelp'
                  name={payloadId} value={payloadId} onChange={(e) => { setPayloadId(e.target.value) }}
                />
                <button className='btn simple-btn' type='button' onClick={(e) => searchLocation(e)}>
                  <i className='bi bi-search'></i>
                </button>
              </div>
              <div className='form-text' id='payloadRefHelp'>{t('search.payloadRefOption.inputLegend')}</div>
            </div>
          </div>
        }
				{searchBy === 'issueRef' &&
          <div>
            {errorExists(issues_error, 'issue_info')
              ? <Alert type='danger' msg={t('search.issueRefOption.errorAlert.msg')}/> 
              : !isEmpty(issue_info)
                ? <Alert type='success' msg={<strong>{t('search.issueRefOption.alert.msg')}</strong>}/>
                : null
            }
            
            <div className='mb-3'>
              <label className='form-label' htmlFor='issueRefInput'>
                <i className='bi bi-fingerprint ms-1 me-2'></i> {t('search.issueRefOption.input')}
              </label>
              <div className='d-flex flex-row'>
                <input className='form-control me-2' id='issueRefInput' aria-describedby='payloadRefHelp'
                  name={issueId} value={issueId} onChange={(e) => { setIssueId(e.target.value) }}
                />
                <button className='btn simple-btn' type='button' onClick={(e) => searchLocation(e)}>
                  <i className='bi bi-search'></i>
                </button>
              </div>
              <div className='form-text' id='payloadRefHelp'>{t('search.issueRefOption.inputLegend')}</div>
            </div>
          </div>
        }

        <div className='mb-3'>
          <label className='form-label'>
            <i className='bi bi-arrow-repeat ms-1 me-2'></i> {t('search.filters.cycle')}
          </label>
          <SelectButton
            options={cycles !== undefined ? cycles : ['No cycles']}
            name='cycle'
            value={orgFilters.cycle === '' ? null : orgFilters.cycle}
            onChange={onChangeOrgFilters}
          />
          <div id='cycleHelp' className='form-text'>{t('search.filters.cycleLegend')}</div>
        </div>

        <div className='d-grid gap-2 col-2 mx-auto text-center mb-4'>
          <button className='btn submit-btn' type='submit' disabled={searchBy === 'payloadRef'}>{t('search.searchBtn')}</button>
        </div>
      </form>
      
      {displayRecords
				?	<LocationRecords
						loading={location_loading}
						records={location_records.records}
					/>
				:	null
			}

      {(!isEmpty(payload_info) && displayPayloadInfo) &&
        <PayloadInfo isPayload={true} payloadRef={payloadRef} payloadInfo={payload_info} indicatorsInfo={indicators_progress} />
      }
			{(!isEmpty(issue_info) && displayIssueInfo) &&
				<PayloadInfo isPayload={false} payloadRef={issueRef} payloadInfo={issue_info} />
			}
    </div>
    </div>
  );
}
 
export default Search;
