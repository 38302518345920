import {
	ISSUE_LOADING_TRUE,
	ISSUE_LOADING_FALSE,
	ISSUE_INFO_GET,
	ISSUE_INFO_ERROR,
	ISSUE_TAGS_GET,
	ISSUE_TAGS_ERROR,
	ALL_ISSUES_GET,
	ALL_ISSUES_ERROR
} from '../actions/types';

const initialState = {
	issue_loading: false,
	issue_info: {},
	issue_tags: { count: 0, tags: [] },
	all_issues: { count: 0, issues: [] },
	issues_error: {}
}

export default function issueReducer(state = initialState, action){
	switch(action.type){
		case ISSUE_LOADING_TRUE:
			return {
				...state,
				issue_loading: true,
				issues_error: {}
			}
		case ISSUE_LOADING_FALSE:
			return {
				...state,
				issue_loading: false
			}
		case ISSUE_INFO_GET:
			delete state.issues_error.issue_info
			return {
				...state,
				issue_info: action.payload
			}
		case ISSUE_TAGS_GET:
			delete state.issues_error.issue_tags
			return {
				...state,
				issue_tags: {
					count: action.payload.count,
					tags: action.payload.tags
				}
			}
		case ALL_ISSUES_GET:
			delete state.issues_error.all_issues
			return {
				...state,
				all_issues: {
					count: action.payload.count,
					issues: action.payload.issues.sort((a,b)=> (a.date.$date < b.date.$date ? 1 : -1))
				}
			}
		case ISSUE_INFO_ERROR:
		case ISSUE_TAGS_ERROR:
		case ALL_ISSUES_ERROR:
			return {
				...state,
				issues_error: {
					...state.issues_error, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
