import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_FRAUDS_DATA,
	CLEAR_ALL_FRAUDS_ERRORS,
	CLEAR_FRAUD_SINGLE_ERROR,
	ALL_FRAUDS_LOADING,
	ALL_FRAUDS_GET,
	ALL_FRAUDS_ERROR,
	FRAUD_SEARCH_LOADING,
	FRAUD_SEARCH_GET,
	FRAUD_SEARCH_ERROR
} from './types';

// Actions
import { alert_remove, alert_set } from './alertActions';
import { get_payload_info } from './payloadActions';

// Utils
import create_query_params from '../utils/create_query_params';

export const clear_all_fraud_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_FRAUDS_DATA
	});
}

export const clear_fraud_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_FRAUD_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_FRAUDS_ERRORS
		});
	}
}

export const get_all_frauds = (filters) => dispatch => {
	dispatch({
		type: ALL_FRAUDS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/fraud?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_FRAUDS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_FRAUDS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_FRAUDS_ERROR,
			payload: { type: 'all_frauds', msg: err.message }
		});

		dispatch({
			type: ALL_FRAUDS_LOADING,
			payload: false
		});
	})
}

export const search_payload_fraud = (filters) => dispatch => {
	dispatch({
		type: FRAUD_SEARCH_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/fraud/search?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: FRAUD_SEARCH_GET,
			payload: res.data
		});

		dispatch({
			type: FRAUD_SEARCH_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: FRAUD_SEARCH_ERROR,
			payload: { type: 'search_payload_fraud', msg: err.message }
		});

		dispatch({
			type: FRAUD_SEARCH_LOADING,
			payload: false
		});
	})
}

export const fraud_process = (body, payload_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/fraud/process';

	Axios.post(url, body)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#198754', 'success', i18next.t('fraud.alerts.success')));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(get_payload_info(payload_id));
		dispatch(search_payload_fraud({ payload: payload_id }));
	}).catch((err) => {
		// console.log(err);
		dispatch(alert_set('#dc3545', 'danger', `${i18next.t('fraud.alerts.error')} - ${err.response.status}`));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}
