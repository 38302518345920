import React from 'react';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Utils
import { getPayloadStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const StatusModal = (props) => {
  const { payload_history } = props;

  const { t } = useTranslation();

  return ( 
    <div className='modal fade' id='payloadHistory' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='payloadHistoryLabel' aria-hidden='true'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='payloadHistoryLabel'>
              <i className='bi bi-clock ms-1 me-2'></i> {t('statusModal.title')}
            </h5>
            <button type='button' className={clsx('btn-close', {
              'btn-close-white': localStorage.getItem('theme') === 'dark'
              })} data-bs-dismiss='modal' aria-label='Close'></button>
          </div>
          <div className='modal-body'>
            <table className='table'>
              <thead className='table-head'>
                <tr>
                  <th scope='col'>{t('statusModal.tableHeaders.prevStatus')}</th>
                  <th scope='col'>{t('statusModal.tableHeaders.currentStatus')}</th>
                  <th scope='col'>{t('statusModal.tableHeaders.date')}</th>
                </tr>
              </thead>
              <tbody>
                {payload_history.length > 0
                  ? payload_history.map((h, idx) => (
                    <tr key={idx}>
                      <td>{getPayloadStatusDesc(h.prev_status)}</td>
                      <td>{getPayloadStatusDesc(h.current_status)}</td>
                      <td>{epochToLocalDate(h.date.$date, localStorage.getItem('i18nextLng'))}</td>
                    </tr>
                  ))
                  : <tr>
                      <td className='text-center' colSpan={3}>{t('statusModal.errors.noHistory')}</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

StatusModal.prototypes = {
  payload_history: PropTypes.array.isRequired
}
 
export default StatusModal;
