import {
  PAYLOAD_LOADING_TRUE,
  PAYLOAD_LOADING_FALSE,
  PAYLOAD_INFO_GET,
  PAYLOAD_INFO_ERROR,
  PAYLOAD_HISTORY_GET,
  PAYLOAD_HISTORY_ERROR,
	ALL_PAYLOADS_LOADING,
  ALL_PAYLOADS_GET,
  ALL_PAYLOADS_ERROR,
  SET_PAYLOAD_IMAGES
} from '../actions/types';

const initialState = {
  payload_loading: false,
  payload_info: {},
  payload_history: [],
	all_payloads_loading: false,
  all_payloads: { count: 0, payloads: [] },
  payload_images: [],
  payloads_errors: {}
}

export default function payloadReducer(state = initialState, action){
  switch(action.type){
    case PAYLOAD_LOADING_TRUE:
      return {
        ...state,
        payload_loading: true
      }
    case PAYLOAD_LOADING_FALSE:
      return {
        ...state,
        payload_loading: false
      }
    case PAYLOAD_INFO_GET:
      delete state.payloads_errors.payload_info
      return {
        ...state,
        payload_info: action.payload
      }
    case PAYLOAD_HISTORY_GET:
      delete state.payloads_errors.payload_history
      return {
        ...state,
        payload_history: action.payload
      }
		case ALL_PAYLOADS_LOADING:
			return {
				...state,
				all_payloads_loading: action.payload
			}
    case ALL_PAYLOADS_GET:
      delete state.payloads_errors.all_payloads
      return {
        ...state,
        all_payloads: {
          count: action.payload.count,
          payloads: action.payload.payloads.sort((a,b)=> (a.date.$date < b.date.$date ? 1 : -1))
        }
      }
    case  SET_PAYLOAD_IMAGES:
      return {
        ...state,
        payload_images: action.payload
      }
    case PAYLOAD_INFO_ERROR:
    case PAYLOAD_HISTORY_ERROR:
    case ALL_PAYLOADS_ERROR:
      return {
        ...state,
        payloads_errors: {
          ...state.payloads_errors, 
          [`${action.payload.type}`]: { msg: action.payload.msg }
        }
      }
    default:
      return state;
  }
}
