import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR,
	SUBMODULES_GET,
	SUBMODULES_ERROR,
	CYCLES_GET,
	CYCLES_ERROR,
	AREAS_GET,
	AREAS_ERROR,
	ROUTE_CYCLE_GET,
	ORG_FILTERS_SET,
	SCREENS_DATA_GET,
	SCREENS_DATA_ERROR,
	APP_CONFIG_GET,
	APP_CONFIG_ERROR
} from '../actions/types';

const initialState = {
	organizations: [],
	organization_info: {},
	workspaces: [],
	modules: [],
	submodules: [],
	cycles: [],
	areas: [],
	route_cycle: null,
	orgFilters: { module: '', cycle: '', region: '', zone: '', depot: '', group: '' },
	screens_data: [],
	app_config: [],
	error: { isError: false, type: '', msg: '' }
}

export default function organizationReducer(state = initialState, action){
	switch(action.type){
		case ORGANIZATIONS_GET:
			return {
				...state,
				organizations: action.payload
			}
		case ORG_INFO_GET:
			return {
				...state,
				organization_info: action.payload
			}
		case WORKSPACES_GET:
			return {
				...state,
				workspaces: action.payload
			}
		case MODULES_GET:
			return {
				...state,
				modules: action.payload
			}
		case SUBMODULES_GET:
			return {
				...state,
				submodules: action.payload
			}
		case CYCLES_GET:
			return {
				...state,
				cycles: action.payload
			}
		case AREAS_GET:
			return {
				...state,
				areas: action.payload
			}
		case ROUTE_CYCLE_GET:
			return {
				...state,
				route_cycle: action.payload
			}
		case ORG_FILTERS_SET:
			return {
				...state,
				orgFilters: action.payload
			}
		case SCREENS_DATA_GET:
			return {
				...state,
				screens_data: action.payload
			}
		case APP_CONFIG_GET:
			return {
				...state,
				app_config: action.payload
			}
		case ORGANIZATIONS_ERROR:
		case ORG_INFO_ERROR:
		case WORKSPACES_ERROR:
		case MODULES_ERROR:
		case SUBMODULES_ERROR:
		case CYCLES_ERROR:
		case AREAS_ERROR:
		case SCREENS_DATA_ERROR:
		case APP_CONFIG_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
