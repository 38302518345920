import React, { useState, useEffect, Fragment } from 'react';

// Packages
import { Button, Stack } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utils
import isEmpty from '../../utils/isEmpty';
import formatString from '../../utils/formatString';

ChartJS.register(ArcElement, Tooltip, Legend);

const TagsChart = (props) => {
	const { appOrgConfig, tagsList, selectedResult, imageInfo } = props;

	const { t } = useTranslation();

	const [doughnutData, setDoughnutData] = useState({});

	const [filterOptions, setFilterOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState('productos');

	useEffect(() => {
		if (!isEmpty(imageInfo)) {
			// Get the organization configuration for display dashboard
			if (appOrgConfig) {
				const displayConfig = appOrgConfig?.data?.display_results?.find((result) => result.r_type_id === selectedResult.r_type_id);

				if (displayConfig) {
					setFilterOptions(displayConfig.tag_list_filters);
				}
				else {
					setFilterOptions([]);
				}
			}
		}
	}, [tagsList, imageInfo]);

	const groupDataByOption = (tagsList) => {
		return tagsList.reduce((acc, tag) => {
			const tagOptionData = tag['data'][selectedOption];
			
			if (!acc[tagOptionData]) {
				acc[tagOptionData] = 0;
			}
			acc[tagOptionData]+= tag.quantity;

			return acc;
		}, {});
	};

	const createDoughnutData = (tagsList) => {
		let labels = [];
		let data = [];
		let backgroundColors = [];
		let borderColors = [];

		const total = tagsList.reduce((sum, element) => sum += element.quantity, 0);

		if (selectedOption === 'productos') {
			tagsList.forEach((element) => {
				let name = element.className;
	
				labels.push(Number.parseFloat((element.quantity * 100 / total)).toFixed(2) + '% ' + name);
				data.push(element.quantity);
	
				backgroundColors.push(`rgba(${element.arrayRGBColor[0]}, ${element.arrayRGBColor[1]}, ${element.arrayRGBColor[2]},0.5)`);
				borderColors.push(`rgba(${element.arrayRGBColor[0]}, ${element.arrayRGBColor[1]}, ${element.arrayRGBColor[2]},1)`);
			});
		}
		else {
			let groupedData = groupDataByOption(tagsList);

			Object.keys(groupedData).forEach((key) => {
				labels.push(Number.parseFloat((groupedData[key] * 100 / total)).toFixed(2) + '% ' + key);
				data.push(groupedData[key]);
	
				let randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
				backgroundColors.push(`#${randomColor}80`);
				borderColors.push(`#${randomColor}`);
			});
		}
	
		const doughnutData = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: backgroundColors,
					borderColor: borderColors,
					borderWidth: 1
				},
			],
		};
		setDoughnutData(doughnutData);
	}

	useEffect(() => {
		createDoughnutData(tagsList);
	}, [tagsList, selectedOption]);

	return (
		<Fragment>
			{tagsList.length > 0 
				?	<div className="doughnut">
						<Stack
							className='doughnut-filters'
							direction="horizontal"
							gap={2}
						>
							{filterOptions.map((option, index) => (
								<Button
									key={index}
									className={clsx('doughnut-filters-btn', {
										'selected': selectedOption === option,
										'unselected': selectedOption !== option,
									})}
									onClick={() => setSelectedOption(option)}
								>
									{formatString(option)}
								</Button>
							))}
						</Stack>
						<Doughnut
							options={{
								responsive: true,
								tooltip: { enabled: true },
								plugins: {
									legend: { position: 'bottom', display: true },
									labels: {render: 'outside' }
								},
								maintainAspectRatio: false,
							}}
							data={ doughnutData }
						/>
					</div>
				:	<h3 className="text-center p-2">{t('tagChart.errors.noProducts')}</h3>
			}
		</Fragment>
	)
}

TagsChart.propTypes = {
	appOrgConfig: PropTypes.object,
	tagsList: PropTypes.array,
	selectedResult: PropTypes.object,
	imageInfo: PropTypes.object
};

export default TagsChart;
