import {
  ALL_IMAGES_LOADING,
	ALL_IMAGES_GET,
	ALL_IMAGES_ERROR,
  IMAGE_LOADING_TRUE,
  IMAGE_LOADING_FALSE,
  IMAGE_INFO_GET,
  IMAGE_INFO_ERROR,
	IMAGE_RESULT_LOADING,
  IMAGE_RESULT_GET,
  IMAGE_RESULT_ERROR,
	IMAGE_TAGS_LOADING,
  IMAGE_TAGS_GET,
  IMAGE_TAGS_ERROR
} from '../actions/types';

const initialState = {
	all_images_loading: false,
	all_images: { count: 0, images: [] },
  image_loading: false,
  image_info: {},
	image_result_loading: false,
  image_result: {},
	image_tags_loading: false,
  image_tags: {},
  error: { isError: false, type: '', msg: '' }
}

export default function imageReducer(state = initialState, action){
  switch(action.type){
		case ALL_IMAGES_LOADING:
			return {
				...state,
				all_images_loading: action.payload
			}
		case ALL_IMAGES_GET:
			return {
				...state,
				all_images: {
					count: action.payload.count,
					images: action.payload.images
				}
			}
    case IMAGE_LOADING_TRUE:
      return {
        ...state,
        image_loading: true
      }
    case IMAGE_LOADING_FALSE:
      return {
        ...state,
        image_loading: false
      }
    case IMAGE_INFO_GET:
      return {
        ...state,
        image_info: action.payload,
				error: { isError: false, type: '', msg: '' }
      }
		case IMAGE_RESULT_LOADING:
			return {
				...state,
				image_result_loading: true
			}
    case IMAGE_RESULT_GET:
      return {
        ...state,
				image_result_loading: false,
        image_result: action.payload
      }
		case IMAGE_TAGS_LOADING:
			return {
				...state,
				image_tags_loading: true
			}
    case IMAGE_TAGS_GET:
      return {
        ...state,
				image_tags_loading: false,
        image_tags: action.payload
      }
    case IMAGE_INFO_ERROR:
    case IMAGE_RESULT_ERROR:
    case IMAGE_TAGS_ERROR:
		case ALL_IMAGES_ERROR:
      return {
        ...state,
				image_tags_loading: false,
				image_result_loading: false,
        error: { isError: true, type: action.payload.type, msg: action.payload.msg }
      }
    default:
      return state;
  }
}
