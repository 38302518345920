import React, { useState, useContext } from 'react';

// Packages
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Context
import { ThemeContext } from '../../contexts/ThemeProvider';

const ThemeCard = (props) => {
	const { selected, imgSrc, alt, onClickFunction, themeText } = props;

	return (
		<div className='col'>
			<img className={clsx('theme-card', {
					'selected-theme': selected
				})}
				src={imgSrc} alt={alt} onClick={onClickFunction}
			/>
			<p className={clsx({
					'text-primary': selected
				})}
			>
				{selected ? <i className='bi bi-check-lg'></i> : null} {themeText}
			</p>
		</div>
	);
}

ThemeCard.prototypes = {
	selected: PropTypes.bool.isRequired,
	imgSrc: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	onClickFunction: PropTypes.func.isRequired,
	themeText: PropTypes.string.isRequired
}

const Configuration = () => {
	const { t } = useTranslation();

	const [ theme, onChangeTheme ] = useContext(ThemeContext);
	
	const [selectedTheme, setSelectedTheme] = useState('');

	useDidMountEffect(() => {
		onChangeTheme(selectedTheme);
	}, [selectedTheme]);

	return ( 
		<div className='modal fade' id='settings' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='settingsLabel' aria-hidden='true'>
			<div className='modal-dialog modal-xl modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='settingsLabel'>
							<i className='bi bi-gear-wide-connected ms-1 me-2'></i> {t('configuration.title')}
						</h5>
						<button type='button' className={clsx('btn-close', {
							'btn-close-white': theme === 'dark'
							})} data-bs-dismiss='modal' aria-label='Close'></button>
					</div>
					<div className='modal-body'>
						<div className='d-flex align-items-start'>
							<div className='nav flex-column nav-pills me-3' id='v-pills-tab' role='tablist' aria-orientation='vertical'>
								<button className='nav-link active' id='theme-tab' data-bs-toggle='pill' data-bs-target='#theme' type='button' role='tab' aria-controls='theme' aria-selected='false'>
									<i className='bi bi-palette-fill ms-1 me-2'></i> {t('configuration.options.appearance')}
								</button>
							</div>
							<div className='tab-content' id='v-pills-tabContent'>
								<div className='tab-pane fade show active' id='theme' role='tabpanel' aria-labelledby='theme-tab' tabIndex='0'>
									<h5>{t('configuration.options.appearance')}</h5>
									<p>{t('configuration.appearance.themes')}</p>
									<div className='d-flex row'>
										<ThemeCard 
											selected={theme === 'light'}
											imgSrc={process.env.PUBLIC_URL + '/assets/light-mode.png'}
											alt={theme}
											onClickFunction={() => setSelectedTheme('light')}
											themeText={t('configuration.appearance.light')}
										/>
										<ThemeCard 
											selected={theme === 'dark'}
											imgSrc={process.env.PUBLIC_URL + '/assets/dark-mode.png'}
											alt={theme}
											onClickFunction={() => setSelectedTheme('dark')}
											themeText={t('configuration.appearance.dark')}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
 
export default Configuration;
