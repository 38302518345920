import Swal from 'sweetalert2'
//import Noty from "noty";

/**
 * Shows a small message with noty
 * @param  {String} type It can be: success, error, info or warning
 * @param  {Number} timeout timeout miliseconds
 * @return {void}   It don't return anything
 */
export function SmallNoty(type, text, timeout=2600) {
	let title = ''
	switch (type) {
		case 'success':
			title = 'Correcto'
			break;
		case 'error':
			title = 'Error'
			break;
		case 'info':
			title = 'Info'
			break;
		case 'warning':
			title = 'Advertencia'
			break;
		default:
			break;
	}

	const Toast = Swal.mixin({
		toast: true,
		position: 'bottom',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})

	Toast.fire({
		icon: type,
		title: title,
		text: text
	})

}

/**
 * Shows a message with two buttons for cancel or confirm some action with sweetalert2
 * @param  {string} icon It can be: success, error or warning
 * @param  {string} title Title to show
 * @param  {string} text Text to show
 * @return {void}   It don't return anything
 */
export const ConfirmActionMessage = Swal.mixin({
	showCancelButton: true,
	reverseButtons: true,
	confirmButtonColor: '#00920F',
	cancelButtonColor: '#D60000',
	confirmButtonText: 'Si, realizar!',
	cancelButtonText: 'Cancelar',
})

/*//Examples use
	SmallNoty('success', `Se han cargado [${contador}] artículos desde los archivos del proyecto`)
	SmallNoty('info', `La imagen no cuenta con artículos anteriores`)
	SmallNoty('error', `mensaje de error`)

	ConfirmActionMessage.fire({
		icon: 'warning',
		title: 'Advertencia!',
		text: "Desea eliminar los objetos seleccionados?, no podran ser recuperados!",
	}).then((result) => {
		if (result.isConfirmed) {
			object._objects.forEach(element => {
				canvas.remove(element);
			});
			SmallMessage.fire({
				icon: 'success',
				title: 'Correcto!',
				text: 'Los objetos han sido eliminados.',
			})
		} 
	})   
*/