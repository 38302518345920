import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Card, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utils
import { createHexcolor, getColorContrast } from '../../utils/colorsHandler';

const PayloadRating = (props) => {
	const { organizationId, score, indicators } = props;

	const { t } = useTranslation();

	const { user } = useSelector(state => state.auth);

	const { app_config } = useSelector(state => state.organization);

	const [indicatorsData, setIndicatorsData] = useState([]);
	
	useEffect(() => {
		if (indicators.length > 0) {
			if (user?.app_session) {
				// Session from Visual Inspector App
				let indicatorsToShow = null;
	
				const appOrgConfig = app_config.find((config) => config.organization.$oid === organizationId);
				if (appOrgConfig) {
					indicatorsToShow = appOrgConfig?.data?.payload_indicators;
				}

				indicators.map((group) => {
					group.indicators = group.indicators.filter((ind) => {
						const checkIndicatorsToShow = Array.isArray(indicatorsToShow) && indicatorsToShow.length > 0;
						return ind.score !== undefined && (!checkIndicatorsToShow || indicatorsToShow.includes(ind.name));
					});
				});
			}
			else {
				// Session from Web Browser
				indicators.map((group) => {
					group.indicators = group.indicators.filter(ind => ind.score !== undefined);
				});
			}

			const indicatorsFiltered = indicators.filter(group => group.indicators.length > 0);
			setIndicatorsData(indicatorsFiltered);
		}
	}, [indicators, organizationId, app_config]);

	return (
		<Container className='text-center p-0' fluid>
			<div className='d-flex justify-content-center mt-3'>
				<Card style={{ width: '18rem' }}>
					<Card.Body>
						<Card.Title>{t('payload.payloadScore.score')}</Card.Title>
						<Card.Text className='fs-3'>{score}</Card.Text>
					</Card.Body>
				</Card>
			</div>

			{indicatorsData.map((group, groupIdx) => (
				<div className='mb-4' key={groupIdx}>
					<h6 className='mt-4' style={{ color: createHexcolor(group?.group.color) }}>{group?.group?.name}</h6>
					<Table responsive='lg'>
						<thead>
							<tr 
								style={{
									verticalAlign: 'middle',
									backgroundColor: createHexcolor(group?.group.color),
									color: getColorContrast(group?.group.color)
								}}
							>
								{group?.indicators.map((ind, indIdx) => (
									<th key={indIdx}>{ind?.name}</th>
								))}
							</tr>
						</thead>
						<tbody>
							<tr>
								{group?.indicators.map((ind, indIdx) => (
									<td key={indIdx}>{ind?.score}</td>
								))}
							</tr>
						</tbody>
					</Table>
				</div>
			))}
		</Container>
	);
}

PayloadRating.propTypes = {
	organizationId: PropTypes.string,
	score: PropTypes.number.isRequired,
	indicators: PropTypes.array.isRequired
}

export default PayloadRating;
