import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { useTranslation } from 'react-i18next';

// Components
import SelectButton from '../utils/SelectButton';
import Alert from '../utils/Alert';
import FraudsTable from './FraudsTable';

// Actions
import { get_organizations, get_workspaces, get_submodules, get_modules, get_cycles, get_areas } from '../../actions/organizationActions';
import { get_all_frauds } from '../../actions/fraudActions';

const AllFrauds = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const isFirstRender = useRef(true);

	const { user } = useSelector(state => state.auth);

	const { organizations, workspaces, modules, submodules, cycles, areas } = useSelector(state => state.organization);

	const { all_frauds_loading, all_frauds } = useSelector(state => state.frauds); 
	
	const [alert, setAlert] = useState(false);
	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', submodule: '', cycle: '' });
	// const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '', route: '' });
	const [displayPayloads, setDisplayPayloads] = useState(false);
	const [cycle, setCycle] = useState({});

	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let lsOrganization = localStorage.getItem('organization');
		let lsWorkspace = localStorage.getItem('workspace');

		if (organizations.length <= 1) {
			setOrgFilters({
				...orgFilters,
				organization: user.organization,
				workspace: lsWorkspace === null ? '' : lsWorkspace
			});
			localStorage.setItem('organization', user.organization);
		}
		else {
			setOrgFilters({
				...orgFilters, 
				organization: lsOrganization === null ? '' : lsOrganization, 
				workspace: lsWorkspace === null ? '' : lsWorkspace
			});
		}
	}, [organizations]);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.module !== '') {
			dispatch(get_submodules({ module: orgFilters.module }));
		}

		if (orgFilters.workspace !== '') {
			if (orgFilters.module !== '') {
				dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
			}
		}
		else {
			setDisplayPayloads(false);
		}

		// if (orgFilters.cycle !== '') {
		// 	dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
		// }
	}, [orgFilters]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
		}

		if (e !== null){
			filters[name] = e.value;
		}
		
		setDisplayPayloads(false);
		let cycleFound = cycles.find(cycle => cycle._id.$oid === filters.cycle);
		setCycle(cycleFound === undefined ? {} : cycleFound);

		localStorage.setItem('organization', filters.organization);
		localStorage.setItem('workspace', filters.workspace);

		setOrgFilters(filters);
	}

	// useEffect(() => {
	// 	if (orgFilters.cycle !== '') {
	// 		dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
	// 	}
	// }, [areaFilters]);

	// const onChangeAreaFilters = (e, name) => {
	// 	const filters = {...areaFilters};
	// 	let keys = Object.keys(filters);
		
	// 	if (e === null){
	// 		let idx = keys.indexOf(name);
	// 		for (let index = idx; index < keys.length; index++) {
	// 			const key = keys[index];
	// 			filters[key] = '';
	// 		}
	// 	}
	// 	else {
	// 		filters[name] = e.value;
	// 	}

	// 	setAreaFilters(filters);
	// }

	const searchFrauds = (e) => {
		e.preventDefault();

		if (orgFilters.organization === '' || orgFilters.workspace === '' || orgFilters.module === '' || orgFilters.submodule === '') {
			setAlert(true);
			window.scroll({ top: 0, behavior: 'smooth' });
			return;
		}
		setAlert(false);

		dispatch(get_all_frauds({ ...orgFilters, skip, limit, sort: true }));

		setDisplayPayloads(true);
	}

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		dispatch(get_all_frauds({ ...orgFilters, skip, limit, sort: true }));
	}, [skip]);

	return ( 
		<div id={localStorage.getItem('theme')}>
		<div className='container container-padding mt-3' style={{marginBottom: '7rem'}}>
			<h2 className='text-center'>{t('fraud.allFraudsTitle')}</h2>
			<form>
				{alert ? <Alert type='danger' msg={t('allPayloads.errorAlertMsg')}/> : null}

				{organizations.length > 1 &&
					<div className='mb-3'>
						<label className='form-label'>
							<i className='bi bi-house ms-1 me-2'></i> {t('allPayloads.filters.organization')}
						</label>
						<SelectButton
							options={organizations !== undefined ? organizations : ['No organizations']}
							name='organization'
							value={orgFilters.organization === '' ? null : orgFilters.organization}
							onChange={onChangeOrgFilters}
						/>
						<div id='organizationHelp' className='form-text'>{t('allPayloads.filters.orgLegend')}</div>
					</div>
				}

				<div className='mb-3'>
					<label className='form-label'>
						<i className='bi bi-list ms-1 me-2'></i> {t('allPayloads.filters.workspace')}
					</label>
					<SelectButton
						options={workspaces !== undefined ? workspaces : ['No workspaces']}
						name='workspace'
						value={orgFilters.workspace === '' ? null : orgFilters.workspace}
						onChange={onChangeOrgFilters}
					/>
					<div id='workspaceHelp' className='form-text'>{t('allPayloads.filters.workspaceLegend')}</div>
				</div>

				<div className='mb-3'>
					<label className='form-label'>
						<i className='bi bi-box2 ms-1 me-2'></i> {t('allPayloads.filters.module')}
					</label>
					<SelectButton
						options={modules !== undefined ? modules : ['No modules']}
						name='module'
						value={orgFilters.module === '' ? null : orgFilters.module}
						onChange={onChangeOrgFilters}
					/>
					<div id='moduleHelp' className='form-text'>{t('allPayloads.filters.moduleLegend')}</div>
				</div>

				<div className='mb-3'>
					<label className='form-label'>
						<i className='bi bi-box-seam ms-1 me-2'></i> {t('allPayloads.filters.submodule')}
					</label>
					<SelectButton
						options={submodules !== undefined ? submodules : ['No submodules']}
						name='submodule'
						value={orgFilters.submodule === '' ? null : orgFilters.submodule}
						onChange={onChangeOrgFilters}
					/>
					<div id='submoduleHelp' className='form-text'>{t('allPayloads.filters.submoduleLegend')}</div>
				</div>

				<div className='mb-3'>
					<label className='form-label'>
						<i className='bi bi-arrow-repeat ms-1 me-2'></i> {t('allPayloads.filters.cycle')}
					</label>
					<SelectButton
						options={cycles !== undefined ? cycles : ['No cycles']}
						name='cycle'
						value={orgFilters.cycle === '' ? null : orgFilters.cycle}
						onChange={onChangeOrgFilters}
					/>
					<div id='cycleHelp' className='form-text'>{t('allPayloads.filters.cycleLegend')}</div>
				</div>

				{/* <div className='mb-3'>
					<label className='form-label'><i className='bi bi-globe2 ms-1 me-2'></i> {t('allPayloads.filters.areas')}</label>
					<div className='d-flex row align-items-center justify-content-center'>
						<div className='col-lg-6 col-md-6 col-sm-12 mb-2'>
							<div className='d-flex row align-items-center'>
								<div className='col-lg-2 col-md-2 col-sm-12'>
									<label>{t('allPayloads.filters.areasOptions.region')}</label>
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12'>
									<SelectButton
										options={'regions' in areas ? areas.regions : []}
										name='region'
										value={areaFilters.region === '' ? null : areaFilters.region}
										onChange={onChangeAreaFilters}
									/>
								</div>
							</div>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 mb-2'>
							<div className='d-flex row align-items-center'>
								<div className='col-lg-2 col-md-2 col-sm-12'>
									<label>{t('allPayloads.filters.areasOptions.zone')}</label>
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12'>
									<SelectButton
										options={'zones' in areas ? areas.zones : []}
										name='zone'
										value={areaFilters.zone === '' ? null : areaFilters.zone}
										onChange={onChangeAreaFilters}
									/>
								</div>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
							<div className='d-flex row align-items-center'>
								<div className='col-lg-2 col-md-2 col-sm-12'>
									<label>{t('allPayloads.filters.areasOptions.depot')}</label>
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12'>
									<SelectButton
										options={'depots' in areas ? areas.depots : []}
										name='depot'
										value={areaFilters.depot === '' ? null : areaFilters.depot}
										onChange={onChangeAreaFilters}
									/>
								</div>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
							<div className='d-flex row align-items-center'>
								<div className='col-lg-2 col-md-2 col-sm-12'>
									<label>{t('allPayloads.filters.areasOptions.group')}</label>
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12'>
									<SelectButton
										options={'groups' in areas ? areas.groups : []}
										name='group'
										value={areaFilters.group === '' ? null : areaFilters.group}
										onChange={onChangeAreaFilters}
									/>
								</div>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
							<div className='d-flex row align-items-center'>
								<div className='col-lg-2 col-md-2 col-sm-12'>
									<label>{t('allPayloads.filters.areasOptions.route')}</label>
								</div>
								<div className='col-lg-10 col-md-10 col-sm-12'>
									<SelectButton
										options={'routes' in areas ? areas.routes : []}
										name='route'
										value={areaFilters.route === '' ? null : areaFilters.route}
										onChange={onChangeAreaFilters}
									/>
								</div>
							</div>
						</div>
					</div>
					<div id='areasHelp' className='form-text'>{t('allPayloads.filters.areasLegend')}</div>
				</div> */}

				<div className='d-grid gap-2 col-2 mx-auto text-center mb-4'>
					<button className='btn submit-btn' type='submit' onClick={searchFrauds}>{t('allPayloads.searchBtn')}</button>
				</div>
			</form>
			
			{displayPayloads 
				? <FraudsTable
						loading={all_frauds_loading}
						fraudsCount={all_frauds.count}
						frauds={all_frauds.frauds}
						skip={skip}
						setSkip={setSkip}
						limit={limit}
					/> 
				: null
			}
		</div>
		</div>
	);
}

export default AllFrauds;
