import React from 'react';

// Packages
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../utils/dateConverter';

const PayloadData = (props) => {
	const { width, isPayload, info } = props;

	const { t } = useTranslation();

	return (
		<Container className='p-0' fluid>
			<Row className='d-flex align-items-center'>
				<Col lg={4} md={12} sm={12}></Col>
				<Col lg={4} md={12} sm={12}>
					<p className='text-center fs-4 my-0'>
						<i className={`bi ${isPayload ? ' bi-phone' : 'bi-hand-thumbs-down'} ms-1 me-2`}></i>
						{t(`${isPayload ? 'payload.payloadInfo' : 'issue.issueInfo'}.title`)}
					</p>
					<p className='form-label text-center my-0'><b>Id: </b>{info?._id?.$oid}</p>
				</Col>
				<Col 
					className={
						clsx('d-flex', {
							'justify-content-center mt-3': width < 993,
							'justify-content-end': width > 993
						})
					} 
					lg={4} md={12} sm={12}
				>
					{info?.process_type === 1 &&
						<div className='d-flex flex-row'>
							<div className='process-type' style={{ backgroundColor: '#198754' }}>
								<i className='bi bi-wifi'></i>
							</div>
							<p className='my-0 ms-2'>{t(`${isPayload ? 'payload.payloadInfo' : 'issue.issueInfo'}.processType.online`)}</p>
						</div>
					}
					{info?.process_type === 3 &&
						<div className='d-flex flex-row'>
							<div className='process-type' style={{ backgroundColor: '#dc3545' }}>
								<i className='bi bi-wifi-off'></i>
							</div>
							<p className='my-0 ms-2'>{t(`${isPayload ? 'payload.payloadInfo' : 'issue.issueInfo'}.processType.offline`)}</p>
						</div>
					}
				</Col>
			</Row>
			<Form className='row gy-3 mb-4 my-0'>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.workspace')}</Form.Label>
						<Form.Control defaultValue={info?.workspace?.name} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.module')}</Form.Label>
						<Form.Control defaultValue={info?.module?.name} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.submodule')}</Form.Label>
						<Form.Control defaultValue={info?.submodule?.title} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.config')}</Form.Label>
						<Form.Control defaultValue={info?.configuration?.version} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.user')}</Form.Label>
						<Form.Control defaultValue={info?.user?.username} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.cycle')}</Form.Label>
						<Form.Control defaultValue={info?.cycle?.name} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.creationDate')}</Form.Label>
						<Form.Control defaultValue={epochToLocalDate(info?.date?.$date, localStorage.getItem('i18nextLng'))} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.region')}</Form.Label>
						<Form.Control defaultValue={info?.region !== undefined ? info?.region?.name : t('payload.payloadInfo.error')} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.zone')}</Form.Label>
						<Form.Control defaultValue={info?.zone !== undefined ? info?.zone?.name : t('payload.payloadInfo.error')} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.depot')}</Form.Label>
						<Form.Control defaultValue={info?.depot !== undefined ? info?.depot?.name : t('payload.payloadInfo.error')} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.group')}</Form.Label>
						<Form.Control defaultValue={info?.group !== undefined ? info?.group?.name : t('payload.payloadInfo.error')} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={6} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.payloadInfo.route')}</Form.Label>
						<Form.Control defaultValue={info?.route !== undefined ? info?.route?.code : t('payload.payloadInfo.error')} disabled />
					</Form.Group>
				</Col>
			</Form>
			<hr/>
		</Container>
	)
}

PayloadData.propTypes = {
	width: PropTypes.number.isRequired,
	isPayload: PropTypes.bool.isRequired,
	info: PropTypes.object.isRequired
}

export default PayloadData;
