import {
	ORG_REPORTS_GET,
	ORG_REPORTS_GET_ERROR
} from '../actions/types';

const initialState = {
	org_reports: [],
	reports_errors: {}
}

export default function reportsReducer(state = initialState, action){
	switch(action.type){
		case ORG_REPORTS_GET:
			delete state.reports_errors.organization_reports
			return {
				...state,
				org_reports: action.payload
			}
		case ORG_REPORTS_GET_ERROR:
			return {
				...state,
				reports_errors: {
					...state.reports_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
