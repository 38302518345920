import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

// Components
import Alert from '../utils/Alert';
import SelectButton from '../utils/SelectButton';
import ImagesTable from '../images/ImagesTable';

// Actions
import { get_organizations, get_workspaces, get_modules, get_cycles } from '../../actions/organizationActions';
import { clear_image_info, get_all_images, get_image_info } from '../../actions/imageActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import { getImgGroupDesc, getImgReconStatusDesc, getImgTypeDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const SearchImage = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	
	const { user, isGodUser } = useSelector(state => state.auth);

	const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const { image_info, error } = useSelector(state => state.image);

	const { payload_info } = useSelector(state => state.payload);

	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	const [searchBy, setSearchBy] = useState(null);
	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });
	const [imageId, setImageId] = useState('');
	const [payloadURL, setPayloadURL] = useState('');
	const [displayImgagesTable, setDisplayImgagesTable] = useState(false);

	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let lsOrganization = localStorage.getItem('organization');

		if (organizations.length <= 1) {
			setOrgFilters({
				...orgFilters,
				organization: user.organization
			});
			localStorage.setItem('organization', user.organization);
		}
		else {
			setOrgFilters({
				...orgFilters, 
				organization: lsOrganization === null ? '' : lsOrganization
			});
		}
	}, [organizations]);

	useEffect(() => {
		setPayloadUrlFromInfo({ image_info, payload_info, setPayloadURL, dispatch });
	}, [image_info, payload_info, setPayloadURL, dispatch]);	

	useEffect(() => {
		if (isGodUser) {
			setSearchBy(null);
		}
		else {
			setSearchBy('imageRef');
		}
	}, [isGodUser]);

	const setPayloadUrlFromInfo = async ({ image_info, setPayloadURL }) => {
		try {
			if (!isEmpty(image_info)) {
				// If `resource` is not null, get the payload information
				if (image_info?.resource) {
					const url = `${process.env.REACT_APP_SERVER_URL}/api/payloads/${image_info?.resource}/info`;
					
					// Performs the request with axios and handles the results
					const response = await Axios.get(url);
					setPayloadURL(`/locations/${response.data?.location?._id?.$oid}/payloads/${response.data?._id?.$oid}/image/${image_info?._id?.$oid}`);
				}
				else {
					// If `resource` is null, set the URL to `/image/:image_id`
					setPayloadURL(`/image/${image_info?._id?.$oid}`);
				}
			}
		} catch (error) {
			// If the request fails, set the URL to `/image/:image_id`
			setPayloadURL(`/image/${image_info?._id?.$oid}`);
		}
	};

	useEffect(() => {
		if (displayImgagesTable) {
			dispatch(get_all_images({ organization: orgFilters.organization, skip, limit }));
		}
	}, [skip]);

	const displayImageInfo = (data) => {
		return data || t('payload.payloadInfo.error');
	}

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
		}

		if (e !== null){
			filters[name] = e.value;
		}

		localStorage.setItem('organization', filters.organization);

		setOrgFilters(filters);
	}

	const onChangeSearchMode = (e) => {
		setImageId('');
		setDisplayImgagesTable(false);
		dispatch(clear_image_info());

		if (e !== null && e !== undefined){
			setSearchBy(e.value);
		}
		else {
			setSearchBy(null);
		}
	}

	const searchOrgImages = (e) => {
		e.preventDefault();

		if (orgFilters.organization === '') {
			setAlert({ display: true, type: 'danger', msg: t('search.imageOption.errorAlert.msg') });
			return;
		}
		setAlert(initialAlert);

		setDisplayImgagesTable(true);
		dispatch(get_all_images({ organization: orgFilters.organization, skip, limit }));
	}

	const searchImageById = (e) => {
		e.preventDefault();

		if (imageId === '') {
			setAlert({ display: true, type: 'danger', msg: t('search.imageOption.errorAlert.msg') });
			return;
		}
		setAlert(initialAlert);

		dispatch(get_image_info(imageId));
	}

	return ( 
		<div id={localStorage.getItem('theme')}>
			<div className='container container-padding mt-3'>
				<h2 className='text-center'>{t('search.imageOption.title')}</h2>
				<form onSubmit={searchBy === 'orgRef' ? searchOrgImages : searchBy === 'imageRef' ? searchImageById : null}>
					{alert.display ? <Alert type={alert.type} msg={alert.msg} /> : null}

					<div>
						{isGodUser &&
							<div className='mb-3'>
								<label className='form-label'>
									<i className='bi bi-search ms-1 me-2'></i> {t('search.filters.searchFor')}
								</label>
								<SelectButton
									options={[
										{name: `${t('search.imageOption.input')}`, value: 'imageRef'},
										{name: `${t('search.filters.organization')}`, value: 'orgRef'}
									]}
									name='searchBy'
									value={searchBy}
									onChange={onChangeSearchMode}
								/>
								<div id='searchForHelp' className='form-text'>{t('search.filters.searchForLegend')}</div>
							</div>
						}
						{searchBy === 'orgRef' && 
							organizations.length > 1 &&
								<div className='mb-3'>
									<label className='form-label'>
										<i className='bi bi-house ms-1 me-2'></i> {t('search.filters.organization')}
									</label>
									<SelectButton
										options={organizations !== undefined ? organizations : ['No organizations']}
										name='organization'
										value={orgFilters.organization === '' ? null : orgFilters.organization}
										onChange={onChangeOrgFilters}
									/>
									<div id='organizationHelp' className='form-text'>{t('search.filters.orgLegend')}</div>
								</div>
						}
						{searchBy === 'imageRef' &&
							<div>
								{error.isError &&
									<Alert type='danger' msg={t('search.imageOption.errorAlert.msg')}/> 
								}
								<div className='mb-3'>
									<label className='form-label' htmlFor='payloadRefInput'>
										<i className='bi bi-fingerprint ms-1 me-2'></i> {t('search.imageOption.input')}
									</label>
									<input className='form-control me-2' id='payloadRefInput' aria-describedby='payloadRefHelp'
										name={imageId} value={imageId} onChange={(e) => { setImageId(e.target.value) }}
									/>
									<div className='form-text' id='payloadRefHelp'>{t('search.imageOption.inputLegend')}</div>
								</div>
							</div>
						}
					</div>
					<div className='d-grid gap-2 col-2 mx-auto text-center mb-5'>
						<button className='btn submit-btn' type='submit'>{t('search.searchBtn')}</button>
					</div>
				</form>

				{searchBy === 'imageRef' &&
				(!isEmpty(image_info) && !error.isError)	&&
					<div className='card shadow p-3 mb-5 bg-body rounded'>
						<div className='card-body'>
							<h5 className='card-title text-center'>{t('display.imgInfo')}</h5>
							<p className='card-subtitle mb-2 text-muted text-center'><b>Id: </b>{displayImageInfo(image_info?._id?.$oid)}</p>
							<hr/>
			
							<div className='container text-center p-0'>
								<div className='row'>
									<p className='card-text m-0 p-0'><b>{t('payload.locationInfo.name')}: </b>{displayImageInfo(image_info?.filename)}</p>
									<p className='card-text m-0 p-0'><b>{t('locationsRecords.headers.date')}: </b>{epochToLocalDate(image_info?.date?.$date)}</p>
									<p className='card-text'><b>Payload Id: </b>{image_info?.resource}</p>
								</div>
								<hr/>
								<div className='row'>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgRecon')}: </b>{getImgReconStatusDesc(image_info?.recon)}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgType')}: </b>{getImgTypeDesc(image_info?.image_type)}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgGroup')}: </b>{getImgGroupDesc(image_info?.group)}</p>
									</div>
								</div>
								<hr/>
								<div className='row'>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Org Tags: </b>{displayImageInfo(String(image_info?.org_tags))}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Tags: </b>{displayImageInfo(String(image_info?.tags))}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Project Tags: </b>{displayImageInfo(String(image_info?.project_tags))}</p>
									</div>
								</div>
								<hr/>
							</div>
							
							<div className='row text-end mt-4'>
								<a className='card-link text-end text-decoration-none' target='_blank'
									// href={`/image/${image_info?._id?.$oid}`}
									href={payloadURL}
								>
									{t('search.imageOption.goToImage')} <i className='bi bi-chevron-right ms-2 me-1'></i>
								</a>
							</div>
						</div>
					</div>
				}
				{searchBy === 'orgRef' && displayImgagesTable &&
					<ImagesTable
						skip={skip}
						setSkip={setSkip}
						limit={limit}
						setPayloadUrlFromInfo={setPayloadUrlFromInfo}
					/>
				}
			</div>
		</div>
	);
}

export default SearchImage;
