import { combineReducers } from 'redux';

import alertReducer from './alertReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import locationReducer from './locationReducer';
import payloadReducer from './payloadReducer';
import imageReducer from './imageReducer';
import issueReducer from './issueReducer';
import resultReducer from './resultReducer';
import permissionsReducer from './permissionsReducer';
import projectsReducer from './projectsReducer';
import ticketReducer from './ticketReducer';
import indicatorsReducer from './indicatorsReducer';
import reportsReducer from './reportsReducer';
import fraudReducer from './fraudReducer';

export default combineReducers ({
	alert: alertReducer,
	errors: errorReducer,
	auth: authReducer,
	organization: organizationReducer,
	location: locationReducer,
	payload: payloadReducer,
	issues: issueReducer,
	image: imageReducer,
	result: resultReducer,
	permissions: permissionsReducer,
	projects: projectsReducer,
	ticket: ticketReducer,
	indicators: indicatorsReducer,
	reports: reportsReducer,
	frauds: fraudReducer
});
