import Axios from 'axios';

import {
	INDICATORS_PROGRESS_LOADING_TRUE,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const indicators_progress_loading_true = () => dispatch => {
  dispatch({
    type: INDICATORS_PROGRESS_LOADING_TRUE
  })
}

export const get_indicators_progress = (filters) => dispatch => {
	dispatch(indicators_progress_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/indicators/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_PROGRESS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_PROGRESS_ERROR,
			payload: { type: 'indicators_progress', msg: err.message }
		})
	})
}
