import React from 'react';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utils
import { getIssueStatusDesc, getPayloadStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';
import isEmpty from '../../utils/isEmpty';

const PayloadInfo = (props) => {
	const { isPayload, payloadRef, payloadInfo, indicatorsInfo } = props;

	const { t } = useTranslation();

	const displayPayloadInfo = (data) => data || t('payload.payloadInfo.error');

	return (
		<div className='card shadow p-3 mb-5 bg-body rounded' ref={payloadRef}>
			<div className='card-body'>
				<h5 className='card-title text-center'>
					{isPayload ? t('payload.payloadInfo.title') : t('issue.issueInfo.title')}
				</h5>
				<p className='card-subtitle mb-2 text-muted text-center'><b>Id: </b>{displayPayloadInfo(payloadInfo?._id?.$oid)}</p>
				<hr/>

				<div className='container text-center p-0'>
					<div className='row'>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('allPayloads.filters.organization')}: </b>{displayPayloadInfo(payloadInfo?.organization?.name)}</p>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.workspace')}: </b>{displayPayloadInfo(payloadInfo?.workspace?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.module')}: </b>{displayPayloadInfo(payloadInfo?.module?.name)}</p>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.submodule')}: </b>{displayPayloadInfo(payloadInfo?.submodule?.title)}</p>
						</div>
					</div>
					<hr/>
					<div className='row align-items-center'>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.cycle')}: </b>{displayPayloadInfo(payloadInfo?.cycle?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('locationsRecords.headers.date')}: </b>{epochToLocalDate(payloadInfo?.date?.$date)}</p>
						</div>
						{isPayload
							?	<div className='col'>
									<p className='card-text m-0 p-0'><b>{t('payload.payloadStatus.status')}: </b>{getPayloadStatusDesc(payloadInfo?.status)}</p>
								</div>
							:	<div className='col'>
									<p className='card-text m-0 p-0'><b>{t('issue.issuesTable.headers.type')}: </b>{getIssueStatusDesc(payloadInfo?.issue_type)}</p>
								</div>
						}
						{!isEmpty(indicatorsInfo) &&
							<div className='col'>
								<p className='card-text m-0 p-0'><b>{t('payload.payloadScore.title')}: </b>{displayPayloadInfo(indicatorsInfo?.indicators?.score)}</p>
							</div>
						}
					</div>
					<hr/>
					<div className='row'>
						<p className='card-text'><b>{t('payload.locationInfo.name')}: </b>{displayPayloadInfo(payloadInfo?.location?.name)}</p>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.region')} </b></p>
							<p className='card-text m-0 p-0'>{displayPayloadInfo(payloadInfo?.region?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.zone')} </b></p>
							<p className='card-text m-0 p-0'>{displayPayloadInfo(payloadInfo?.zone?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.depot')} </b></p>
							<p className='card-text m-0 p-0'>{displayPayloadInfo(payloadInfo?.depot?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.group')} </b></p>
							<p className='card-text m-0 p-0'>{displayPayloadInfo(payloadInfo?.group?.name)}</p>
						</div>
						<div className='col'>
							<p className='card-text m-0 p-0'><b>{t('payload.payloadInfo.route')} </b></p>
							<p className='card-text m-0 p-0'>{displayPayloadInfo(payloadInfo?.route?.name)}</p>
						</div>
					</div>
					<hr/>
				</div>

				<div className='row text-end mt-4'>
					<a className='card-link text-end text-decoration-none' target='_blank'
						href={`/locations/${payloadInfo?.location?._id?.$oid}/${isPayload ? 'payloads' : 'issues'}/${payloadInfo?._id?.$oid}`}
					>
						{isPayload ? t('payloadsTable.actions.goToPayload') : t('locationsRecords.actions.goToIssue')} <i className='bi bi-chevron-right ms-2 me-1'></i>
					</a>
				</div>
			</div>
		</div>
	);
}

PayloadInfo.propTypes = {
	isPayload: PropTypes.bool.isRequired,
	payloadRef: PropTypes.object.isRequired,
  payloadInfo: PropTypes.object.isRequired,
  indicatorsInfo: PropTypes.object
}

export default PayloadInfo;
