import React from 'react';

// Packages
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// Components
import PDFImage from '../utils/PDFImage';

// Styles
import { styles, colors } from './styles';

// Utils
import formatString from '../../../utils/formatString';
import isObjectId from '../../../utils/isObjectId';

const PayloadContent = (props) => {
	const { parsedPayload } = props;

	return (
		<View>
			<View style={styles.section}>
				<Text style={styles.sectionText}>Contenido del Censo</Text>
			</View>
			<View style={styles.table}>
				{/* Refris column */}
				<View style={{ ...styles.tableColumn, borderRight: `1px solid ${colors.geppBlue}` }}>
					<View style={styles.tableContentHeader}>
						<Text style={styles.boldTex}>Refris</Text>
					</View>
					{parsedPayload.map((element, idx) => (
						<View key={idx}>
							{Object.keys(element).map((key, i) => (
								key.includes('refris') && (
									<View style={styles.tableContentRow} key={i}>
										{!key.includes('refris-') && (
											<View style={styles.tableContentNameCell}>
												<Text style={styles.boldTex}>{formatString(key)}</Text>
											</View>
										)}
										{typeof element[key] === 'object'
											? <View style={styles.tableContentCell}>
													{element[key].items.map((item, itemIdx) => (
														Object.entries(item).map(([itemKey, value]) => (
															<View key={itemIdx} style={styles.tableContentItemsCell}>
																<Text style={styles.boldTex}>{formatString(itemKey)}:  </Text>
																<Text style={styles.normalText}>{value}</Text>
															</View>
														))
													))}
												</View>
											: !isObjectId(element[key]) && <Text style={styles.normalText}>{element[key]}</Text> 
										}
										{typeof element[key] === 'object'
											? <View style={{ ...styles.tableContentCell, ...styles.flexWrap }}>
													{element[key].photos.map((item, photoIdx) => (
														<View key={photoIdx} style={styles.tableColumn}>
															{Object.entries(item).map(([itemKey, value]) => (
																<View key={itemKey}>
																	{itemKey === 'image' 
																		? <PDFImage imageId={value} />
																		: <Text style={styles.normalText}>
																				{itemKey === 'image' ? null : formatString(itemKey) + ': '}{value}
																			</Text>
																	}
																</View>
															))}
														</View>
													))}
												</View>
											: isObjectId(element[key]) && <PDFImage imageId={element[key]} />
										}
									</View>
								)
							))}
						</View>
					))}
				</View>
				{/* Racks & other items column */}
				<View style={styles.tableColumn}>
					<View style={styles.tableContentHeader}>
						<Text style={styles.boldTex}>Información Adicional</Text>
					</View>
					{parsedPayload.map((element, idx) => (
						<View key={idx}>
							{Object.keys(element).map((key, i) => (
								!key.includes('refris') && (
									<View style={styles.tableContentRow} key={i}>
										{!key.includes('racks-') && (
											<View style={styles.tableContentNameCell}>
												<Text style={styles.boldTex}>{formatString(key)}</Text>
											</View>
										)}
										{typeof element[key] === 'object'
											? <View style={styles.tableContentCell}>
													{element[key].items.map((item, itemIdx) => (
														Object.entries(item).map(([itemKey, value]) => (
															<View key={itemIdx} style={styles.tableContentItemsCell}>
																<Text style={styles.boldTex}>{formatString(itemKey)}:  </Text>
																<Text style={styles.normalText}>{value}</Text>
															</View>
														))
													))}
												</View>
											: !isObjectId(element[key]) && <Text style={styles.normalText}>{element[key]}</Text>
										}
										{typeof element[key] === 'object'
											? <View style={styles.tableContentCell}>
													{element[key].photos.map((item, photoIdx) => (
														<View key={photoIdx}>
															{Object.entries(item).map(([itemKey, value]) => (
																<View key={itemKey}>
																	{itemKey === 'image' 
																		? <PDFImage imageId={value} />
																		: <Text style={styles.normalText}>
																				{itemKey === 'image' ? null : formatString(itemKey) + ': '}{value}
																			</Text>
																	}
																</View>
															))}
														</View>
													))}
												</View>
											: isObjectId(element[key]) && <PDFImage imageId={element[key]} />
										}
									</View>
								)
							))}
						</View>
					))}
				</View>
			</View>
		</View>
	);
}

PayloadContent.prototypes = {
	parsedPayload: PropTypes.array.isRequired
}

export default PayloadContent;
