import Axios from 'axios';

import {
  PAYLOAD_LOADING_TRUE,
  PAYLOAD_LOADING_FALSE,
  PAYLOAD_INFO_GET,
  PAYLOAD_INFO_ERROR,
  PAYLOAD_HISTORY_GET,
  PAYLOAD_HISTORY_ERROR,
	ALL_PAYLOADS_LOADING,
  ALL_PAYLOADS_GET,
  ALL_PAYLOADS_ERROR,
  SET_PAYLOAD_IMAGES
} from './types';

const create_query_params = (filters) => {
  let query_params = '';

  Object.keys(filters).forEach((f) => {
    query_params = query_params + `${f}=${filters[f]}&`;
  })

  return query_params;
}

export const payload_loading_true = () => dispatch => {
  dispatch({
    type: PAYLOAD_LOADING_TRUE
  })
}

export const payload_loading_false = () => dispatch => {
  dispatch({
    type: PAYLOAD_LOADING_FALSE
  })
}

export const get_payload_info = (payload_id) => dispatch => {
  // console.log(payload_id)
  dispatch(payload_loading_true());
  let url = process.env.REACT_APP_SERVER_URL + `/api/payloads/${payload_id}/info`;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data)
    dispatch({
      type: PAYLOAD_INFO_GET,
      payload: res.data
    })
    dispatch(payload_loading_false());
    // setTimeout(() => {
    // }, 3000);
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: PAYLOAD_INFO_ERROR,
      payload: {type: 'payload_info', msg: err.message}
    })
    dispatch(payload_loading_false());
    // setTimeout(() => {
    // }, 3000);
  })
}

export const clear_payload_info = () => dispatch => {
  dispatch({
    type: PAYLOAD_INFO_GET,
    payload: {}
  })
}

export const get_payload_history = (payload_id) => dispatch => {
  // console.log(payload_id)
  let url = process.env.REACT_APP_SERVER_URL + `/api/payloads/${payload_id}/history`;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data)
    dispatch({
      type: PAYLOAD_HISTORY_GET,
      payload: res.data
    })
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: PAYLOAD_HISTORY_ERROR,
      payload: {type: 'payload_history', msg: err.message}
    })
  })
}

export const get_all_payloads = (orgFilters, areaFilters = {}, skip, limit) => dispatch => {
	dispatch({
		type: ALL_PAYLOADS_LOADING,
		payload: true
	});

  let filters = { ...orgFilters, ...areaFilters, };

  let url = process.env.REACT_APP_SERVER_URL + `/api/payloads?skip=${skip}&limit=${limit}&`;
  let query = create_query_params(filters);
  url += query;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data);
    dispatch({
      type: ALL_PAYLOADS_GET,
      payload: res.data
    });

		dispatch({
			type: ALL_PAYLOADS_LOADING,
			payload: false
		});
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: ALL_PAYLOADS_ERROR,
      payload: {type: 'all_payloads', msg: err.message}
    });

		dispatch({
			type: ALL_PAYLOADS_LOADING,
			payload: false
		});
  })
}

export const set_payload_images = (payloadData) => dispatch => {
  let images = [];

  if (payloadData !== undefined && payloadData !== null) {
    Object.keys(payloadData).forEach((key) => {
      if (Array.isArray(payloadData[key])){ // es un array?
        payloadData[key].forEach((element) => {
          if (element.photos !== undefined) {
            element.photos.forEach((photo) => {
              images.push({
                type: key,
                imageId: photo.image.$oid
              });
            });
          }
        });
      }
      else if (typeof payloadData[key] === 'object'){ // es un objeto?
        if (payloadData[key] !== null) {
          if (payloadData[key].image !== null) {
            images.push({
              type: key,
              imageId: payloadData[key].image.$oid
            });
          }
        }
      }
    })
  }

  dispatch({
    type: SET_PAYLOAD_IMAGES,
    payload: images
  })
}
