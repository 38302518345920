import moment from 'moment-timezone';
import 'moment/locale/es';

const epochToLocalDate = (epoch, locale) => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  moment.locale(locale);
  let momentTime = moment(parseInt(epoch)).tz(timezone).format('L LTS');

  return momentTime;
}

export { epochToLocalDate };
