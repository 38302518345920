import React, { Fragment, useEffect, useState } from 'react';

// Packages
import { Table } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import TablePagination from '../utils/TablePagination';

// Utils
import { getPayloadStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const FraudsTable = (props) => {
	const { loading, fraudsCount, frauds, skip, setSkip, limit } = props;
	console.log(frauds)

	const { t } = useTranslation();

	const nPages = Math.ceil(fraudsCount / limit);

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

	const [width, setWidth]   = useState(window.innerWidth);

	const updateDimensions = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	return (
		<div style={{ marginTop: '3rem', minHeight: '510px' }}>
			<Table responsive='lg'>
				<thead >
					<tr className='text-center'>
						<th
							colSpan='2'
							style={{ borderTop: '1px solid black', borderRight: '1px solid black', borderLeft: '1px solid black' }}
						>
							Original
						</th>
						<th
							colSpan='2'
							style={{ borderTop: '1px solid black', borderRight: '1px solid black', borderLeft: '1px solid black' }}
						>
							{t('fraud.false')}
						</th>
					</tr>
					<tr className='table-head'>
						{/* {width > 800 && <th scope='col'>Id</th>} */}
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('payloadsTable.headers.code')}</th>
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('payloadsTable.headers.code')}</th>
						<th scope='col'>{t('payloadsTable.headers.date')}</th>
						<th scope='col'>{t('payloadsTable.headers.status')}</th>
						<th scope='col'>{t('payloadsTable.headers.action')}</th>
					</tr>
				</thead>
				<tbody>
					{loading
						?	<tr>
								<td className='text-center' colSpan={6}>
									{/* <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span> */}
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</td>
							</tr>
						: <Fragment>
								{frauds.length > 0
									? <Fragment>
											{frauds.map((r, idx) => (
												<tr key={idx}>
													{/* {width > 800 && <td className='text-break'>{r?._id?.$oid}</td>} */}
													<td>{r?.original?.location?.name || t('payload.payloadInfo.error')}</td>
													<td>{r?.original?.location?.code || t('payloadsTable.errors.noCode')}</td>
													<td>{r?.copy?.location?.name || t('payload.payloadInfo.error')}</td>
													<td>{r?.copy?.location?.code || t('payloadsTable.errors.noCode')}</td>
													<td>{epochToLocalDate(r?.date?.$date, localStorage.getItem('i18nextLng'))}</td>
													<td>{getPayloadStatusDesc(r?.status)}</td>
													<td>
														<a href={`/payloads/${r?.original?.payload?._id?.$oid}/fraud`} target='_blank' rel='noopener noreferrer'>
															{t('fraud.alert.link')}
														</a>
													</td>
												</tr>
											))}
										</Fragment>
									: <tr>
											<td className='text-center' colSpan={6}>{t('payloadsTable.errors.noPayloads')}</td>
										</tr>
								}
							</Fragment>
					}
				</tbody>
			</Table>
			<TablePagination
				count={fraudsCount}
				arrayLength={frauds.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</div>
	);
}

FraudsTable.prototypes = {
	loading: PropTypes.bool.isRequired,
	fraudsCount: PropTypes.number.isRequired,
	frauds: PropTypes.array.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	searchPayloads: PropTypes.func.isRequired
}

export default FraudsTable;
