import React, { useState, useEffect } from 'react';

// Packages
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SelectButton = (props) => {
  const { options, name, value, onChange, loading } = props;

  const { t } = useTranslation();

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    let optsArray = [];

    options.forEach((opt) => {
      if (opt === null || opt === undefined) return;

      let obj = {};
      obj.value = opt._id !== undefined ? opt._id.$oid : opt.value;
      obj.label = name !== 'submodule' ? opt.name : opt.title;
      optsArray.push(obj);
    });

    setSelectOptions(optsArray);
  }, [options]);

  const customLightStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 9999
    })
  }

  const customDarkStyles = {
    control: (styles, state) => ({
      ...styles,
      background: '#181a1b',
      borderColor: state.isFocused ? '#cccccc' : '#3e4446',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: '#cccccc'
      }
    }),
    menu: (styles) => ({
      ...styles,
      background: '#181a1b',
      color: '#d1cdc7',
      zIndex: 9999
    }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? '#2684ff'
        : state.isFocused ? '#25292a' : undefined
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#d1cdc7'
    }),
    input: (styles) => ({
      ...styles,
      '[type="text"]': {
        color: 'white !important'
      }
    })
  };

  return (
    <Select
      classNamePrefix='select'
      placeholder={t('selectButton.placeholder')}
      noOptionsMessage={() => t('selectButton.noOptionsMessage')}
      maxMenuHeight={170}
      options={selectOptions}
      value={value !== null ? selectOptions.find(opt => opt.value === value) : null}
      onChange={(e) => onChange(e, name)}
      isDisabled={false}
      isClearable={true}
      isSearchable={true}
			isLoading={loading}
			loadingMessage={() => t('selectButton.loadingMessage')}
      styles={localStorage.getItem('theme') === 'dark' ? customDarkStyles : customLightStyles}
      theme={(selectTheme) => ({
        ...selectTheme,
        colors: {
          ...selectTheme.colors,
          primary50: undefined,
        },
      })}
    />
  );
}

SelectButton.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool
};

export default SelectButton;
