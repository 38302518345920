import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Lottie from 'lottie-react';

// Components
import SelectButton from '../utils/SelectButton';
import Alert from '../utils/Alert';

// Actions
import { get_organizations } from '../../actions/organizationActions';
import { get_all_projects } from '../../actions/projectsActions';
import { create_ticket } from '../../actions/ticketActions';

// Utils
import isEmpty from '../../utils/isEmpty';

// Icons
import checkAnimation from '../../static/icons/checkAnimation.json';

const TicketModal = (props) => {
	const { width, imageId, result } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const all_projects = useSelector(state => state.projects.all_projects);

	const ticket_loading = useSelector(state => state.ticket.ticket_loading);
	const ticket_create_success = useSelector(state => state.ticket.ticket_create_success);
	const ticket_error = useSelector(state => state.ticket.error);

	const [alert, setAlert] = useState(false);

	const [projectInfo, setProjectInfo] = useState({});
	const [ticketInfo, setTicketInfo] = useState({ project: '', name: '', description: '' });

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	useEffect(() => {
		dispatch(get_organizations());
	}, [dispatch]);

	useEffect(() => {
		if (organizations.length < 1) {
			/// Select defualt project
			const projects = all_projects.filter(project => !project?.name.startsWith("Test -"));
			const default_project = projects[0];

			setProjectInfo(default_project);
			setTicketInfo({ ...ticketInfo, project: default_project?._id?.$oid });
		}
	}, [organizations, all_projects]);

	const resetTicketInfo = () => setTicketInfo({ project: '', name: '', description: '' });

	const onChangeProject = (e, name) => {
		const filters = {...ticketInfo};
		
		if (e !== null){
			filters[name] = e.value;
		}
		else {
			filters[name] = '';
		}

		setTicketInfo(filters);
	}

	const createTicket = (e) => {
		e.preventDefault();

		if (ticketInfo.project === '' || ticketInfo.name === '' || ticketInfo.description === '') {
			setAlert(true);
			return;
		}
		setAlert(false);

		const ticket_info = {
			...ticketInfo,
			...(result?._id?.$oid !== '' && { result: result?._id?.$oid }),
			t_type: 1,
			image: imageId
		}

		dispatch(create_ticket(ticket_info, closeModalRef, resetTicketInfo));
	}

	return ( 
		<div className='modal fade' id='createTicket' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='createTicketLabel'>
			<div className='modal-dialog modal-lg modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='createTicketLabel'>
							<i className='bi bi-file-excel ms-1 me-2'></i> {t('display.actionBtns.createTicket')}
						</h5>
						<button type='button' className={clsx('btn-close', {
							'btn-close-white': localStorage.getItem('theme') === 'dark'
							})} data-bs-dismiss='modal' aria-label='Close' ref={closeModalRef} 
							onClick={() => { resetTicketInfo(); setAlert(false); }}
						></button>
					</div>
					<div className='modal-body'>
						{alert ? <Alert type='danger' msg={t('allPayloads.errorAlertMsg')}/> : null}
						
						{ticket_error.isError && ticket_error.type === 'ticket_create' &&
							<Alert type='danger' msg={'Error — ' + ticket_error.msg}/>
						}
						
						<p>{t('ticketModal.instuction')}</p>

						<form>
							<div className='col-12 mb-3'>
							{organizations.length > 1
								?	<div className='d-flex row align-items-start'>
										<div className='col-lg-2 col-md-2 col-sm-12 py-2'>
											<label>{t('ticketModal.project')}:</label>
											<OverlayTrigger
												trigger={['hover', 'focus']}
												placement={width < 993 ? 'bottom' : 'right'}
												overlay={
													<Popover id='indicators-info'>
														<Popover.Header as='h3'>{t('tickets.help.title')}</Popover.Header>
														<Popover.Body>
															<p className='my-0'>{t('tickets.help.msg')}</p>
														</Popover.Body>
													</Popover>
												}
											>
												<i className='bi bi-question help-btn circle pulse'></i>
											</OverlayTrigger>
										</div>
										<div className='col-lg-10 col-md-10 col-sm-12'>
											<SelectButton
												options={all_projects}
												name='project'
												value={ticketInfo.project === '' ? null : ticketInfo.project}
												onChange={onChangeProject}
											/>
										</div>
									</div>
								:	<div className='col-12 mb-3'>
										<div className='d-flex row align-items-start py-2'>
											<div className='col-2'>
												<label>{t('ticketModal.project')}:</label>
											</div>
											<div className='col-10'>{projectInfo?.name}</div>
										</div>
									</div>
							}
								
							</div>
							<div className='mb-3 row'>
								<label htmlFor='inputName' className='col-sm-2 col-form-label'>{t('ticketModal.name')}:</label>
								<div className='col-sm-10'>
									<input type='text' className='form-control' id='inputName' value={ticketInfo.name}
										onChange={(e) => setTicketInfo({ ...ticketInfo, name: e.target.value})}
									/>
								</div>
							</div>
							<div className='mb-3 row'>
								<label htmlFor='inputDescription' className='col-sm-2 col-form-label'>{t('ticketModal.description')}:</label>
								<div className='col-sm-10'>
									<textarea className='form-control' rows='3' id='inputDescription' value={ticketInfo.description}
										onChange={(e) => setTicketInfo({ ...ticketInfo, description: e.target.value})}>
									</textarea>
								</div>
							</div>
						</form>
					</div>
					<div className='modal-footer text-center'>
						<button type='button' className='btn btn-outline-secondary' disabled={ticket_loading} data-bs-dismiss='modal'
							onClick={() => { resetTicketInfo(); setAlert(false); }}
						>
							{t('ticketModal.cancel')}
						</button>
						<button type='button' className={clsx('btn', {
							'submit-btn': !ticket_create_success,
							'success-btn': ticket_create_success
						})} 
							disabled={ticket_loading} onClick={createTicket}
						>
							{ticket_create_success
								? <Lottie
										loop={false}
										lottieRef={lottieRef}
										animationData={checkAnimation}
										style={{ height: '24px' }}
									/>
								: ticket_loading
									? <Fragment>
											<span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span> {t('ticketModal.creating')}
										</Fragment>
									: t('ticketModal.create')
							}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

TicketModal.propTypes = {
	width: PropTypes.number.isRequired,
	imageId: PropTypes.string.isRequired,
	result: PropTypes.object.isRequired
}
 
export default TicketModal;
