import Axios from 'axios';

import {
	ERRORS_CLEAR,
	TICKET_LOADING_TRUE,
	TICKET_LOADING_FALSE,
	TICKET_CREATE,
	TICKET_CREATE_ERROR,
	ALL_TICKETS_LOADING,
	ALL_TICKETS_GET,
	ALL_TICKETS_ERROR
} from './types';

// Utils
import create_query_params from '../utils/create_query_params';

export const ticket_errors_clear = () => dispatch => {
	dispatch({
		type: ERRORS_CLEAR
	})
}

export const ticket_loading_true = () => dispatch => {
	dispatch({
		type: TICKET_LOADING_TRUE
	})
}

export const ticket_loading_false = () => dispatch => {
	dispatch({
		type: TICKET_LOADING_FALSE
	})
}

export const create_ticket = (ticket_info, closeModalRef, resetTicketInfo) => dispatch => {
	// console.log(ticket_info)
	dispatch(ticket_loading_true());
	dispatch(ticket_errors_clear());

	let url = process.env.REACT_APP_SERVER_URL + '/api/tickets/create/display';

	Axios.post(url, ticket_info)
	.then((res) => {
		// console.log(res.data)
		dispatch(ticket_loading_false());

		dispatch({
			type: TICKET_CREATE,
			payload: true
		})
		
		setTimeout(() => {
			closeModalRef.current.click();
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: TICKET_CREATE,
				payload: false
			})
			resetTicketInfo();
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: TICKET_CREATE_ERROR,
			payload: { type: 'ticket_create', msg: err.message }
		})
		dispatch(ticket_loading_false());
	})
}

export const get_all_tickets = (filters) => dispatch => {
	dispatch({
		type: ALL_TICKETS_LOADING,
		payload: true
	})

	let url = process.env.REACT_APP_SERVER_URL + `/api/tickets?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_TICKETS_GET,
			payload: res.data
		})

		dispatch({
			type: ALL_TICKETS_LOADING,
			payload: false
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_TICKETS_ERROR,
			payload: { type: 'get_all_tickets', msg: err.message }
		})

		dispatch({
			type: ALL_TICKETS_LOADING,
			payload: false
		})
	})
}
