import {
	ALERT_SET,
	ALERT_REMOVE
} from './types';

export const alert_set = (variant, icon, msg) => dispatch => {
	dispatch({
		type: ALERT_SET,
		payload: { variant: variant, icon: icon, msg: msg }
	});
}

export const alert_remove = () => dispatch => {
	dispatch({
		type: ALERT_REMOVE
	})
}
