import React, { useState, useEffect, useLayoutEffect } from 'react';
import { PercepthorCanvas } from '../../models/PercepthorCanvas'
import { PercepthorImage } from '../../models/PercepthorImage'

import { loadImageFromUrl } from '../../utils/image'
import { PercepthorArticle } from '../../models/PercepthorArticle';
import { Coordinate } from '../../models/Coordinate';

//Utils
import { COLOR_TRANSPARENCY } from '../../utils/fabricJsCustom'

const Canvas = (props) => {
	const { id, urlPhoto, height, canvas, setCanvas, tagsList, predictions } = props;

	useEffect(() => {
		async function initCanvas() {
			const localCanvas = new PercepthorCanvas('canvas' + id)
			localCanvas.hoverCursor = 'default';
			localCanvas.cursorMode = 'inspect'
			localCanvas.stopContextMenu = true;
			localCanvas.fireRightClick = true;
			setCanvas(localCanvas);

			let imageObj = await loadImageFromUrl(urlPhoto)
			if (imageObj !== null){
				let newPercepthorImage = new PercepthorImage(imageObj)
				newPercepthorImage.set({
					image: imageObj,
					width: imageObj.width,
					height: imageObj.height,
				})

				localCanvas.setPercepthorImage(newPercepthorImage)
				localCanvas.requestRenderAll();
				localCanvas.setFitZoom()

				localCanvas.activeTag = tagsList[0]

				//Creando los percpethorArtuicles
				predictions.forEach(element => {
					tagsList.forEach(tag => {
						if (tag.className === element.clase) {
							let c = new Coordinate(0, 0, 0, 0, element.coords['0'], element.coords['1'], element.coords['2'], element.coords['3'], element.score)
							let [left, top, width, height] = c.getParamsForFabricPerceptorTag(newPercepthorImage.width, newPercepthorImage.height)
							let rgbAux = tag.arrayRGBColor
							let article = new PercepthorArticle({
								//Attributes of Fabric.Rect
								left: left,
								top: top,
								originX: 'left',
								originY: 'top',
								width: width,
								height: height,
								objectCaching: false,
								strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
								strokeUniform: true,
								fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`,
								stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`,
								//Extended attributes for PercepthorArticle
								id: 0,
								label: '',
								result: null,
								tag: tag,
							});
							article.set({
								lockMovementX: true,
								lockMovementY: true,
								lockScalingX: true,
								lockScalingY: true
							});
							localCanvas.add(article);
						}
					});
				});
			}
		}

		initCanvas()
	}, []);

	useEffect(() => { //On change tagsList
		if (canvas && tagsList && predictions){
			canvas.removePercepthorArticles();
			//Creando los percpethorArtuicles
			predictions.forEach(element => {
				tagsList.forEach(tag => {
					if (tag.className === element.clase) {
						let c = new Coordinate(0, 0, 0, 0, element.coords['0'], element.coords['1'], element.coords['2'], element.coords['3'], element.score)
						let [left, top, width, height] = c.getParamsForFabricPerceptorTag(canvas.percepthorImage.width, canvas.percepthorImage.height)
						let rgbAux = tag.arrayRGBColor
						let article = new PercepthorArticle({
							//Attributes of Fabric.Rect
							left: left,
							top: top,
							originX: 'left',
							originY: 'top',
							width: width,
							height: height,
							objectCaching: false,
							strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
							strokeUniform: true,
							fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`,
							stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`,
							//Extended attributes for PercepthorArticle
							id: 0,
							label: '',
							result: null,
							tag: tag,
						});
						article.set({
							lockMovementX: true,
							lockMovementY: true,
							lockScalingX: true,
							lockScalingY: true
						});
						canvas.add(article);
					}
				});
			});
		}
	}, [tagsList]);

	//https://stackblitz.com/edit/react-w2v97x?file=index.js
	function useCanvasSize() { //US hoook
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				let newWidth = document.getElementById("divCanvas" + id).offsetWidth
				let newHeight = document.getElementById("divCanvas" + id).offsetHeight
				setSize([newWidth, newHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		if (canvas) {
			canvas.setDimensions({
				width: size[0],
				height: size[1]
			});
		}
		return size;
	}

	const [widthCanvas, heightCanvas] = useCanvasSize(); //It works as EventListener for rezizing canvas

	const canvasLightStyleCSS = {
		height: height,
		backgroundColor:'#f7f7f7'
	}
	
	const canvasDarkStyleCSS = {
		height: height,
		backgroundColor:'#1d1f20'
	}

	return (
		<div id={'divCanvas' + id} style={localStorage.getItem('theme') === 'dark' ? canvasDarkStyleCSS : canvasLightStyleCSS}>
			<canvas id={"canvas" + id} onContextMenu={(e) => e.preventDefault()}></canvas>
			<p hidden> {widthCanvas}x{heightCanvas}</p>
		</div>
	);
}

export default Canvas;
