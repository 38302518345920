import i18next from 'i18next';

const setStatusBadgeColor = (status) => {
	let bgColor = 'black', textColor = 'white';

	switch (status) {
		case 'None':
		case i18next.t('statusDesc.payload.none'):
			bgColor = '#adb5bd'; textColor = 'white';
		break;
		case i18next.t('statusDesc.payload.created'):
			bgColor = '#0d6efd'; textColor = 'white';
		break;
		case i18next.t('statusDesc.location.available'):
			bgColor = '#64dd17'; textColor = 'white';
		break;
		case 'Down':
			bgColor = 'black'; textColor = 'white';
		break;
		case 'Maintenance':
			bgColor = '#546e7a'; textColor = 'white';
		break;
		case 'Deprecated':
			bgColor = '#424242'; textColor = 'white';
		break;
		case i18next.t('statusDesc.payload.removed'):
			bgColor = '#ad1457'; textColor = 'white';
		break;
		case 'Working':
			bgColor = '#8e24aa'; textColor = 'white';
		break;
		case 'Stop':
		case 'Stopped':
		case i18next.t('statusDesc.payload.bad'):
			bgColor = '#dc3545'; textColor = 'white';
		break;
		case 'End':
		case 'Ended':
			bgColor = '#283593'; textColor = 'white';
		break;
		case i18next.t('statusDesc.location.disabled'):
		case i18next.t('statusDesc.payload.in_review'):
			bgColor = '#0dcaf0'; textColor = 'black';
		break;
		case 'Ready':
			bgColor = '#aeea00'; textColor = 'black';
		break;
		case i18next.t('statusDesc.payload.waiting'):
			bgColor = '#fbc02d'; textColor = 'black';
		break;
		case i18next.t('statusDesc.payload.processing'):
			bgColor = '#3f51b5'; textColor = 'white';
		break;
		case 'Start':
		case i18next.t('statusDesc.payload.good'):
			bgColor = '#198754'; textColor = 'white';
		break;
		case i18next.t('statusDesc.payload.incomplete'):
			bgColor = '#fb8c00'; textColor = 'white';
		break;
		case 'Failed':
			bgColor = '#e53935'; textColor = 'white';
		break;
		case i18next.t('statusDesc.payload.error'):
			bgColor = '#b71c1c'; textColor = 'white';
		break;

		default:
			bgColor = 'black'; textColor = 'white';
		break;
	}

	return { bgColor, textColor }
}

export default setStatusBadgeColor;
