import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Button, Col, Container, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Components
import RenderImage from '../utils/RenderImage';
import StatusModal from './StatusModal';
import PayloadRating from './PayloadRating';
import Loading from '../utils/Loading';
import LocationInfo from '../utils/LocationInfo';
import PayloadData from '../utils/PayloadData';
import GeppDataSheet from '../data_sheet/gepp/DataSheet';
import Alert from '../utils/Alert';

// Actions
import { get_payload_info, get_payload_history, set_payload_images } from '../../actions/payloadActions';
import { get_route_cycle, get_screens_data } from '../../actions/organizationActions';
import { get_indicators_progress } from '../../actions/indicatorsActions';
import { get_location_data } from '../../actions/locationActions';
import { get_org_reports } from '../../actions/reportsActions';
import { search_payload_fraud } from '../../actions/fraudActions';

// Utils
import parsePayloadData from '../../utils/parsePayloadData';
import { getPayloadStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';
import formatString from '../../utils/formatString';
import isEmpty from '../../utils/isEmpty';
import isObjectId from '../../utils/isObjectId';

const Payload = () => {
	const { t } = useTranslation();

	const { payload_id } = useParams();

	const dispatch = useDispatch();

	const { payload_loading, payload_info, payload_history } = useSelector(state => state.payload);
	
	const { screens_data, route_cycle } = useSelector(state => state.organization);

	const { indicators_progress_loading, indicators_progress } = useSelector(state => state.indicators);

	const { location_data } = useSelector(state => state.location);

	const { org_reports } = useSelector(state => state.reports);

	const { payload_fraud } = useSelector(state => state.frauds);

	const [parsedPayload, setParsedPayload] = useState([]);
	const [historyDate, setHistoryDate] = useState('');

	const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

	useEffect(() => {
		dispatch(get_payload_info(payload_id));
		dispatch(get_payload_history(payload_id));
		dispatch(search_payload_fraud({ payload: payload_id }));

		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	useEffect(() => {
		if (!isEmpty(payload_info)) {
			dispatch(get_screens_data(payload_info?.submodule?._id?.$oid));

			// dispatch(get_route_cycle(payload_info?.route?._id?.$oid));

			dispatch(get_location_data(payload_info?.location?._id?.$oid, { workspace: payload_info?.workspace?._id?.$oid }));

			dispatch(get_org_reports({ organization: payload_info?.organization?._id?.$oid, app: process.env.REACT_APP_ALIAS }));
			
			const query = {
				location: payload_info?.location?._id?.$oid,
				route: payload_info?.route?._id?.$oid,
				cycle: payload_info?.cycle?._id?.$oid
			}
			dispatch(get_indicators_progress(query));
		}
	}, [payload_info]);

	useEffect(() => {
		if (payload_history.length > 0) {
			setHistoryDate(epochToLocalDate(payload_history[payload_history.length - 1]?.date?.$date, localStorage.getItem('i18nextLng')));
		}
	}, [payload_history]);

	useEffect(() => {
		const parsedPayload = parsePayloadData(screens_data, payload_info?.data);
		setParsedPayload(parsedPayload);
		dispatch(set_payload_images(payload_info.data));
	}, [screens_data]);

	if (payload_loading) {
		return (
			<Loading msg={t('payload.loading')} />
		);
	}
	else {
		return ( 
			<div id={localStorage.getItem('theme')}>
			<div className='container container-padding'> 
				<StatusModal payload_history={payload_history}/>

				<div className='my-3'>
					{!isEmpty(payload_fraud) &&
						<div className='alert alert-danger' role='alert'>
							<i className='bi bi-exclamation-triangle-fill text-danger me-2'></i>
							{t('fraud.alert.msg')} <a href={`/payloads/${payload_id}/fraud`} className='alert-link'>{t('fraud.alert.link')}.</a>
						</div>
					}
					
					<Row>
						<Col lg={4} md={12} sm={12}></Col>
						<Col lg={4} md={12} sm={12}>
							<p className='text-center fs-3 mb-0'>{t('payload.title')}</p>
						</Col>
						<Col className={width < 993 ? 'text-center' : 'text-end'} lg={4} md={12} sm={12}>
							{/* {org_reports.length > 0 &&
								<PDFDownloadLink 
									document={
										<GeppDataSheet
											title={org_reports[0]?.name}
											locationInfo={payload_info?.location}
											locationData={location_data}
											payloadInfo={payload_info}
											indicatorsProgress={indicators_progress?.indicators}
											parsedPayload={parsedPayload}
										/>
									}
									fileName={`Ficha-Tecnica-${payload_info?.location?.code}-${payload_info?.location?.name}.pdf`}
								>
									<Button className='btn simple-btn'>
										<i className='bi bi-file-earmark-pdf-fill ms-1 me-2'></i>Descargar {org_reports[0]?.name}
									</Button>
								</PDFDownloadLink>
							} */}
						</Col>
					</Row>
					<hr/>
					
					{/* Location Info */}
					<LocationInfo locationInfo={payload_info?.location} />

					{/* Payload Info */}
					<PayloadData width={width} isPayload={true} info={payload_info} />

					{/* Payload History */}
					<p className='text-center fs-4 my-0'><i className='bi bi-clipboard2-data ms-1 me-2'></i>{t('payload.payloadStatus.title')}</p>
					<form className='row gy-3 mb-4 my-0'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
							<label className='form-label'>{t('payload.payloadStatus.status')}</label>
							<input type='text' className='form-control' defaultValue={getPayloadStatusDesc(payload_info.status)} disabled/>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
							<label className='form-label'>{t('payload.payloadStatus.statusDate')}</label>
							<input type='text' className='form-control' disabled defaultValue={historyDate} />
						</div>
					</form>
					<div className='d-grid gap-2 mb-4'>
						<button type='button' className='btn simple-btn' data-bs-toggle='modal' data-bs-target='#payloadHistory'>
							{t('payload.payloadStatus.historyBtn')}
						</button>
					</div>
					<hr/>

					{/* Payload Score (if necessary) */}
					<p className='text-center fs-4 my-0'>
						<i className='bi bi-award ms-1 me-2'></i>{t('payload.payloadScore.title')}
						{!isEmpty(indicators_progress?.indicators) &&
							<OverlayTrigger
								trigger='click'
								placement={width < 993 ? 'bottom' : 'right'}
								overlay={
									<Popover id='indicators-info'>
										<Popover.Header as='h3'>Info</Popover.Header>
										<Popover.Body>
											<p className='my-0'>
												<strong>ID:</strong> {indicators_progress?.indicators?._id?.$oid}
											</p>
											<p className='my-0'>
												<strong>{t('statusModal.tableHeaders.date')}:</strong> {epochToLocalDate(indicators_progress?.indicators?.date?.$date, localStorage.getItem('i18nextLng'))}
											</p>
										</Popover.Body>
									</Popover>
								}
							>
								<Button className='info-btn ms-2'><i className='bi bi-info-circle-fill'></i></Button>
							</OverlayTrigger>
						}
					</p>
					{indicators_progress_loading
						?	<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
								<p className='fs-5 m-0'>{t('payload.payloadScore.loading')}</p>
								<div className='spinner-margin'>
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</div>
							</Container>
						:	indicators_progress?.indicators?.details?.length > 0
							?	<PayloadRating
									organizationId={payload_info?.organization?._id?.$oid}
									score={indicators_progress?.indicators?.score}
									indicators={indicators_progress?.indicators?.details}
								/>
							:	<form className='row gy-3 mb-4 my-0'>
									<p className='form-label text-center'>{t('payload.payloadScore.error')}</p>
								</form>
					}
					<hr/>
					
					{/* Payload Content */}
					<p className='text-center fs-4'><i className='bi bi-table ms-1 me-2'></i>{t('payload.payloadContent.title')}</p>
					<div className='mb-4'>
						<Table responsive='lg'>
							<thead className='table-head'>
								<tr>
									<th scope='col'>{t('payload.payloadContent.tableHeaders.type')}</th>
									<th scope='col'>{t('payload.payloadContent.tableHeaders.info')}</th>
									<th scope='col' className='text-center'>{t('payload.payloadContent.tableHeaders.images')}</th>
								</tr>
							</thead>
							<tbody>
								{parsedPayload.length > 0
									? parsedPayload.map((element, idx) => (
										<Fragment key={idx}>
											{Object.keys(element).map((key, i) => (
												<tr key={i}>
													<td className='align-middle'><strong>{formatString(key)}</strong></td> 
													<td className='align-middle'>
														{typeof element[key] === 'object'
															? <Fragment>
																	{element[key].items.map((item, itemIdx) => (
																		<Fragment key={itemIdx}>
																			{Object.entries(item).map(([itemKey, value]) => (
																				<p className='my-0' key={itemKey}><strong>{formatString(itemKey)}:</strong> {value}</p>
																			))}
																		</Fragment>
																	))}
																</Fragment>
															: !isObjectId(element[key]) && <p className='my-0'>{element[key]}</p> 
														}
													</td>
													<td className='align-middle text-center'>
														{typeof element[key] === 'object'
															? <Fragment>
																	{element[key].photos.map((item, photoIdx) => (
																		<Fragment key={photoIdx}>
																			{Object.entries(item).map(([itemKey, value]) => (
																				<Fragment key={itemKey}>
																					{itemKey === 'image' 
																						? <RenderImage
																								imageId={value}
																								defaultSrc={process.env.PUBLIC_URL + '/assets/ref-image2.svg'}
																								alt={key + ' image'}
																								refImage={false}
																							/>
																						: <p className='mb-2'>
																								<strong>{itemKey === 'image' ? null : formatString(itemKey) + ': '}</strong>{value}
																							</p>
																					}
																				</Fragment>
																			))}
																		</Fragment>
																	))}
																</Fragment>
															: isObjectId(element[key]) && 
																<RenderImage
																	imageId={element[key]}
																	defaultSrc={process.env.PUBLIC_URL + '/assets/ref-image2.svg'}
																	alt={key + ' image'}
																	refImage={false}
																/>
														}
													</td>
												</tr>
											))}
										</Fragment>
									))
									: <tr>
											<td className='text-center' colSpan={4}>{t('payload.payloadContent.errors.noContent')}</td>
										</tr>
								}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
			</div>
		);
	}
}

export default Payload;
