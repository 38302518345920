import React, { useEffect, useState } from 'react';

// Packages
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// Styles
import { styles } from './styles';

const PayloadScore = (props) => {
	const { total, indicators } = props;
	
	const [indicatorsData, setIndicatorsData] = useState([]);

	useEffect(() => {
		if (indicators.length > 0) {
			indicators.map((group) => {
				group.indicators = group.indicators.filter(ind => ind.score !== undefined);
			});

			const indicatorsFiltered = indicators.filter(group => group.indicators.length > 0);
			setIndicatorsData(indicatorsFiltered);
		}
	}, [indicators]);

	return (
		<View>
			<View style={styles.section}>
				<Text style={styles.sectionText}>Calificación del Censo - Total: {total}</Text>
			</View>
			{indicatorsData.map((group, groupIdx) => (
				<View key={groupIdx} style={styles.indicators}>
					<Text style={styles.indicatorsGroup}>{group?.group?.name}</Text>
					{/* Scores */}
					<View style={styles.indicatorsTable}>
						{group?.indicators.map((ind, indIdx) => (
							<View key={indIdx} style={styles.indicatorsCol}>
								<View style={styles.flexWrap}>
									<Text style={styles.boldTex}>{ind?.name}:  </Text>
									<Text style={styles.normalText}>{ind?.score}</Text>
								</View>
							</View>
						))}
					</View>
				</View>
			))}
		</View>
	);
}

PayloadScore.prototypes = {
	total: PropTypes.number.isRequired,
	indicators: PropTypes.array.isRequired
}

export default PayloadScore;
