import React, { useEffect, useRef, useState } from 'react';

// Packages
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Utils
import { isAppleOS } from '../../utils/checkOS';

const ImageControls = () => {
	const { t } = useTranslation();

	const [appleOS, setAppleOS] = useState(false);

	const closeModalRef = useRef();

	useEffect(() => {
		setAppleOS(isAppleOS());
	}, []);

	return (
		<div className='modal fade' id='imageControls' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='imageControlsLabel'>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='imageControlsLabel'>
							<i className='bi bi-dpad ms-1 me-2'></i> {t('display.actionBtns.controls')}
						</h5>
						<button type='button' className={clsx('btn-close', {
								'btn-close-white': localStorage.getItem('theme') === 'dark'
							})} data-bs-dismiss='modal' aria-label='Close' ref={closeModalRef} 
						></button>
					</div>
					<div className='modal-body'>
						<Row className='text-center align-items-center mb-3'>
							<Col>{t('controlsModal.zoom')}</Col>
							<Col><Image style={{ height: 30 }} src={process.env.PUBLIC_URL + '/assets/scroll.png'} alt='Scroll' fluid /></Col>
						</Row>
						<Row className='text-center align-items-center mb-3'>
							<Col>{t('controlsModal.move')}</Col>
							<Col className='align-items-center'>
								{appleOS
									?	<Image style={{ height: 30, marginRight: 3 }} src={process.env.PUBLIC_URL + '/assets/option.png'} alt='Option Key' fluid />
									:	<Image style={{ height: 30, marginRight: 3 }} src={process.env.PUBLIC_URL + '/assets/alt.png'} alt='Atl Key' fluid />
								}
								<i className='bi bi-plus ms-3 me-3'></i>
								<Image style={{ height: 30 }} src={process.env.PUBLIC_URL + '/assets/left-click.png'} alt='Left Click' fluid />
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageControls;
