import React, { Fragment } from 'react';

// Packages
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Components
import TablePagination from '../utils/TablePagination';

// Utils
import { getLocationStatusDesc } from '../../utils/getStatusDesc';

const LocationsTable = (props) => {
  const { locationsCount, locations, skip, setSkip, limit, locationId, setLocationId, setLocationInfo } = props;

  const { t } = useTranslation();

  const nPages = Math.ceil(locationsCount / limit);

  const onClickRow = (location) => {
    setLocationId(prevState => prevState === location._id ? '' : location._id);

    setLocationInfo({ name: location.name, code: location.code });
  }

  return ( 
    <div>
      <table className='table'>
        <thead className='table-head'>
          <tr>
            <th scope='col'>Id</th>
            <th scope='col'>{t('locationsTable.headers.name')}</th>
            <th scope='col'>{t('locationsTable.headers.code')}</th>
            <th scope='col'>{t('locationsTable.headers.status')}</th>
          </tr>
        </thead>
        <tbody>
          {locations.length > 0
            ? <Fragment>
                {locations.map((location, idx) => (
                  <tr key={idx} onClick={() => onClickRow(location)} style={{cursor: 'pointer'}}
                    className={clsx({
                      'table-success': locationId === location._id
                    })}
                  >
                    <td className='text-break'>{location._id}</td>
                    <td>{location.name}</td>
                    <td>{location.code !== null ? location.code : `${t('locationsTable.errors.noCode')}`}</td>
                    <td>{getLocationStatusDesc(location.status)}</td>
                  </tr>
                ))}
              </Fragment>
            : <tr>
                <td className='text-center' colSpan={4}>{t('locationsTable.errors.noLocations')}</td>
              </tr>
          }
        </tbody>
      </table>
      <TablePagination
        count={locationsCount}
        arrayLength={locations.length}
        nPages={nPages}
        skip={skip}
        setSkip={setSkip}
        limit={limit}
      />
    </div>
  );
}

LocationsTable.prototypes = {
  locationsCount: PropTypes.number.isRequired,
  locations: PropTypes.array.isRequired,
  skip: PropTypes.number.isRequired,
  setSkip: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  locationId: PropTypes.string.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setLocationInfo: PropTypes.func.isRequired
}
 
export default LocationsTable;
