import {
	ALERT_SET,
	ALERT_REMOVE
} from '../actions/types';

const initialState = {
	alert_show: false,
	alert_info: {}
}

export default function alertReducer(state = initialState, action){
	switch(action.type){
		case ALERT_SET:
			return {
				...state,
				alert_show: true,
				alert_info:  action.payload
			}
		case ALERT_REMOVE:
			return {
				...state,
				alert_show: false,
				alert_info: {}
			}
		default:
			return state;
	}
}
