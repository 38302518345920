/*const red = [255, 20, 20];
const yellow = [255, 233, 20];
const green = [20, 255, 20];
const lightBlue = [20, 182, 255];
const purple = [163, 20, 255];
const orange = [255, 148, 20];
const lime = [125, 255, 20];
const aqua = [20, 255, 224];
const blue = [20, 82, 255];
const pink = [251, 20, 255];
*/

//https://htmlcolorcodes.com/es/tabla-de-colores/ (Diseno plano)
const _1 = [229, 115, 115]
const _7 = [240, 98, 146]
const _13 = [186, 104, 200]
const _19 = [149, 117, 205]
const _25 = [121, 134, 203]
const _31 = [100, 181, 246]


const _2 = [79, 195, 247]
const _8 = [77, 182, 172]
const _14 = [129, 199, 132]
const _20 = [174, 213, 129]
const _26 = [220, 231, 117]
const _32 = [255, 241, 118]

const _3 = [255, 213, 79]
const _9 = [255, 183, 77]
const _15 = [255, 138, 101]
const _21 = [161, 136, 127]
const _27 = [144, 164, 174]
const _33 = [211, 47, 47]

const _4 = [194, 24, 91]
const _10 = [123, 31, 162]
const _16 = [81, 45, 168]
const _22 = [48, 63, 159]
const _28 = [25, 118, 210]
const _34 = [2, 136, 209]

const _5 = [0, 151, 167]
const _11 = [0, 121, 107]
const _17 = [56, 142, 60]
const _23 = [104, 159, 56]
const _29 = [175, 180, 43]
const _35 = [251, 192, 45]

const _6 = [255, 160, 0]
const _12 = [245, 124, 0]
const _18 = [230, 74, 25]
const _24 = [93, 64, 55]
const _30 = [97, 97, 97]
const _36 = [69, 90, 100]


/**
 * Class to create a Singleton RGBColor
 */
export class RGBColor{
	count = 0;

	constructor(){
		//Singleton
		if(typeof RGBColor.instance === 'object'){
			return RGBColor.instance
		}else{
			RGBColor.instance = this
			return this
		}
	}

	/**
	 * Round number: if is less to 0 return 0, if is bigger than 1 return 1 else return same number
	 * @return {Array<Number>}  Array format [R,G,B]
	 */
	getColor(){
		this.count += 1
		switch (this.count) {
			case 1: 
				return _1
			case 2:
				return _2
			case 3:
				return _3
			case 4:
				return _4
			case 5:
				return _5
			case 6:
				return _6
			case 7:
				return _7
			case 8:
				return _8
			case 9:
				return _9
			case 10:
				return _10
			case 11:
				return _11
			case 12:
				return _12
			case 13:
				return _13
			case 14:
				return _14
			case 15:
				return _15
			case 16:
				return _16
			case 17:
				return _17
			case 18:
				return _18
			case 19:
				return _19
			case 20:
				return _20
			case 21:
				return _21
			case 22:
				return _22
			case 23:
				return _23
			case 24:
				return _24
			case 25:
				return _25
			case 26:
				return _26
			case 27:
				return _27
			case 28:
				return _28
			case 29:
				return _29
			case 30:
				return _30
			case 31:
				return _31
			case 32:
				return _32
			case 33:
				return _33
			case 34:
				return _34
			case 35:
				return _35
			case 36:
				this.count = 0
				return _36
			default:
				return _1
		}
	}
}
