import Axios from 'axios';

import {
	ALL_IMAGES_LOADING,
	ALL_IMAGES_GET,
	ALL_IMAGES_ERROR,
  IMAGE_LOADING_TRUE,
  IMAGE_LOADING_FALSE,
  IMAGE_INFO_GET,
  IMAGE_INFO_ERROR,
	IMAGE_RESULT_LOADING,
  IMAGE_RESULT_GET,
  IMAGE_RESULT_ERROR,
	IMAGE_TAGS_LOADING,
  IMAGE_TAGS_GET,
  IMAGE_TAGS_ERROR,
  IMAGE_TICKETS_GET,
  IMAGE_TICKETS_ERROR
} from './types';

// Utils
import create_query_params from '../utils/create_query_params';

export const image_loading_true = () => dispatch => {
  dispatch({
    type: IMAGE_LOADING_TRUE
  })
}

export const image_loading_false = () => dispatch => {
  dispatch({
    type: IMAGE_LOADING_FALSE
  })
}

export const get_all_images = (filters) => dispatch => {
	dispatch({
		type: ALL_IMAGES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/images?sort=true&`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ALL_IMAGES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_IMAGES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_IMAGES_ERROR,
			payload: {type: 'all_images', msg: err.message}
		});

		dispatch({
			type: ALL_IMAGES_LOADING,
			payload: false
		});
	})
}

export const clear_image_info = () => dispatch => {
  dispatch({
    type: IMAGE_INFO_GET,
    payload: {}
  })
}

export const get_image_info = (image_id) => dispatch => {
  // console.log(image_id)
  dispatch(image_loading_true());
  let url = process.env.REACT_APP_SERVER_URL + `/api/images/${image_id}/info`;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data)
    dispatch({
      type: IMAGE_INFO_GET,
      payload: res.data
    })
    dispatch(image_loading_false());
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: IMAGE_INFO_ERROR,
      payload: {type: 'image_info', msg: err.message}
    })
    dispatch(image_loading_false());
  })
}

export const get_image_result = (image_id) => dispatch => {
  // console.log(image_id)
	dispatch({ type: IMAGE_RESULT_LOADING });
  let url = process.env.REACT_APP_SERVER_URL + `/api/images/${image_id}/result`;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data)
    dispatch({
      type: IMAGE_RESULT_GET,
      payload: res.data
    })
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: IMAGE_RESULT_ERROR,
      payload: {type: 'image_result', msg: err.message}
    })
  })
}

export const get_image_tags = (org_id) => dispatch => {
  // console.log(org_id)
  dispatch({ type: IMAGE_TAGS_LOADING });
  let url = process.env.REACT_APP_SERVER_URL + `/api/images/tags?scope=2&resource=${org_id}`;

  Axios.get(url)
  .then((res) => {
    // console.log(res.data)
    dispatch({
      type: IMAGE_TAGS_GET,
      payload: res.data
    })
  }).catch((err) => {
    // console.log(err);
    dispatch({
      type: IMAGE_TAGS_ERROR,
      payload: {type: 'image_tags', msg: err.message}
    })
  })
}

export const download_image = (image_id, image_info) => dispatch => {
  let filename = image_info.filename;

  Axios.request({
    url: `${process.env.REACT_APP_SERVER_URL}/api/images/${image_id}/data`,
    method: 'GET',
    responseType: 'blob',
  }).then(({data}) => {
    const downloadURL = window.URL.createObjectURL(new Blob([data]));
    
    const link = document.createElement('a');
    link.href = downloadURL;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch((err) => {
    console.log(err);
  })
}
