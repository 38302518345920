import React, { useState, useEffect, createContext } from 'react';

export const ThemeContext = createContext(null);

const ThemeProvider = (props) => {
	const { children } = props;
	
	const [theme, setTheme] = useState('light');

	const onChangeTheme = (selectedTheme) => {
		localStorage.setItem('theme', selectedTheme);
		setTheme(selectedTheme);
	}

	useEffect(() => {
		var lsTheme = localStorage.getItem('theme');
		lsTheme === null && (lsTheme = 'light')
		
		localStorage.setItem('theme', lsTheme);
		setTheme(lsTheme);
	}, []);

	return ( 
		<ThemeContext.Provider value={[theme, onChangeTheme]}>
			{children}
		</ThemeContext.Provider>
	);
}

export default ThemeProvider;
