import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Buffer } from 'buffer';
import clsx from 'clsx';

const RenderImage = (props) => {
  const { imageId, defaultSrc, alt, refImage } = props;

  const navigate = useNavigate();

  const { payload_info } = useSelector(state => state.payload);

  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (imageId !== '' && imageId !== 'None') {
      Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/images/${imageId}/data`,{
        responseType: 'arraybuffer'
      })
      .then((res) => {
        let data = `data:${res.headers['content-type']};base64, ${Buffer.from(res.data, 'binary').toString('base64')}`;
  
        if (isMounted) setImgSrc(data);
      }).catch((err) => {
        if (isMounted) setImgSrc(defaultSrc);
      });
    }
    else {
      if (isMounted) setImgSrc(defaultSrc);
    }

    return () => isMounted = false;
  }, [imageId]);

  const onClickImage = () => {
    if (!refImage) {
      navigate(`/locations/${payload_info.location._id.$oid}/payloads/${payload_info._id.$oid}/image/${imageId}`);
    }
  }

  return ( 
    <img 
      className={clsx({
        'ref-img': refImage,
        'payload-img': !refImage
      })}
      style={!refImage ? {cursor: 'pointer'} : null}
      src={imgSrc} alt={alt} onClick={onClickImage}
    />
  );
}

RenderImage.propTypes = {
  imageId: PropTypes.string.isRequired,
  defaultSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  refImage: PropTypes.bool.isRequired
};
 
export default RenderImage;
