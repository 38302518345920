import { fabric } from "fabric";
//Models
import { PercepthorArticle } from './PercepthorArticle'
//Helpers
//import {SmallNoty} from '../helpers/message'
import { COLOR_TRANSPARENCY } from '../utils/fabricJsCustom'
//Vendors
import { Contextual } from '../static/vendors/ContextualJS/contextual'
import '../static/vendors/ContextualJS/contextual.theme.css'
import '../static/vendors/ContextualJS/contextual.css'

var articleAux, isLeftClickDown, origX, origY; //Variables to draw PercpethorAticles with mouse and ctrl key

/**
 * Class to create a PercepthorCanvas
 * @extends fabric.Canvas
 * @param  {PercepthorImage}  percepthorImage actual PercepthorImage to show
 * @param  {PercepthorArticles[]} listPercepthorArticles listPercepthorArticles insside
 * @return {PercepthorCanvas}  PercepthorCanvas Object
 */
export var PercepthorCanvas = fabric.util.createClass(fabric.Canvas, {
	type: 'percepthorCanvas',
	// initialize can be of type function(options) or function(property, options), like for text.
	// no other signatures allowed.
	initialize: function(options) {
		options || (options = { });
		this.callSuper('initialize', options);
		this.set('percepthorImage', options.percepthorImage || null); //Atributes
		this.set('listPercepthorArticles', options.percepthorImage || null);
		this.set('activeTag', null);
		this.set('cursorMode', 'select')
		this.set('listPercepthorArticlesDeleted' , []);
		this.set('setTriggerUpdateQuantityTags' , null);
	},

	toObject: function() {
		return fabric.util.object.extend(this.callSuper('toObject'), {
			percepthorImage: this.get('percepthorImage'),
			listPercepthorArticles: this.get('listPercepthorArticles'),
			activeTag: this.get('activeTag'),
			cursorMode: this.get('cursorMode'),
			listPercepthorArticlesDeleted: this.get('listPercepthorArticlesDeleted'),
		});
	},

	_render: function(ctx) {
		this.callSuper('_render', ctx);
	}
});

//This function is used when cloned a PercepthorCanvas Object
PercepthorCanvas.fromObject = function (object, callback, forceAsync) {
	//https://stackoverflow.com/questions/41568369/uncaught-typeerror-cannot-set-property-fromobject-of-undefined-fabric-custom
	return fabric.Object._fromObject('PercepthorCanvas', object, callback, forceAsync, )//['id','className','rgbColor','fill','stroke', 'julio'])
};

/**
 * Active hook triggerUpdateQuantityTags and render all canvas
 * @return {Void}  void
*/
PercepthorCanvas.prototype.customRequestRenderAll = function (params) {
	if(this.setTriggerUpdateQuantityTags){
		this.setTriggerUpdateQuantityTags(prevState => (!prevState))
	}
	this.requestRenderAll()
}

/**
 * Events in PercepthorCanvas, moved, click, zoom, scaled
 * @return {Void}  void
 */
PercepthorCanvas.prototype.on({
//Comportamientos movimiento y redimension de objetos dentro de algun objeto PercepthorCanvas
	'object:moving': function(event) {
		var object = event.target
		var objects = object?._objects
		if(objects){ //Read all objects active
			for (let i=0; i<objects.length; i++) {
				if(objects[i]?.lockMovementX || object[i]?.lockMovementY){
					console.log('Uno de los objetos activos esta bloqueado')
					object.set({
						lockMovementX: true,
						lockMovementY: true
					});
					return false
				}
			}
		}
	},

	'object:scaling': function(event) {
		var object = event.target
		var objects = object?._objects
		if(objects){ //Read all objects active
			for (let i=0; i<objects.length; i++) {
				if(objects[i]?.lockScalingX || object[i]?.lockScalingY){
					console.log('Uno de los objetos activos esta bloqueado')
					object.set({
						lockScalingX: true,
						lockScalingY: true
					});
					return false
				}
			}
		}
	},

	'object:moved': function(event) {
		var object = event.target
		//console.log('Canvas: Se movio algo', object)
		this.validateObjectMove(object)
	},

	'object:scaled': function(event) {
		var object = event.target
		//console.log('Canvas: Se rescalo algo', object)
		this.validateObjectMove(object)
	},

	'selection:created': function(event) {
		//var object = event.target
		//console.log('Canvas: Se selecciono algo', object)
	},

	'before:selection:cleared': function(event) {
		//var object = event.target
		//console.log('Canvas: Se deselecciono algo', object)
		//setColorDesactivated(object)
	},

	//Comportamientos de zoom y desplazamiento de viewport objeto PercepthorCanvas, dibijar PercpethorAticle con mouse y ctrl key
	'mouse:wheel' : function(opt) { //Rueda de zoom
		var delta = opt.e.deltaY; //DeltaY es positivo cuando baja la rueda y negativo cuando sube
		var zoom = this.getZoom(); //this canvas
		zoom *= 0.999 ** delta;
		if (zoom > 20) zoom = 20;
		if (zoom < 0.15) zoom = 0.15;
		this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
		opt.e.preventDefault();
		opt.e.stopPropagation();
	},

	'mouse:down' : function(opt) { //Left click mouse down
		//console.log('mouse:down')
		var evt = opt.e;
		if (evt.altKey === true) { //Tecla ALT
			this.isDragging = true;     //Habilitamos el arraste
			this.selection = false;     //Deshabilitamos la seleecion
			this.lastPosX = evt.clientX;
			this.lastPosY = evt.clientY;
		}
		if (evt.ctrlKey === true || this.cursorMode === 'draw'){ //Tecla CTRL o en modo 'draw'
			isLeftClickDown = true;
			this.isDragging = false     //Deshabilitamos el arraste
			this.isDrawingMode = true;  //Habilitamos el modo dibujo 
			this.selection = false;     //Deshabilitamos la seleecion
			//console.log('down', opt)
			this.discardActiveObject()
			this.requestRenderAll()
			var pointer = this.getPointer(opt.e); //Obtenemos el punto donde se hizo click
			origX = pointer.x;
			origY = pointer.y;
			if(this.activeTag){ //Hay un tag activo
				let rgbAux = this.activeTag.arrayRGBColor
				articleAux = new PercepthorArticle({
					//Attributes of Fabric.Rect
					left: origX,
					top: origY,
					originX: 'left',
					originY: 'top',
					width: pointer.x-origX,
					height: pointer.y-origY,
					objectCaching: false,
					strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
					strokeUniform: true,
					fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`,
					stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`,
					//Extended attributes for PercepthorArticle
					id: Date.parse(new Date()),
					label: '',
					tag: this.activeTag,
					result: null
				});
				this.add(articleAux);
				this.customRequestRenderAll()
			}else{
				console.log('error', 'No hay una etiqueta activa');
			}
		}
	},

	'mouse:move': function(opt) {
		//console.log('mouse:move')
		if (this.isDragging) { //Tecla ALT, modo arrastrar
			var e = opt.e;
			var vpt = this.viewportTransform;
			vpt[4] += e.clientX - this.lastPosX;
			vpt[5] += e.clientY - this.lastPosY;
			this.requestRenderAll();
			this.lastPosX = e.clientX;
			this.lastPosY = e.clientY;
		}
		else if(isLeftClickDown && this.activeTag){ //Left click mouse down vamos obteniendo las cooirdenadas
			var pointer = this.getPointer(opt.e);
			
			if(origX>pointer.x){
				articleAux.set({ left: Math.abs(pointer.x) });
			}
			if(origY>pointer.y){
				articleAux.set({ top: Math.abs(pointer.y) });
			}
			
			articleAux.set({ width: Math.abs(origX - pointer.x) });
			articleAux.set({ height: Math.abs(origY - pointer.y) });

			this.requestRenderAll();
		}
		else if (this.cursorMode === 'inspect' && opt.target){ //Modo inspeccion y hay un objeto Fabric debajo
			//console.log('mouse:move')
			let thereIsPercepthorArticle = false
			let object = opt.target
			if(object){
				let contentHTML =	`<div>
														<ul class="list-group">`
				if (object._objects) {
					object._objects.forEach(element => {
						if(element.type === 'percepthorArticle'){
							let origin = element.result ? 'P' : 'U'
							contentHTML +=	`<li class="list-group-item d-flex justify-content-between align-items-center bg-dark text-white">
																${element.tag.className}
																<span class="badge rounded-pill" style="background-color:rgba(${element.tag.arrayRGBColor[0]}, ${element.tag.arrayRGBColor[1]}, ${element.tag.arrayRGBColor[2]}, 1);">
																	${origin}
																</span>
															</li>`
						}
					});
					thereIsPercepthorArticle = true
				}else if(object.type === 'percepthorArticle'){
					thereIsPercepthorArticle = true
					let origin = object.result ? 'P' : 'U'
					contentHTML +=	`<li class="list-group-item d-flex justify-content-between align-items-center bg-dark text-white">
														${object.tag.className}
														<span class="badge rounded-pill" style="background-color:rgba(${object.tag.arrayRGBColor[0]}, ${object.tag.arrayRGBColor[1]}, ${object.tag.arrayRGBColor[2]}, 1);">
															${origin}
														</span>
													</li>`
				}
				contentHTML += '</ul></div>'
							
				if(thereIsPercepthorArticle === true){
					new Contextual({
						event: opt.e,
						isSticky: false,
						width: '250px',
						items: [{type: 'custom', markup: contentHTML},]
					});
				}else{ //Create a new void Contextual to clean previuos Contextuals
					new Contextual({
						event: opt.e,
						isSticky: false,
						width: '0px',
						items: []
					})
				}
			}
		}
	},

	'mouse:up' : function(opt) { //Left click mouse up
		//console.log('mouse:up')
		// on mouse up we want to recalculate new interaction
		// for all objects, so we call setViewportTransform
		this.setViewportTransform(this.viewportTransform);
		this.isDragging = false;    //Deshabilitamos el arraste
		this.selection = true;      //Habilitamos la seleccion
		this.isDrawingMode = false; //Deshabilitamos el modo dibujo
		if(articleAux){ //Se dibujo algo?
			this.validateObjectMove(articleAux)//Validamos que el articleAux creaado con el mouse no se salga de la imagen
			articleAux = null //Limpiamos la variable
		}
		isLeftClickDown = false; //La vairble con la que manejamos el saber si se presiono la tecla ctrl
		if (opt.button === 3) { //RigthClick ContextMmenu
			if (opt.target){
				let object = opt.target
				let contentHTML =	`<div class="p-2 mb-1">
														<p class="text-center">Articulo(s) seleccionado(s)</p>
														<ul class="list-group">`
				if (object._objects) {
					object._objects.forEach(element => {
						if(element.type === 'percepthorArticle'){
							let origin = element.result ? 'P' : 'U'
							contentHTML +=	`<li class="list-group-item d-flex justify-content-between align-items-center bg-dark text-white">
																${element.tag.className}
																<span class="badge rounded-pill" style="background-color:rgba(${element.tag.arrayRGBColor[0]}, ${element.tag.arrayRGBColor[1]}, ${element.tag.arrayRGBColor[2]}, 1);">
																	${origin}
																</span>
															</li>`
						}
					});
				}else if(object.type === 'percepthorArticle'){
					let origin = object.result ? 'P' : 'U'
					contentHTML +=	`<li class="list-group-item d-flex justify-content-between align-items-center bg-dark text-white">
														${object.tag.className}
														<span class="badge rounded-pill" style="background-color:rgba(${object.tag.arrayRGBColor[0]}, ${object.tag.arrayRGBColor[1]}, ${object.tag.arrayRGBColor[2]}, 1);">
															${origin}
														</span>
													</li>`
				}
				contentHTML += '</ul></div>'

				new Contextual({
					event: opt.e,
					isSticky: false,
					width: '250px',
					items: [{type: 'custom', markup: contentHTML},]
				});
			}
		}
	}
});

/**
 * Adjust a PercepthorImage inside PercepthorCanvas
 * @return {void}  void
 */
PercepthorCanvas.prototype.setFitZoom = function () {
	//console.log('setFitZoom')
	//canvas.zoomToPoint({ x: 0, y: 0 }, 0.2);
	//canvas.setZoom(0.2);
	if (!this.percepthorImage){
		//alert('No hay imagen')
		console.log('error', 'No hay imagen en el lienzo');
		return
	}
	let canvasOriginalWidth = this.width
	let canvasOriginalHeight = this.height
	let imageObj = this.percepthorImage
	if (imageObj){
		//Imagen horizontal o vertical?
		if(imageObj.width > imageObj.height){
			//El ancho original del canvas entre el ancho de la imagen para que no se vea feo
			let zoom = canvasOriginalWidth/imageObj.width
			//console.log('Es mas ancha, Zoom inicial: '+zoom);
			this.setZoom(zoom);
			let newHeight = imageObj.height*zoom
			let dif = Math.round((canvasOriginalHeight-newHeight)/2)
			var vpt1 = this.viewportTransform;
			vpt1[4] = 0;
			vpt1[5] = dif;
			this.requestRenderAll();
			this.lastPosX = 0;
			this.lastPosY = dif;
		}else{
			let zoom = canvasOriginalHeight/imageObj.height
			//console.log('Es mas larga, Zoom inicial: '+zoom);
			this.setZoom(zoom);
			let newWidth = imageObj.width*zoom
			let dif = Math.round((canvasOriginalWidth-newWidth)/2)
			var vpt2 = this.viewportTransform;
			vpt2[4] = dif;
			vpt2[5] = 0;
			this.requestRenderAll();
			this.lastPosX = dif;
			this.lastPosY = 0;
		}
	}else{
		console.error('No hay imagen en el canvas')
	}
}

/**
 * Valid fabric.Object moved inside PercepthorCanvas` PercepthorImage
 * @param {fabric.Object} object Object to validate
 * @return {void}  void
 */
PercepthorCanvas.prototype.validateObjectMove = function(object){
	//console.log('validateObjectMove')
	if (!this.percepthorImage){
		//alert('No hay imagen')
		console.log('error', 'No hay imagen en el lienzo');
		return
	}
	var obj = object;
	var canvas = obj.canvas;
	//const scale = obj.getObjectScaling();
	const maxWidth = this.percepthorImage.width
	const maxHeight = this.percepthorImage.height

	var objStrokeWidth = obj.strokeWidth //+ 1
	var objWidth  = Math.abs(obj.aCoords.tl.x - obj.aCoords.tr.x)
	objWidth-=objStrokeWidth*2 //Quitammos le borde del recuadro
	var objHeigth = Math.abs(obj.aCoords.tl.y - obj.aCoords.bl.y)
	objHeigth-=objStrokeWidth*2 //Quitammos le borde del recuadro
	
	//printObject(obj)
	
	//Validamos que  al mover no este fuera del canvas, No se usa x1 ni y1 poruqe eso solo son la equina superior izq o el Point "obj.aCoords.tl"
	//Si esta alguna aprte del recuadro fuera del canvas lo ponemos en el filo más proximo
	
	//Validacion redimension del objeto, si redimenciona exediendo el canvas ajustamos el objeto al maximo permitido
	//Validacion redimension ancho
	if(objWidth+objStrokeWidth*2 > maxWidth){ //Si es más ancho que el canvas
		//console.log('Muy ancho')
		obj.left = 0
		obj.set({ width: maxWidth-objStrokeWidth*2, scaleX: 1, scaleY: 1, });
	}
	//Validacion redimension alto
	if(objHeigth+objStrokeWidth*2 > maxHeight){ //Si es más alto que el canvas
		//console.log('Muy largo')
		obj.top = 0
		obj.set({ height: maxHeight-objStrokeWidth*2, scaleX: 1, scaleY: 1, });
	}

	//Validaciones movimiento, no se puede salir del canvas
	//Validacion movimiento hacia arriba 
	if(obj.aCoords.tl.y-objStrokeWidth*2 < 0){
		//console.log('Se sale por arriba')
		obj.top = 0
	}
	//Validacion movimiento hacia derecha
	if( (obj.aCoords.tl.x+objStrokeWidth*2 + objWidth) > maxWidth){
		//console.log('Se sale por la derecha')
		obj.left = maxWidth - objWidth - objStrokeWidth*2
	}
	//Validacion movimiento hacia abajo 
	if( (obj.aCoords.tl.y+objStrokeWidth*2 + objHeigth) > maxHeight){
		//console.log('Se sale por abajo')
		obj.top = maxHeight - objHeigth - objStrokeWidth*2
	}
	//Validacion movimiento hacia izquierda
	if(obj.aCoords.tl.x-objStrokeWidth*2 < 0){
		//console.log('Se sale por la izq')
		obj.left = 0
	}

	//Renderizamos de nuevo todo
	canvas.requestRenderAll();
}

PercepthorCanvas.prototype.removePercepthorImage = function (params) {
	if(this.percepthorImage){
		this.remove(this.percepthorImage);
		this.requestRenderAll();
		this.percepthorImage = null
	}else{
		console.error('No hay imagen que remover')
	}
}

PercepthorCanvas.prototype.setPercepthorImage = function (newPercepthorImage) {
	if(this.percepthorImage){
		this.removePercepthorImage()
	}
	this.percepthorImage = newPercepthorImage
	this.add(newPercepthorImage)
	this.setFitZoom()
	this.requestRenderAll();
}

PercepthorCanvas.prototype.getPercepthorImage = function () {
	return this.percepthorImage
}

PercepthorCanvas.prototype.removePercepthorArticles = function (params) {
	let objects = this.getObjects()
	if(objects){
		objects.forEach(element => {
			if(element.type === 'percepthorArticle'){
				this.remove(element)
			}
		});
	}else{
		console.error('No hay percepthorArticle que remover')
	}
	this.requestRenderAll();
}

export default PercepthorCanvas;