import {
	LOCATION_LOADING_TRUE,
	LOCATION_LOADING_FALSE,
	LOCATIONS_GET,
	LOCATIONS_ERROR,
	LOCATIONS_RESET,
	LOCATION_INFO_GET,
	LOCATION_INFO_ERROR,
	LOCATION_RECORDS_GET,
	LOCATION_RECORDS_ERROR,
	LOCATION_DATA_GET,
	LOCATION_DATA_ERROR
} from '../actions/types';

const initialState = {
	location_loading: false,
	locations: { count: 0, locations: [] },
	location_info: {},
	location_records: { count: 0, records: [] },
	location_data: {},
	error: { isError: false, type: '', msg: '' }
}

export default function locationReducer(state = initialState, action){
	switch(action.type){
		case LOCATION_LOADING_TRUE:
			return {
				...state,
				location_loading: true,
				error: { isError: false, type: '', msg: '' }
			}
		case LOCATION_LOADING_FALSE:
			return {
				...state,
				location_loading: false
			}
		case LOCATIONS_GET:
			return {
				...state,
				locations: {
					count: action.payload.count,
					locations: action.payload.locations
				},
				error: { isError: false, type: '', msg: '' }
			} 
		case LOCATIONS_RESET:
			return {
				...state,
				locations: { count: 0, locations: [] }
			}
		case LOCATION_INFO_GET:
			return {
				...state,
				location_info: action.payload
			}
		case LOCATION_RECORDS_GET:
			return {
				...state,
				location_records: {
					count: action.payload.count,
					records: action.payload.records.sort((a,b)=> (a.date.$date < b.date.$date ? 1 : -1))
				}
			}
		case LOCATION_DATA_GET:
			return {
				...state,
				location_data: action.payload
			}
		case LOCATIONS_ERROR:
		case LOCATION_INFO_ERROR:
		case LOCATION_RECORDS_ERROR:
		case LOCATION_DATA_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
