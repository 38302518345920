import React, { Fragment, useEffect, useState } from 'react';

// Packages
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import TablePagination from '../utils/TablePagination';

// Utils
import { getIssueStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const IssuesTable = (props) => {
	const { issuesCount, issues, skip, setSkip, limit } = props;

	const { t } = useTranslation();

	const nPages = Math.ceil(issuesCount / limit);

	const [width, setWidth]   = useState(window.innerWidth);

	const updateDimensions = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	return ( 
		<div style={{marginTop: '3rem'}}>
			<Table responsive='lg'>
				<thead className='table-head'>
					<tr>
						{width > 800 && <th scope='col'>Id</th>}
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('issue.issuesTable.headers.code')}</th>
						<th scope='col'>{t('issue.issuesTable.headers.date')}</th>
						<th scope='col'>{t('issue.issuesTable.headers.type')}</th>
						<th scope='col'>{t('issue.issuesTable.headers.action')}</th>
					</tr>
				</thead>
				<tbody>
					{issues.length > 0
						? <Fragment>
								{issues.map((issue, idx) => (
									issue?.location &&
										<tr key={idx}>
											{width > 800 && <td className='text-break'>{issue?._id?.$oid}</td>}
											<td>{issue?.location?.name || t('payload.payloadInfo.error')}</td>
											<td>{issue?.location?.code || t('issue.issuesTable.errors.noCode')}</td>
											<td>{epochToLocalDate(issue?.date?.$date, localStorage.getItem('i18nextLng'))}</td>
											<td>{getIssueStatusDesc(issue?.issue_type)}</td>
											<td>
												<a href={`/locations/${issue?.location?._id?.$oid}/issues/${issue?._id?.$oid}`} target='_blank' rel='noopener noreferrer'>
													{t('issue.issuesTable.actions.goToIssue')}
												</a>
											</td>
										</tr>
								))}
							</Fragment>
						: <tr>
								<td className='text-center' colSpan={5}>{t('issue.issuesTable.errors.noIssues')}</td>
							</tr>
					}
				</tbody>
			</Table>
			<TablePagination
				count={issuesCount}
				arrayLength={issues.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</div>
	);
}

IssuesTable.prototypes = {
	issuesCount: PropTypes.number.isRequired,
	issues: PropTypes.array.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	searchPayloads: PropTypes.func.isRequired
}

export default IssuesTable;
