import Axios from 'axios';

import {
	ISSUE_LOADING_TRUE,
	ISSUE_LOADING_FALSE,
	ISSUE_INFO_GET,
	ISSUE_INFO_ERROR,
	ISSUE_TAGS_GET,
	ISSUE_TAGS_ERROR,
	ALL_ISSUES_GET,
	ALL_ISSUES_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const issue_loading_true = () => dispatch => {
	dispatch({
		type: ISSUE_LOADING_TRUE
	})
}

export const issue_loading_false = () => dispatch => {
	dispatch({
		type: ISSUE_LOADING_FALSE
	})
}

export const get_issue_info = (issue_id) => dispatch => {
	// console.log(issue_id)
	dispatch(issue_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/issues/${issue_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ISSUE_INFO_GET,
			payload: res.data
		})
		dispatch(issue_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ISSUE_INFO_ERROR,
			payload: { type: 'issue_info', msg: err.message }
		})
		dispatch(issue_loading_false());
	})
}

export const clear_issue_info = () => dispatch => {
  dispatch({
    type: ISSUE_INFO_GET,
    payload: {}
  })
}

export const get_issue_tags = (org_id) => dispatch => {
	// console.log(org_id)
	dispatch(issue_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/issues/tags?organization=${org_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ISSUE_TAGS_GET,
			payload: res.data
		})
		dispatch(issue_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ISSUE_TAGS_ERROR,
			payload: { type: 'issue_tags', msg: err.message }
		})
		dispatch(issue_loading_false());
	})
}

export const get_all_issues = (orgFilters, areaFilters = {}, issueType, skip, limit) => dispatch => {
	let filters = { ...orgFilters, ...areaFilters };

	if (issueType !== 0) {
		filters = { ...filters, issue_type: issueType}
	}

	let url = process.env.REACT_APP_SERVER_URL + `/api/issues?skip=${skip}&limit=${limit}&`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ALL_ISSUES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_ISSUES_ERROR,
			payload: { type: 'all_issues', msg: Object.values(err.response.data)[0] }
		})
	})
}
