import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Table } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import TablePagination from '../utils/TablePagination';

// Utils
import { epochToLocalDate } from '../../utils/dateConverter';
import { getImgReconStatusDesc } from '../../utils/getStatusDesc';

const ImagesTable = (props) => {
	const { skip, setSkip, limit, setPayloadUrlFromInfo } = props;

	const { t } = useTranslation();

	const { all_images_loading, all_images } = useSelector(state => state.image);

	const nPages = Math.ceil(all_images.count / limit);

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

	const [imageUrls, setImageUrls] = useState({});
	const [width, setWidth]   = useState(window.innerWidth);

	const updateDimensions = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	// ! Comented out because we don't need to set the URL with the payload info, just use the image ID to set the URL
	// useEffect(() => {
	// 	const fetchUrls = async () => {
	// 		const urls = {};
	// 		for (const img of all_images.images) {
	// 			await setPayloadUrlFromInfo({
	// 				image_info: img,
	// 				setPayloadURL: (url) => {
	// 					urls[img._id.$oid] = url; // Save the URL for each image in the `urls` object
	// 				}
	// 			});
	// 		}
	// 		setImageUrls(urls); // Store the URLs obtained in the state
	// 	};
	// 	fetchUrls();
	// }, [all_images, setPayloadUrlFromInfo]);

	return (
		<div style={{ marginTop: '3rem', minHeight: '510px' }}>
			<Table responsive='lg'>
				<thead className='table-head'>
					<tr>
						{width > 800 && <th scope='col'>Id</th>}
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('search.imageOption.imgRecon')}</th>
						<th scope='col'>{t('payloadsTable.headers.date')}</th>
						<th scope='col'>{t('payloadsTable.headers.action')}</th>
					</tr>
				</thead>
				<tbody>
					{all_images_loading
						?	<tr>
								<td className='text-center' colSpan={6}>
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</td>
							</tr>
						: <Fragment>
								{all_images.images.length > 0
									? <Fragment>
											{all_images.images.map((img, idx) => (
												<tr key={idx}>
													{width > 800 && <td className='text-break'>{img?._id?.$oid}</td>}
													<td>{img?.filename || t('payload.payloadInfo.error')}</td>
													<td>{img?.recon !== undefined ? getImgReconStatusDesc(img?.recon) : t('payload.payloadInfo.error')}</td>
													<td>{epochToLocalDate(img?.date?.$date, localStorage.getItem('i18nextLng'))}</td>
													<td>
														{/* {imageUrls[img._id.$oid] === undefined
															?	<ClipLoader color={spinnerColor} size={20} speedMultiplier={1} cssOverride={{ 'borderWidth': '2px' }}/>
															:	<a
																	style={{ textDecoration: 'none' }}
																	href={'/image/' + img._id.$oid}
																	target='_blank'
																	rel='noopener noreferrer'
																>
																	{t('search.imageOption.goToImage')}
																</a>
														} */}
														<a
															style={{ textDecoration: 'none' }}
															href={'/image/' + img?._id?.$oid}
															target='_blank'
															rel='noopener noreferrer'
														>
															{t('search.imageOption.goToImage')}
														</a>
													</td>
												</tr>
											))}
										</Fragment>
									: <tr>
											<td className='text-center' colSpan={6}>{t('allPayloads.payloadsTable.errors.noImages')}</td>
										</tr>
								}
							</Fragment>
					}
				</tbody>
			</Table>
			<TablePagination
				count={all_images.count}
				arrayLength={all_images.images.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</div>
	);
}

ImagesTable.prototypes = {
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	setPayloadUrlFromInfo: PropTypes.func.isRequired
}

export default ImagesTable;
