import React, { Fragment } from 'react';

// Packages
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';

// Utils
import { getRecordStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const LocationRecords = (props) => {
  const { loading, records } = props;

  const { t } = useTranslation();

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

  return ( 
    <div style={{marginTop: '3rem'}}>
      <table className='table'>
        <thead className='table-head'>
          <tr>
            <th scope='col'>Id</th>
            <th scope='col'>{t('locationsRecords.headers.type')}</th>
            <th scope='col'>{t('locationsRecords.headers.date')}</th>
            <th scope='col'>{t('locationsRecords.headers.action')}</th>
          </tr>
        </thead>
        <tbody>
					{loading
						?	<tr>
								<td className='text-center' colSpan={6}>
									{/* <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span> */}
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</td>
							</tr>
						: <Fragment>
								{records.length > 0
									? <Fragment>
											{records.map((r, idx) => (
												<Fragment>
													{(r.record_type === 1 || r.record_type === 2) &&
														<tr className={clsx({
															'table-success': r.record_type === 1,
															'table-warning': r.record_type === 2
														})}
														key={idx}>
															<td className='text-break'>{r.payload !== null ? r?.payload?.$oid : r?.issue?.$oid}</td>
															<td>{getRecordStatusDesc(r.record_type)}</td>
															<td>{epochToLocalDate(r.date.$date, localStorage.getItem('i18nextLng'))}</td>
															<td>
																{r.payload !== null 
																	? <a href={`/locations/${r?.location?.$oid}/payloads/${r?.payload?.$oid}`} target='_blank' rel='noopener noreferrer'>
																			{t('locationsRecords.actions.goToPayload')}
																		</a>
																	: <a href={`/locations/${r?.location?.$oid}/issues/${r?.issue?.$oid}`} target='_blank' rel='noopener noreferrer'>
																			{t('locationsRecords.actions.goToIssue')}
																		</a>
																}
															</td>
														</tr>
													}
												</Fragment>
											))}
										</Fragment>
									: <tr>
											<td className='text-center' colSpan={4}>{t('locationsRecords.errors.noRecords')}</td>
										</tr>
								}
							</Fragment>
					}
        </tbody>
      </table>
      <dl className='mb-5'>
        <dt className='payload'></dt>
        <dd>{t('locationsRecords.types.payload')}</dd>

        <dt className='issue'></dt>
        <dd>{t('locationsRecords.types.issue')}</dd>
      </dl>
    </div>
  );
}

LocationRecords.prototypes = {
	loading: PropTypes.bool.isRequired,
  records: PropTypes.array.isRequired
}
 
export default LocationRecords;
