import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

import {
	SET_CURRENT_USER,
	IS_GOD_USER
} from './types';

// Actions
import { get_app_config } from './organizationActions';

const cookies = new Cookies ();

// set logged user
export const user_set_current = (decoded) => {

	return {
		type: SET_CURRENT_USER,
		payload: decoded
	}

}

// log user out
export const auth_token_remove = () => dispatch => {

	// const cookies = new Cookies ();
	process.env.REACT_APP_RUNTIME === 'Production' || process.env.REACT_APP_RUNTIME === 'Test' ?
		cookies.remove (process.env.REACT_APP_JWT_KEY, {
				path: '/', 
				domain: '.percepthor.com'}) :
		cookies.remove (process.env.REACT_APP_JWT_KEY, {
				path: '/', 
				domain: '.localhost.com'});
	
	// remove auth header for future requests
	localStorage.clear();
	window.location.href = process.env.REACT_APP_LOGIN;
	auth_token_set (null);
	dispatch (user_set_current ({}));
	// dispatch (alert_set ('You have logged out!', 'success'));

};

// check for user token
export const user_token_check = (store) => {
	let jwt = cookies.get (process.env.REACT_APP_JWT_KEY);

	if (jwt) {
		let decoded = jwt_decode (jwt);
		auth_token_set (jwt);           // set auth token header auth
		store.dispatch (user_set_current ({ ...decoded, app_session: false }));

		const authURL = process.env.REACT_APP_SERVER_URL + '/api/users/auth';
		Axios.get(authURL)
		.then(() => {
			// Manage user auth (main) request 
			store.dispatch(check_user_role());

			// Check app access (second request)
			const url = process.env.REACT_APP_SERVER_URL + `/api/apps/access?app=${process.env.REACT_APP_ALIAS}`;
			return Axios.get(url);
		})
		.then(() => {
			// Manage app access (second) request
			process.env.REACT_APP_RUNTIME === 'Test' || process.env.REACT_APP_RUNTIME === 'Development' 
				&& console.log('Has permission to use ' + process.env.REACT_APP_ALIAS + ' dashboard');

			store.dispatch(get_app_config());
		})
		.catch(() => {
			// Manage errors
			window.location.href = process.env.REACT_APP_LOGIN;
		});
	}
	else {
		window.location.href = process.env.REACT_APP_LOGIN;
	}
}

// set auth token for each request
const auth_token_set = token => {

	// Apply to every request
	if (token) Axios.defaults.headers.common['Authorization'] = token;
	else delete Axios.defaults.headers.common['Authorization'];
	
};

// check for user session
export const user_session_auth = (store, token) => {
	token = `Bearer ${token}`;

	process.env.REACT_APP_RUNTIME === 'Production' || process.env.REACT_APP_RUNTIME === 'Test' ?
		cookies.set (process.env.REACT_APP_JWT_KEY, token, {
			path: '/', 
			domain: '.percepthor.com', 
			secure: true
		}) :
		cookies.set (process.env.REACT_APP_JWT_KEY, token, {
			path: '/', 
			domain: '.localhost.com'
		});
	
	auth_token_set (token); // set token to auth header
	let decoded = jwt_decode (token); // decode token to get user data
	store.dispatch(user_set_current({ ...decoded, app_session: true })); // set current user
		
	let url = process.env.REACT_APP_SERVER_URL + '/api/users/auth';
	
	Axios.get(url, {
		headers: {
			'Authorization': token
		}
	})
	.then((res) => {
		// console.log(res.data);
		store.dispatch(get_app_config());
	}).catch((err) => {
		// console.log(err);
		window.location.href = 'inspector://error';
	})
}

// Check user role
export const check_user_role = () => dispatch => {
	const url = process.env.REACT_APP_SERVER_URL + '/api/auth/check/admin';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: IS_GOD_USER,
			payload: true
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: IS_GOD_USER,
			payload: false
		});
	})
}
