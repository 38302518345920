import {
	ERRORS_CLEAR,
	TICKET_LOADING_TRUE,
	TICKET_LOADING_FALSE,
	TICKET_CREATE,
	TICKET_CREATE_ERROR,
	ALL_TICKETS_LOADING,
	ALL_TICKETS_GET,
	ALL_TICKETS_ERROR
} from '../actions/types';

const initialState = {
	ticket_loading: false,
	ticket_create_success: false,
	all_tickets_loading: false,
	all_tickets: { count: 0, tickets: [] },
	error: { isError: false, type: '', msg: '' }
}

export default function ticketReducer (state = initialState, action){
	switch (action.type){
		case ERRORS_CLEAR:
			return {
				...state,
				error: { isError: false, type: '', msg: '' }
			}
		case TICKET_LOADING_TRUE:
			return {
				...state,
				ticket_loading: true
			}
		case TICKET_LOADING_FALSE:
			return {
				...state,
				ticket_loading: false
			}
		case TICKET_CREATE:
			return {
				...state,
				ticket_create_success: action.payload
			}
		case ALL_TICKETS_LOADING:
			return {
				...state,
				all_tickets_loading: action.payload
			}
		case ALL_TICKETS_GET:
			return {
				...state,
				all_tickets: {
					count: action.payload.count,
					tickets: action.payload.tickets
				}
			}
		case TICKET_CREATE_ERROR:
		case ALL_TICKETS_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
