import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Actions
import { get_all_tickets } from '../../actions/ticketActions';

const ImageTickets = (props) => {
	const { imageId } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_tickets } = useSelector(state => state.ticket);

	const [hasTickets, setHasTickets] = useState(false);
	const [imageTickets, setImageTickets] = useState(0);

	useEffect(() => {
		const filters = { image: imageId };
		dispatch(get_all_tickets(filters));
	}, []);

	useDidMountEffect(() => {
		if (all_tickets?.count > 0) {
			const ticketsCount = all_tickets?.tickets.filter(ticket => ticket.status >= 1 && ticket.status <= 3).length;
			
			if (ticketsCount > 0) {
				setHasTickets(true);
				setImageTickets(ticketsCount);
			}
		}
	}, [all_tickets]);

	if (hasTickets) {
		return (
			<button type='button' className='btn-ticket-badge position-relative' title={t('display.numTickets')}>
				<i className='bi bi-bookmark-fill text-danger'></i>
				<span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary' style={{ fontSize: '12px' }}>
					{imageTickets}
					<span className='visually-hidden'>image tickets</span>
				</span>
			</button>
		);
	}
	else {
		return (null);
	}
}

ImageTickets.propTypes = {
	imageId: PropTypes.string.isRequired
};

export default ImageTickets;
