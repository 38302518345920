import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';

// Packages
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { download_image } from '../../actions/imageActions';

const NewImageWindow = ({ children }) => {
	const container = document.createElement('div');
	container.setAttribute('style', 'text-align:center;');
	const div = useRef(container);

	useLayoutEffect(() => {
		const external = window.open('', '_blank');
		external.document.body.appendChild(div.current);

		return () => external.close();
	}, []);

	return createPortal(children, div.current);
};

const PictureButtons = (props) => {
	const { imageInfo, imageSrc, width } = props;

	const { t } = useTranslation();

	const { image_id } = useParams();

	const dispatch = useDispatch();

	const [openNewImgWindow, setOpenNewImgWindow] = useState(false);

	return (
		<div className={clsx('col-sm-12 col-md-12 col-lg-2 col-xl-2 p-0 m-0', {
			'text-center': width < 1000,
			'text-end': width > 1000
		})}>
			<div 
				className={clsx({
					'btn-group my-4': width < 1000,
					'btn-group-vertical': width > 1000
				})}
				role='group' aria-label='Vertical button group'
			>
				<button className='btn image-btn' title={t('display.actionBtns.fullScreen')} 
					onClick={() => setOpenNewImgWindow(!openNewImgWindow)}
				>
					<i 
						className={clsx('bi bi-arrows-fullscreen', {
							'me-2': width < 1000
						})}
						style={{ fontSize: '18px' }}
					></i> {width < 1000 && t('display.actionBtns.fullScreen')}
				</button>
				<button className='btn image-btn' title={t('display.actionBtns.download')}
					onClick={() => dispatch(download_image(image_id, imageInfo))}
				>
					<i 
						className={clsx('bi bi-download', {
							'me-2': width < 1000
						})}
						style={{ fontSize: '18px' }}
					></i> {width < 1000 && t('display.actionBtns.download')}
				</button>
				<button className='btn image-btn' title={t('display.actionBtns.controls')}
					type='button' data-bs-toggle='modal' data-bs-target='#imageControls'
				>
					<i 
						className={clsx('bi bi-dpad', {
							'me-2': width < 1000
						})}
						style={{ fontSize: '18px' }}
					></i> {width < 1000 && t('display.actionBtns.controls')}
				</button>
			</div>
			{openNewImgWindow && (
				<NewImageWindow>
					<img src={imageSrc} style={{ height: '100%'}} />
				</NewImageWindow>
			)}
		</div>
	);
}

PictureButtons.propTypes = {
	imageInfo: PropTypes.object.isRequired,
	imageSrc: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired
}

export default PictureButtons;
