export const ERRORS_GET = 'ERRORS_GET';
export const ERRORS_CLEAR = 'ERRORS_CLEAR';

export const SUCCESS_GET = 'SUCCESS_GET';
export const SUCCESS_CLEAR = 'SUCCESS_CLEAR';

// Alert
export const ALERT_SET = 'ALERT_SET';
export const ALERT_REMOVE = 'ALERT_REMOVE';

// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

export const IS_GOD_USER = 'IS_GOD_USER';

// Apps
export const APP_CONFIG_GET = 'APP_CONFIG_GET';
export const APP_CONFIG_ERROR = 'APP_CONFIG_ERROR';

// Permissions
export const PERMISSIONS_GET = 'PERMISSIONS_GET';
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR';

// UI
export const UI_LOADING_TRUE = 'UI_LOADING_TRUE';
export const UI_LOADING_FALSE = 'UI_LOADING_FALSE';

// Organization
export const FILTERS_LOADING_TRUE = 'FILTERS_LOADING_TRUE';
export const FILTERS_LOADING_FALSE = 'FILTERS_LOADING_FALSE';

export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORG_INFO_GET = 'ORG_INFO_GET';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';

export const WORKSPACES_GET = 'WORKSPACES_GET';
export const WORKSPACES_ERROR = 'WORKSPACES_ERROR';

export const MODULES_GET = 'MODULES_GET';
export const MODULES_ERROR = 'MODULES_ERROR';

export const SUBMODULES_GET = 'SUBMODULES_GET';
export const SUBMODULES_ERROR = 'SUBMODULES_ERROR';

export const CYCLES_GET = 'CYCLES_GET';
export const CYCLES_ERROR = 'CYCLES_ERROR';

export const AREAS_GET = 'AREAS_GET';
export const AREAS_ERROR = 'AREAS_ERROR';

export const ROUTE_CYCLE_GET = 'ROUTE_CYCLE_GET';

export const ORG_FILTERS_SET = 'ORG_FILTERS_SET';

export const SCREENS_DATA_GET = 'SCREENS_DATA_GET';
export const SCREENS_DATA_ERROR = 'SCREENS_DATA_ERROR';

// Locations
export const LOCATION_LOADING_TRUE = 'LOCATION_LOADING_TRUE';
export const LOCATION_LOADING_FALSE = 'LOCATION_LOADING_FALSE';

export const LOCATIONS_GET = 'LOCATIONS_GET';
export const LOCATIONS_ERROR = 'LOCATIONS_ERROR';
export const LOCATIONS_RESET = 'LOCATIONS_RESET';

export const LOCATION_INFO_GET = 'LOCATION_INFO_GET';
export const LOCATION_INFO_ERROR = 'LOCATION_INFO_ERROR';

export const LOCATION_RECORDS_GET = 'LOCATION_RECORDS_GET';
export const LOCATION_RECORDS_ERROR = 'LOCATION_RECORDS_ERROR';

export const LOCATION_DATA_GET = 'LOCATION_DATA_GET';
export const LOCATION_DATA_ERROR = 'LOCATION_DATA_ERROR';

// Payloads
export const PAYLOAD_LOADING_TRUE = 'PAYLOAD_LOADING_TRUE';
export const PAYLOAD_LOADING_FALSE = 'PAYLOAD_LOADING_FALSE';
export const PAYLOAD_INFO_GET = 'PAYLOAD_INFO_GET';
export const PAYLOAD_INFO_ERROR = 'PAYLOAD_INFO_ERROR';
export const PAYLOAD_HISTORY_GET = 'PAYLOAD_HISTORY_GET';
export const PAYLOAD_HISTORY_ERROR = 'PAYLOAD_HISTORY_ERROR';

export const ALL_PAYLOADS_LOADING = 'ALL_PAYLOADS_LOADING';
export const ALL_PAYLOADS_GET = 'ALL_PAYLOADS_GET';
export const ALL_PAYLOADS_ERROR = 'ALL_PAYLOADS_ERROR';

export const SET_PAYLOAD_IMAGES = 'SET_PAYLOAD_IMAGES';

// Issues
export const ISSUE_LOADING_TRUE = 'ISSUE_LOADING_TRUE';
export const ISSUE_LOADING_FALSE = 'ISSUE_LOADING_FALSE';
export const ISSUE_INFO_GET = 'ISSUE_INFO_GET';
export const ISSUE_INFO_ERROR = 'ISSUE_INFO_ERROR';
export const ISSUE_TAGS_GET = 'ISSUE_TAGS_GET';
export const ISSUE_TAGS_ERROR = 'ISSUE_TAGS_ERROR';

export const ALL_ISSUES_GET = 'ALL_ISSUES_GET';
export const ALL_ISSUES_ERROR = 'ALL_ISSUES_ERROR';

// Images
export const ALL_IMAGES_LOADING = 'ALL_IMAGES_LOADING';
export const ALL_IMAGES_GET = 'ALL_IMAGES_GET';
export const ALL_IMAGES_ERROR = 'ALL_IMAGES_ERROR';

export const IMAGE_LOADING_TRUE = 'IMAGE_LOADING_TRUE';
export const IMAGE_LOADING_FALSE = 'IMAGE_LOADING_FALSE';
export const IMAGE_INFO_GET = 'IMAGE_INFO_GET';
export const IMAGE_INFO_ERROR = 'IMAGE_INFO_ERROR';

export const IMAGE_RESULT_LOADING = 'IMAGE_RESULT_LOADING';
export const IMAGE_RESULT_GET = 'IMAGE_RESULT_GET';
export const IMAGE_RESULT_ERROR = 'IMAGE_RESULT_ERROR';

export const IMAGE_TAGS_LOADING = 'IMAGE_TAGS_LOADING';
export const IMAGE_TAGS_GET = 'IMAGE_TAGS_GET';
export const IMAGE_TAGS_ERROR = 'IMAGE_TAGS_ERROR';

export const IMAGE_DOWNLOAD_ERROR = 'IMAGE_DOWNLOAD_ERROR';

// Results
export const RESULT_LOADING_TRUE = 'RESULT_LOADING_TRUE';
export const RESULT_LOADING_FALSE = 'RESULT_LOADING_FALSE';
export const RESULT_INFO_GET = 'RESULT_INFO_GET';
export const RESULT_INFO_ERROR = 'RESULT_INFO_ERROR';

export const RESULT_TAGS_LOADING = 'RESULT_TAGS_LOADING';
export const RESULT_TAGS_GET = 'RESULT_TAGS_GET';
export const RESULT_TAGS_ERROR = 'RESULT_TAGS_ERROR';

export const RESULT_HISTORY_LOADING = 'RESULT_HISTORY_LOADING';
export const RESULT_HISTORY_GET = 'RESULT_HISTORY_GET';
export const RESULT_HISTORY_ERROR = 'RESULT_HISTORY_ERROR';

// Projects
export const PROJECT_LOADING_TRUE = 'PROJECT_LOADING_TRUE';
export const PROJECT_LOADING_FALSE = 'PROJECT_LOADING_FALSE';
export const ALL_PROJECTS_GET = 'ALL_PROJECTS_GET';
export const ALL_PROJECTS_ERROR = 'ALL_PROJECTS_ERROR';

// Tickets
export const TICKET_LOADING_TRUE = 'TICKET_LOADING_TRUE';
export const TICKET_LOADING_FALSE = 'TICKET_LOADING_FALSE';
export const TICKET_CREATE = 'TICKET_CREATE';
export const TICKET_CREATE_ERROR = 'TICKET_CREATE_ERROR';
export const ALL_TICKETS_LOADING = 'ALL_TICKETS_LOADING';
export const ALL_TICKETS_GET = 'ALL_TICKETS_GET';
export const ALL_TICKETS_ERROR = 'ALL_TICKETS_ERROR';

// Indicators
export const INDICATORS_PROGRESS_LOADING_TRUE = 'INDICATORS_PROGRESS_LOADING_TRUE';
export const INDICATORS_PROGRESS_GET = 'INDICATORS_PROGRESS_GET';
export const INDICATORS_PROGRESS_ERROR = 'INDICATORS_PROGRESS_ERROR';

// Reports
export const ORG_REPORTS_GET = 'ORG_REPORTS_GET';
export const ORG_REPORTS_GET_ERROR = 'ORG_REPORTS_GET_ERROR';

// Fraud
export const CLEAR_ALL_FRAUDS_DATA = 'CLEAR_ALL_FRAUDS_DATA';
export const CLEAR_ALL_FRAUDS_ERRORS = 'CLEAR_ALL_FRAUDS_ERRORS';
export const CLEAR_FRAUD_SINGLE_ERROR = 'CLEAR_FRAUD_SINGLE_ERROR';

export const ALL_FRAUDS_LOADING = 'ALL_FRAUDS_LOADING';
export const ALL_FRAUDS_GET = 'ALL_FRAUDS_GET';
export const ALL_FRAUDS_ERROR = 'ALL_FRAUDS_ERROR';

export const FRAUD_SEARCH_LOADING = 'FRAUD_SEARCH_LOADING';
export const FRAUD_SEARCH_GET = 'FRAUD_SEARCH_GET';
export const FRAUD_SEARCH_ERROR = 'FRAUD_SEARCH_ERROR';
