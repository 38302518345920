import React from 'react';

// Packages
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import TablePagination from '../../utils/TablePagination';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getTicketStatusDesc } from '../../../utils/getStatusDesc';

const TicketsTable = (props) => {
	const { loading, ticketsCount, tickets, skip, setSkip, limit } = props;

	const { t } = useTranslation();

	const nPages = Math.ceil(ticketsCount / limit);

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';
	
	return (
		<div>
			<Table responsive='lg'>
				<thead className='table-head'>
					<tr>
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('payload.locationInfo.description')}</th>
						<th scope='col'>{t('payload.locationInfo.status')}</th>
						<th scope='col'>{t('payload.locationInfo.creationDate')}</th>
						<th scope='col'>{t('payloadsTable.headers.action')}</th>
					</tr>
				</thead>
				<tbody>
					{loading
						?	<tr>
								<td className='text-center' colSpan={6}>
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</td>
							</tr>
						:	tickets.length > 0
								?	tickets.map((ticket, idx) => (
										<tr key={idx}>
											<td>{ticket.name}</td>
											<td>{ticket.description}</td>
											<td>{getTicketStatusDesc(ticket.status)}</td>
											<td>{epochToLocalDate(ticket.date?.$date, localStorage.getItem('i18nextLng'))}</td>
											<td>
												<a href={`/image/${ticket?.image?.$oid}`} target='_blank' rel='noopener noreferrer'>
													{t('search.imageOption.goToImage')}
												</a>
											</td>
										</tr>
									))
								:	<tr>
										<td className='text-center' colSpan={6}>{t('ticketsTable.errors.noTickets')}</td>
									</tr>
					}
				</tbody>
			</Table>
			<TablePagination
				count={ticketsCount}
				arrayLength={tickets.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</div>
	)
}

TicketsTable.propTypes = {
	loading: PropTypes.bool.isRequired,
	ticketsCount: PropTypes.number.isRequired,
	tickets: PropTypes.array.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
}

export default TicketsTable;