import i18next from 'i18next';

// PAYLOAD_STATUS_NONE = 0
// PAYLOAD_STATUS_CREATED = 1
// PAYLOAD_STATUS_WAITING = 2
// PAYLOAD_STATUS_PROCESSING = 3
// PAYLOAD_STATUS_GOOD = 4
// PAYLOAD_STATUS_INCOMPLETE = 5
// PAYLOAD_STATUS_BAD = 6
// PAYLOAD_STATUS_FLAGGED = 7
// PAYLOAD_STATUS_IN_REVIEW = 8
// PAYLOAD_STATUS_PASSED = 9
// PAYLOAD_STATUS_REMOVED = 10
// PAYLOAD_STATUS_ERROR = 11
export const getPayloadStatusDesc = (status) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.payload.none');        break;
    case 1:   desc = i18next.t('statusDesc.payload.created');     break;
    case 2:   desc = i18next.t('statusDesc.payload.waiting');     break;
    case 3:   desc = i18next.t('statusDesc.payload.processing');  break;
    case 4:   desc = i18next.t('statusDesc.payload.good');        break;
    case 5:   desc = i18next.t('statusDesc.payload.incomplete');  break;
    case 6:   desc = i18next.t('statusDesc.payload.bad');         break;
    case 7:   desc = i18next.t('statusDesc.payload.flagged');     break;
    case 8:   desc = i18next.t('statusDesc.payload.in_review');   break;
    case 9:   desc = i18next.t('statusDesc.payload.passed');      break;
    case 10:  desc = i18next.t('statusDesc.payload.removed');     break;
    case 11:  desc = i18next.t('statusDesc.payload.error');       break;
    default:  desc = '';                                          break;
  }

  return desc;
}

// IMAGE_STATUS_NONE = 0
// IMAGE_STATUS_AVAILABLE = 1
// IMAGE_STATUS_LOST = 2
// IMAGE_STATUS_REVIEW = 3
// IMAGE_STATUS_BLOCKED = 4
// IMAGE_STATUS_MISSING = 5
// IMAGE_STATUS_REMOVED = 6
// IMAGE_STATUS_ARCHIVED = 7 si es 7 esta imagen esta siendo accesida desde el cload storage/archive
export const getImgStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.imgStatus.none');      break;
    case 1:   desc = i18next.t('statusDesc.imgStatus.available'); break;
    case 2:   desc = i18next.t('statusDesc.imgStatus.lost');      break;
    case 3:   desc = i18next.t('statusDesc.imgStatus.reviewed');  break;
    case 4:   desc = i18next.t('statusDesc.imgStatus.blocked');   break;
    case 5:   desc = i18next.t('statusDesc.imgStatus.missing');   break;
    case 6:   desc = i18next.t('statusDesc.imgStatus.removed');   break;
    case 7:   desc = i18next.t('statusDesc.imgStatus.archive');   break;
    default:  desc = '';                                          break;
  }

  return desc;
}

// IMAGE_RECON_NONE = 0
// IMAGE_RECON_WAITING = 1
// IMAGE_RECON_SUCCESS = 2
// IMAGE_RECON_ERROR = 3
// IMAGE_RECON_REVIEWED = 4
// IMAGE_RECON_UNAVAILABLE = 5
export const getImgReconStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.imgRecon.none');         break;
    case 1:   desc = i18next.t('statusDesc.imgRecon.waiting');      break;
    case 2:   desc = i18next.t('statusDesc.imgRecon.success');      break;
    case 3:   desc = i18next.t('statusDesc.imgRecon.error');        break;
    case 4:   desc = i18next.t('statusDesc.imgRecon.reviewed');     break;
    case 5:   desc = i18next.t('statusDesc.imgRecon.unavailable');  break;
    default:  desc = '';                                            break;
  }

  return desc;
}

// IMAGE_TYPE_NONE = 0
// IMAGE_TYPE_COMMON = 1
// IMAGE_TYPE_UPLOAD = 2
// IMAGE_TYPE_OUTPUT = 3
// IMAGE_TYPE_BACKUP = 4
export const getImgTypeDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.imgType.none');      break;
    case 1:   desc = i18next.t('statusDesc.imgType.common');    break;
    case 2:   desc = i18next.t('statusDesc.imgType.upload');    break;
    case 3:   desc = i18next.t('statusDesc.imgType.output');    break;
    case 4:   desc = i18next.t('statusDesc.imgType.backup');    break;
    default:  desc = '';                                        break;
  }

  return desc;
}

// IMAGE_GROUP_NONE = 0
// IMAGE_GROUP_ORGANIZATION = 1
// IMAGE_GROUP_MODULE = 2
// IMAGE_GROUP_LOCATION = 3
// IMAGE_GROUP_PAYLOAD = 4
// IMAGE_GROUP_RESULT = 5
// IMAGE_GROUP_ISSUE = 6
// IMAGE_GROUP_POST = 7
// IMAGE_GROUP_NOTIFICATION = 8
export const getImgGroupDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.imgGroup.none');            break;
    case 1:   desc = i18next.t('statusDesc.imgGroup.organization');    break;
    case 2:   desc = i18next.t('statusDesc.imgGroup.module');          break;
    case 3:   desc = i18next.t('statusDesc.imgGroup.location');        break;
    case 4:   desc = i18next.t('statusDesc.imgGroup.payload');         break;
    case 5:   desc = i18next.t('statusDesc.imgGroup.result');          break;
    case 6:   desc = i18next.t('statusDesc.imgGroup.issue');           break;
    case 7:   desc = i18next.t('statusDesc.imgGroup.post');            break;
    case 8:   desc = i18next.t('statusDesc.imgGroup.notification');    break;
    default:  desc = '';                                               break;
  }

  return desc;
}

// LOCATION_STATUS_NONE = 0
// LOCATION_STATUS_AVAILABLE = 1
// LOCATION_STATUS_DISABLED = 2
// LOCATION_STATUS_REMOVED = 3
export const getLocationStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.location.none');       break;
    case 1:   desc = i18next.t('statusDesc.location.available');  break;
    case 2:   desc = i18next.t('statusDesc.location.disabled');   break;
    case 3:   desc = i18next.t('statusDesc.location.removed');    break;
    default:  desc = '';                                          break;
  }

  return desc;
}

// LOCATION_RECORD_NONE = 0
// LOCATION_RECORD_WORK = 1
// LOCATION_RECORD_ISSUE = 2
// LOCATION_RECORD_RETRY = 3
// LOCATION_RECORD_OTHER = 4
export const getRecordStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.record.none');     break;
    case 1:   desc = i18next.t('statusDesc.record.payload');  break;
    case 2:   desc = i18next.t('statusDesc.record.issue');    break;
    case 3:   desc = i18next.t('statusDesc.record.retry');    break;
    case 4:   desc = i18next.t('statusDesc.record.other');    break;
    default:  desc = '';                                      break;
  }

  return desc;
}

// ISSUE_TYPE_NONE = 0
// ISSUE_TYPE_CLOSED = 1
// ISSUE_TYPE_CLOSED_DOWN = 2
// ISSUE_TYPE_ACCESS = 3
// ISSUE_TYPE_PROCESS = 4
// ISSUE_TYPE_PRODUCTS = 5
// ISSUE_TYPE_CUSTOM = 6
export const getIssueStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.issue.none');        break;
    case 1:   desc = i18next.t('statusDesc.issue.closed');      break;
    case 2:   desc = i18next.t('statusDesc.issue.closed_down'); break;
    case 3:   desc = i18next.t('statusDesc.issue.access');      break;
    case 4:   desc = i18next.t('statusDesc.issue.process');     break;
    case 5:   desc = i18next.t('statusDesc.issue.products');    break;
    case 6:   desc = i18next.t('statusDesc.issue.custom');      break;
    default:  desc = '';                                        break;
  }

  return desc;
}

export const getIssueDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('issue.issueType.descriptions.none');        break;
    case 1:   desc = i18next.t('issue.issueType.descriptions.closed');      break;
    case 2:   desc = i18next.t('issue.issueType.descriptions.closed_down'); break;
    case 3:   desc = i18next.t('issue.issueType.descriptions.access');      break;
    case 4:   desc = i18next.t('issue.issueType.descriptions.process');     break;
    case 5:   desc = i18next.t('issue.issueType.descriptions.products');    break;
    case 6:   desc = i18next.t('issue.issueType.descriptions.custom');      break;
    default:  desc = '';                                                    break;
  }

  return desc;
}

// CYCLE_STATUS_NONE = 0
// CYCLE_STATUS_CREATED = 1
// CYCLE_STATUS_STARTING = 2
// CYCLE_STATUS_AVAILABLE = 3
// CYCLE_STATUS_WAITING = 4
// CYCLE_STATUS_COMPLETED = 5
// CYCLE_STATUS_REMOVED = 6
export const getCycleStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.cycle.none');      break;
    case 1:   desc = i18next.t('statusDesc.cycle.created');   break;
    case 2:   desc = i18next.t('statusDesc.cycle.starting');  break;
    case 3:   desc = i18next.t('statusDesc.cycle.available'); break;
    case 4:   desc = i18next.t('statusDesc.cycle.waiting');   break;
    case 5:   desc = i18next.t('statusDesc.cycle.completed'); break;
    case 6:   desc = i18next.t('statusDesc.cycle.removed');   break;
    default:  desc = '';                                      break;
  }

  return desc;
}

// TICKET_STATUS_NONE = 0
// TICKET_STATUS_CREATED = 1
// TICKET_STATUS_WAITING = 2
// TICKET_STATUS_IN_REVIEW = 3
// TICKET_STATUS_GOOD = 4
// TICKET_STATUS_BAD = 5
// TICKET_STATUS_PASSED = 6
// TICKET_STATUS_ERROR = 7
export const getTicketStatusDesc = (status = 0) => {
  let desc = '';

  switch (parseInt(status)) {
    case 0:   desc = i18next.t('statusDesc.ticket.none');      break;
    case 1:   desc = i18next.t('statusDesc.ticket.created');   break;
    case 2:   desc = i18next.t('statusDesc.ticket.waiting');   break;
    case 3:   desc = i18next.t('statusDesc.ticket.in_review'); break;
    case 4:   desc = i18next.t('statusDesc.ticket.good');      break;
    case 5:   desc = i18next.t('statusDesc.ticket.bad');       break;
    case 6:   desc = i18next.t('statusDesc.ticket.passed');    break;
    case 7:   desc = i18next.t('statusDesc.ticket.error');     break;
    default:  desc = '';                                       break;
  }

  return desc;
}

// FRAUD_STATUS_NONE = 0
// FRAUD_STATUS_CREATED = 1
// FRAUD_STATUS_WAITING = 2
// FRAUD_STATUS_PROCESSING = 3
// FRAUD_STATUS_GOOD = 4
// FRAUD_STATUS_INCOMPLETE = 5
// FRAUD_STATUS_BAD = 6
// FRAUD_STATUS_REMOVED = 7
// FRAUD_STATUS_ERROR = 8
export const getFraudStatusDesc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                       break;
		case 1:   desc = i18next.t('statusDesc.payload.created');      break;
		case 2:   desc = i18next.t('statusDesc.payload.waiting');      break;
		case 3:   desc = i18next.t('statusDesc.payload.processing');   break;
		case 4:   desc = i18next.t('statusDesc.payload.good');         break;
		case 5:   desc = i18next.t('statusDesc.payload.incomplete');   break;
		case 6:   desc = i18next.t('statusDesc.payload.bad');          break;
		case 7:   desc = i18next.t('statusDesc.payload.removed');      break;
		case 7:   desc = i18next.t('statusDesc.payload.error');        break;
		default:  desc = '';                                           break;
	}

	return desc;
}
