import React from 'react';

// Packages
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const { t } = useTranslation();

  return ( 
    <div className='m-3'>
      <h1 className='text-center'>Display Dashboard</h1>
      <p className='text-center'>{t('landing.description')}</p>
      <div className='d-flex justify-content-center mt-5'>
        <img className='image shadow rounded' alt='Display preview' 
          src={process.env.PUBLIC_URL + `/assets/display${localStorage.getItem('theme') === 'dark' ? '-dark' : ''}.png`}
        />
      </div>
    </div>
  );
}
 
export default Landing;
