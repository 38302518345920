/**
 * Determines if the user is browsing from an Apple operating system (macOS or iOS).
 *
 * @returns {boolean} - Returns true if macOS or iOS is detected, otherwise false.
 */
export function isAppleOS() {
	// Convert both platform and userAgent strings to lowercase for consistent checks
	const platform = navigator.platform.toLowerCase();
	const userAgent = navigator.userAgent.toLowerCase();

	// Check if the platform string includes "mac," which indicates macOS
	const isMacOS = platform.includes('mac');
	
	// Use a regular expression to detect iPhone, iPad, or iPod in the userAgent
	const isIOS = /iphone|ipad|ipod/.test(userAgent);

	// Return true if it's either macOS or iOS
	return isMacOS || isIOS;
}
