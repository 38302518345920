import React, { useContext } from 'react';

// Packages
import { BrowserRouter as  Router, Routes, Route }  from 'react-router-dom';

// Components
import Navbar from './components/main/Navbar';
import Footer from './components/main/Footer';
import Landing from './components/main/Landing';
import Configuration from './components/main/Configuration';
import Authentication from './components/main/Authentication';
import NotFound from './components/main/NotFound';
import Search from './components/search/Search';
import SearchImage from './components/search/SearchImage';
import AllPayloads from './components/all_payloads/AllPayloads';
import Payload from './components/payload/Payload';
import Display from './components/display/Display';
import Issue from './components/issue/Issue';
import AllIssues from './components/all_issues/AllIsues';
import Tickets from './components/tickets/main/Tickets';
import AllFrauds from './components/fraud/AllFrauds';
import Fraud from './components/fraud/Fraud';

// Context
import { ThemeContext } from './contexts/ThemeProvider';

// Private route
import PrivateRoute from './router/PrivateRoute';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check, user_session_auth } from './actions/authActions';

// user_token_check (store);
const queryParams = new URLSearchParams(window.location.search)
const qp_token = queryParams.get('token');

if (qp_token) {
	// Use when user is redirected to dashboard from the application
	user_session_auth(store, qp_token)
}
else {
	// Use when user is already logged in from website
	user_token_check(store)
}

const App = () => {
	const [ theme ] = useContext(ThemeContext);

	return (
		<Provider store= { store }>
			<Router>
				<div className="App" id={ theme }>
					<Configuration/>

					<Navbar/>

					<Routes>
						{/* Authentication */}
						<Route exact path='/auth' element={ <Authentication /> } />

						<Route element={ <PrivateRoute /> } exact>
							<Route path='/' element={ <Landing /> } exact />

							{/* Search page */}
							<Route path='/search' element={ <Search /> } exact />
							<Route path='/search-image' element={ <SearchImage /> } exact />

							{/* All payloads */}
							<Route path='/all-payloads' element={ <AllPayloads /> } exact />

							{/* All issues */}
							<Route path='/all-issues' element={ <AllIssues /> } exact />

							{/* Payload */}
							<Route path='/locations/:location_id/payloads/:payload_id' element={ <Payload /> } exact />

							{/* Payload Fraud */}
							<Route path='/all-frauds' element={ <AllFrauds /> } exact />
							<Route path='/payloads/:payload_id/fraud' element={ <Fraud /> } exact />

							{/* Display */}
							<Route path='/locations/:location_id/payloads/:payload_id/image/:image_id' element={ <Display /> } exact />
							<Route path='/image/:image_id' element={ <Display /> } exact />

							{/* Issue */}
							<Route path='/locations/:location_id/issues/:issue_id' element={ <Issue /> } exact />

							{/* Tickets */}
							<Route path='/tickets' element={ <Tickets /> } exact />
						</Route>						

						{/* Other */}
						<Route path='*' element={ <NotFound /> } />
					</Routes>
					
					<Footer/>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
