import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Packages
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Actions
import { auth_token_remove } from '../../actions/authActions';
import { get_organization_info } from '../../actions/organizationActions';
import { get_all_projects } from '../../actions/projectsActions';

// Utils
import check_user_role from '../../utils/check_user_role';

const Navbar = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const location = useLocation();
	const { pathname } = location;

	const { user, isGodUser } = useSelector(state => state.auth);

	const { organization_info } = useSelector(state => state.organization);

	const { all_projects } = useSelector(state => state.projects);

	const [isGeppUser, setIsGeppUser] = useState(false);

	useEffect(() => {
		dispatch(get_organization_info(user.organization));
		dispatch(get_all_projects({ organization: user.organization }));

		setIsGeppUser(user.organization === '633e4f8503b34bfb1c72b8ef');
	}, []);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	return (
		<nav className='navbar navbar-expand-lg navbar-dark'>
			<div className='container-fluid'>
				<a className='navbar-brand' href='/'>
					<img className='d-inline-block align-items-center me-2' height={'30px'} alt='Percepthor Logo'
						src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'}
					/>
					Percepthor - {organization_info.name}
				</a>
				<button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarNavDropdown'>
					<ul className='navbar-nav'>
						<li className='nav-item'>
							<a className={clsx(`nav-link`, {
									'active': pathname === '/search'
								})} aria-current='page' href='/search'
							>{t('navbar.options.search')}</a>
						</li>
						<li className='nav-item'>
							<a className={clsx(`nav-link`, {
									'active': pathname === '/all-payloads'
								})} aria-current='page' href='/all-payloads'
							>{t('navbar.options.payloads')}</a>
						</li>
						<li className='nav-item'>
							<a className={clsx(`nav-link`, {
									'active': pathname === '/all-issues'
								})} aria-current='page' href='/all-issues'
							>{t('navbar.options.issues')}</a>
						</li>
						{(isGodUser || isGeppUser) && (
							<li className='nav-item'>
								<a className={clsx(`nav-link`, {
										'active': pathname === '/all-frauds'
									})} aria-current='page' href='/all-frauds'
								>{t('navbar.options.frauds')}</a>
							</li>
						)}					
						<li className='nav-item'>
							<a className={clsx(`nav-link`, {
									'active': pathname === '/search-image'
								})} aria-current='page' href='/search-image'
							>{t('navbar.options.images')}</a>
						</li>
						{all_projects.length > 0 &&
							<li className='nav-item'>
								<a className={clsx(`nav-link`, { 
									'active': pathname === '/tickets'
									})} aria-current='page' href='/tickets'
								>
									Tickets
								</a>
							</li>
						}
					</ul>
					<ul className='navbar-nav ms-auto'>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle active d-flex align-items-center' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								<i className='bi bi-person-circle fs-5 me-2'></i>{user.username}
							</a>
							<ul className={clsx('dropdown-menu dropdown-menu-end', {
									'dropdown-menu-dark': localStorage.getItem('theme') === 'dark'
								})} 
								aria-labelledby='navbarDropdown'
							>
								<li>
									<a className='dropdown-item' href={process.env.REACT_APP_LOGIN + '/account'}>
										<i className='bi bi bi-person-circle me-2'></i>{t('navbar.menu.account')}
									</a>
								</li>
								<li>
									<a className='dropdown-item' href={process.env.REACT_APP_LOGIN + '/links'}>
										<i className='bi bi-grid me-2'></i>{t('navbar.menu.return')}
									</a>
								</li>
								<li>
									<a className='dropdown-item' data-bs-toggle='modal' data-bs-target='#settings'>
										<i className='bi bi-gear-wide-connected me-2'></i>{t('navbar.menu.configuration')}
									</a>
								</li>
								<li><hr className='dropdown-divider'/></li>
								<li>
									<a className='dropdown-item' onClick={(e) => handleLogout(e)}>
										<i className='bi bi-box-arrow-right me-2'></i>{t('navbar.menu.logOut')}
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
