import React, { useEffect, useState } from 'react';

// Pcakages
import { Image, StyleSheet } from '@react-pdf/renderer';
import Axios from 'axios';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';

// Create styles
const styles = StyleSheet.create({
	// PDF image styles
	pdfImage: {
		width: '47.55px',
		marginTop: 3,
		marginBottom: 5
	}
});

const PDFImage = (props) => {
	const { imageId } = props;
	
	const [imgSrc, setImgSrc] = useState(null);

	useEffect(() => {
		let isMounted = true;

		if (imageId !== '' && imageId !== 'None') {
			Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/images/${imageId}/data`,{
				responseType: 'arraybuffer'
			})
			.then((res) => {
				let data = `data:${res.headers['content-type']};base64, ${Buffer.from(res.data, 'binary').toString('base64')}`;
	
				if (isMounted) setImgSrc(data);
			}).catch((err) => {
				console.log(err)
			});
		}
		else {
			console.log('No image')
		}

    return () => isMounted = false;
	}, [imageId]);

	return (
		<Image src={imgSrc} style={styles.pdfImage} />
	);
}

PDFImage.prototypes = {
	imageId: PropTypes.string.isRequired
}

export default PDFImage;
