import Axios from 'axios';

import {
	ORG_REPORTS_GET,
	ORG_REPORTS_GET_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const get_org_reports = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/reports/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ORG_REPORTS_GET,
			payload: res.data
		});
	}).catch((err) => {
		dispatch({
			type: ORG_REPORTS_GET_ERROR,
			payload: { type: 'organization_reports', msg: Object.values(err.response.data)[0] }
		});
	})
}

