import Axios from 'axios';
import i18next from 'i18next';

import { getCycleStatusDesc } from '../utils/getStatusDesc';
import { epochToLocalDate } from '../utils/dateConverter';

import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR,
	SUBMODULES_GET,
	SUBMODULES_ERROR,
	CYCLES_GET,
	CYCLES_ERROR,
	AREAS_GET,
	AREAS_ERROR,
	ROUTE_CYCLE_GET,
	ORG_FILTERS_SET,
	SCREENS_DATA_GET,
	SCREENS_DATA_ERROR,
	APP_CONFIG_GET,
	APP_CONFIG_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const get_organizations = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORGANIZATIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORGANIZATIONS_ERROR,
			payload: {type: 'organizations', msg: err.message}
		})
	})
}

export const get_organization_info = (org_id) => dispatch => {
	// console.log(org_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/organizations/${org_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORG_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORG_INFO_ERROR,
			payload: {type: 'org_info', msg: err.message}
		})
	})
}

export const get_workspaces = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations/workspaces?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKSPACES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKSPACES_ERROR,
			payload: { type: 'wokspaces', msg: err.message }
		})
	})
}

export const get_modules = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/modules?module_type=2&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODULES_ERROR,
			payload: { type: 'modules', msg: err.message }
		})
	})
}

export const get_submodules = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/submodules?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SUBMODULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SUBMODULES_ERROR,
			payload: { type: 'submodules', msg: err.message }
		})
	})
}

const updateCyclesName = (cycles) => {
	cycles.sort((prev, next) => (prev.start.$date < next.start.$date ? 1 : -1));

	cycles.forEach((cycle) => {
		cycle.name = `${cycle.name} - ${i18next.t('search.filters.start')} ${epochToLocalDate(cycle.start.$date, localStorage.getItem('i18nextLng'))} - ${i18next.t('search.filters.end')} ${epochToLocalDate(cycle.end.$date, localStorage.getItem('i18nextLng'))} - ${getCycleStatusDesc(cycle.status)}`;
	})

	return cycles;
}

export const get_cycles = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/cycles/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: CYCLES_GET,
			payload: updateCyclesName(res.data)
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CYCLES_GET,
			payload: []
		})
		dispatch({
			type: CYCLES_ERROR,
			payload: { type: 'cycles', msg: err.message }
		})
	})
}

const getAreaType = (areaFilters) => {
	let area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area = 'depot';
	}

	return area;
}

const createAreaFilters = (workspace, cycle, areaFilters) => {
	let newAreaFilters = {};

	const isEmpty = Object.values(areaFilters).every(f => f === '');

	if (isEmpty) { // if there's no area filters
		newAreaFilters = {
			workspace: workspace,
			cycle: cycle
		}
	}
	else {
		let area = getAreaType(areaFilters);

		newAreaFilters = {
			cycle: cycle,
			[area]: areaFilters[area]
		}
	}

	return newAreaFilters;
}

export const get_areas = (workspace, cycle, areaFilters = {}) => dispatch => {
	let filters = createAreaFilters(workspace, cycle, areaFilters);
	
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: AREAS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: AREAS_ERROR,
			payload: { type: 'areas', msg: err.message }
		})
	})
}

export const get_route_cycle = (route_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/cycle`;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ROUTE_CYCLE_GET,
			payload: res.data
		})
	}).catch((err) => {
		dispatch({
			type: ROUTE_CYCLE_GET,
			payload: {}
		})
	})
}

export const set_org_filters = (orgFilters) => dispatch => {
	// console.log(orgFilters)
	dispatch({
		type: ORG_FILTERS_SET,
		payload: orgFilters
	})
}

export const get_screens_data = (submodule_id) => dispatch => {
	// console.log(submodule_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/submodules/${submodule_id}/screens/data`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SCREENS_DATA_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SCREENS_DATA_ERROR,
			payload: { type: 'screen', msg: err.message }
		})
	})
}

export const get_app_config = () => dispatch => {
	const url = process.env.REACT_APP_SERVER_URL + `/api/apps/screens/data?app=${process.env.REACT_APP_ALIAS}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: APP_CONFIG_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: APP_CONFIG_ERROR,
			payload: { type: 'app_config', msg: err.message }
		})
	})
}
