import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { useTranslation } from 'react-i18next';

// Components
import Loading from '../utils/Loading';
import LocationInfo from '../utils/LocationInfo';
import PayloadData from '../utils/PayloadData';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Actions
import { get_issue_info, get_issue_tags } from '../../actions/issueActions';

// Utils
import { getIssueStatusDesc, getIssueDesc } from '../../utils/getStatusDesc';
import isEmpty from '../../utils/isEmpty';

const Issue = () => {
  const { t } = useTranslation();

  const { issue_id } = useParams();

  const dispatch = useDispatch();

  const { issue_loading, issue_info, issue_tags } = useSelector(state => state.issues);

  const [issueTags, setIssueTags] = useState([]);

  useEffect(() => {
    dispatch(get_issue_info(issue_id));
  }, []);

  useDidMountEffect(() => {
    if (!isEmpty(issue_info)) {
      if (issue_info.issue_type === 6) {
        dispatch(get_issue_tags(localStorage.getItem('organization')));
      }
    }
  }, [issue_info]);

  useDidMountEffect(() => {
    if (!isEmpty(issue_tags)) {
      if (issue_tags.count > 0) {
        issue_tags.tags.forEach((issue_tag) => {
          if ((issue_info.issue_tags & issue_tag.value) === issue_tag.value) {
            setIssueTags(prevArray => [...prevArray, issue_tag.name]);
          }
        });
      }
    }
  }, [issue_tags]);

  if (issue_loading) {
    return (
      <Loading msg={t('payload.loading')} />
    );
  }
  else {
    return ( 
      <div id={localStorage.getItem('theme')}>
      <div className='container container-padding' style={{ marginBottom: '6rem' }}> 
        <div className='my-3'>
          <p className='text-center fs-3'>{t('payload.title')}</p>
          <hr/>

					{/* Location Info */}
					<LocationInfo locationInfo={issue_info?.location} />

					{/* Issue Info */}
					<PayloadData
						width={window.innerWidth}
						isPayload={false}
						info={issue_info}
					/>

					{/* Issue Type & Description (Tags) */}
          <p className='text-center fs-4 my-0'><i className='bi bi-clipboard2-x ms-1 me-2'></i>{t('issue.issueType.title')}</p>
          <form className='row gy-3 mb-4 my-0'>
            <p className='form-label text-center fs-5'><b>{getIssueStatusDesc(issue_info.issue_type)}</b></p>
            <p className='form-label text-center'>{getIssueDesc(issue_info.issue_type)}</p>
            {issue_info.issue_type === 6
              ? <p className='form-label text-center fs-6 mb-0'>
                  {t('issue.issueType.issue_tag')}
                  <b>{issueTags.map((issueTag, idx) => (
                    ' ' + issueTag + (idx === (issueTags.length - 1) ? '' : ', ')
                  ))}</b>
                </p>
              : null
            }
          </form>
          <hr/>
        </div>
      </div>
      </div>
    );
  }
}
 
export default Issue;
