const getSubmoduleInfoByName = (screens, key, value, photoTypes, parent) => {
  let retval = null;
  
  for (let s_idx = 0; s_idx < screens.length; s_idx++) {
    const screen = screens[s_idx];

    for (let cpm_idx = 0; cpm_idx < screen.components.length; cpm_idx++) {
      const component = screen.components[cpm_idx];
      
      if (photoTypes) {
        if (component.field_name === parent || component.field_name === key) {
          if (component.photo_types !== undefined) {
            retval = component.photo_types.options[value];
            break;
          }
        }
      }
      else if (parent !== null) {
        if (component.field_name === parent) {
          for (let c_idx = 0; c_idx < component.children.length; c_idx++) {
            const child = component.children[c_idx];
            
            if (child.field_name === key) {
              if (child.options.length > 0) {
                let idx_value = parseInt(value);

                if (idx_value !== null) {
                  retval = child.options[idx_value];
                }
              }
              break;
            }
          }
          break;
        }
      }
      else {
        if (component.field_name === key) {
          if (component.options.length > 0) {
            let idx_value = parseInt(value);

            if (idx_value !== null) {
              retval = component.options[idx_value];
            }
          }
          break;
        }
      }
    }
  };

  return retval !== null ? retval : value;
}

export default getSubmoduleInfoByName;
