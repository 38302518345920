import {
	INDICATORS_PROGRESS_LOADING_TRUE,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR
} from '../actions/types';

const initialState = {
	indicators_progress_loading: true,
	indicators_progress: {},
	indicators_errors: {}
}

export default function indicatorsReducer(state = initialState, action) {
	switch(action.type) {
		case INDICATORS_PROGRESS_LOADING_TRUE:
			return {
				...state,
				indicators_progress_loading: true
			}
		case INDICATORS_PROGRESS_GET:
			delete state.indicators_errors.indicators_progress
			return {
				...state,
				indicators_progress_loading: false,
				indicators_progress: action.payload
			}
		case INDICATORS_PROGRESS_ERROR:
			return {
				...state,
				indicators_progress_loading: false,
				indicators_errors: {
					...state.indicators_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
