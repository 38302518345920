import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Components
import Loading from '../utils/Loading';
import RenderImage from '../utils/RenderImage';
import StatusBadge from '../utils/StatusBadge';
import ConfirmDialog from '../utils/ConfirmDialog';
import ToastAlert from '../utils/ToastAlert';
import Alert from '../utils/Alert';

// Actions
import { fraud_process, search_payload_fraud } from '../../actions/fraudActions';
import { get_payload_info } from '../../actions/payloadActions';

// Utils
import { epochToLocalDate } from '../../utils/dateConverter';
import { getFraudStatusDesc } from '../../utils/getStatusDesc';

const Fraud = () => {
	const { t } = useTranslation();

	const { payload_id } = useParams();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { search_payload_fraud_loading, payload_fraud, frauds_errors } = useSelector(state => state.frauds);

	const { payload_loading, payload_info } = useSelector(state => state.payload);

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [confirmDialogTexts, setConfirmDialogTexts] = useState({ title: '', msg: '' });

	const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		dispatch(get_payload_info(payload_id));
		dispatch(search_payload_fraud({ payload: payload_id }));

		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	const getPictureDate = (pictureFilename) => {
		if (pictureFilename) {
			const filename = pictureFilename.slice(0, -4);
			const data = filename.split('_');
			return epochToLocalDate(data[0]);
		}
	}

	const getPictureCoordinates = (pictureFilename) => {
		if (pictureFilename) {
			const filename = pictureFilename.slice(0, -4);
			const data = filename.split('_');
			return `${data[1]}, ${data[2]}`;
		}
	}

	const handleConfirmDialog = () => {
		setShowConfirmDialog(true);
		setConfirmDialogTexts({
			title: t('fraud.confirm.title'),
			msg: t('fraud.confirm.msg')
		});
	}

	const hideConfirmDialog = () => {
		setShowConfirmDialog(false);
		setTimeout(() => {
			setConfirmDialogTexts({ title: '', msg: '' });
		}, 1000);
	}

	if (search_payload_fraud_loading) {
		return (
			<Loading msg={t('payload.loading')} />
		);
	}
	else if (frauds_errors.hasOwnProperty('search_payload_fraud')) {
		return (
			<div id={localStorage.getItem('theme')}>
				<div className='container-fluid container-padding my-3'>
					<div className='container'>
						<Row style={{ display: 'flex', alignItems: 'center', height: '80vh' }}>
							<Col xs={12} md={6} lg={8}>
								<Image src={process.env.PUBLIC_URL + '/assets/search.svg'} alt='No fraud' fluid />
							</Col>
							<Col xs={12} md={6} lg={4}>
								<h1 className='text-center'>{t('fraud.errorPageTitle')}</h1>
								<button
									className='btn simple-btn w-100 mt-3'
									disabled={payload_loading}
									onClick={() => window.location.href = `/locations/${payload_info?.location?._id?.$oid}/payloads/${payload_id}`}
								>
									{t('display.returnPayload')}
								</button>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
	else {
		return (
			<div id={localStorage.getItem('theme')}>
				<nav className='navbar navbar-expand-lg'>
					<div className='container-fluid'>
						<a href={`/locations/${payload_info?.location?._id?.$oid}/payloads/${payload_id}`} className='nav-link text-white button-back'>
							<i className='bi bi-arrow-left-square'></i> {t('display.returnPayload')}
						</a>
					</div>
				</nav>

				<ToastAlert />

				<ConfirmDialog
					show={showConfirmDialog}
					title={confirmDialogTexts.title}
					msg={confirmDialogTexts.msg}
					onHide={hideConfirmDialog}
					confirmFunction={() => dispatch(fraud_process({ fraud: payload_fraud?._id?.$oid, user: user.user }, payload_id))}
				/>

				<div className='container-fluid container-padding mt-3'>
					<div className='container'>
						<Row>
							<Col lg={4} md={12} sm={12}></Col>
							<Col lg={4} md={12} sm={12}>
								<p className='text-center fs-3'>{t('fraud.title')}</p>
							</Col>
							<Col className={width < 993 ? 'text-center' : 'text-end'} lg={4} md={12} sm={12}>
								<Button className='btn simple-btn' onClick={() => window.print()} id='print-btn'>
									<i className='bi bi-printer-fill ms-1 me-2'></i>{t('fraud.print')}
								</Button>
							</Col>
						</Row>
						
						{(payload_fraud?.status === 4 || payload_fraud?.status === 6) && payload_fraud?.review !== null && (
							<Alert type='info' msg={<>{t('fraud.reviewMsg')} <a href={`/locations/${payload_fraud?.original?.location?._id?.$oid}/payloads/${payload_fraud?.review?.payload?.$oid}`} className='alert-link'>{t('payloadsTable.actions.goToPayload')}</a></>} />
						)}
						
						{/* Fraud Data */}
						<Card className='shadow mb-5 bg-body rounded card-border'>
							<Card.Body>
								<Card.Title className='text-center' as='h5'>{t('payload.payloadContent.tableHeaders.info')}</Card.Title>
								<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {payload_fraud?._id?.$oid}</Card.Subtitle>
								<hr/>						
								<Container className='p-0'>
									<Row>
										<Col className='text-center' lg={6} md={12} sm={12}>
											<strong>{t('locationsTable.headers.status')}:</strong> <StatusBadge status={getFraudStatusDesc(payload_fraud?.status)} />
										</Col>
										<Col className='text-center' lg={6} md={12} sm={12}>
											<strong>{t('payload.payloadInfo.creationDate')}:</strong> {epochToLocalDate(payload_fraud?.date?.$date)}
										</Col>
									</Row>
									<hr/>
									<Row>
										<Col className='text-center' lg={6} md={12} sm={12}>
											<strong>{t('fraud.image')}:</strong> {(payload_fraud?.data?.image * 100).toFixed(2)}%
										</Col>
										<Col className='text-center' lg={6} md={12} sm={12}>
											<strong>{t('fraud.products')}:</strong> {(payload_fraud?.data?.products * 100).toFixed(2)}%
										</Col>
									</Row>
								</Container>
							</Card.Body>
						</Card>

						<div
							className='sticky-print-fix'
							style={{
								textAlign: 'center',
								position: 'sticky',
								top: 0,
								backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212529' : '#e9e9eb',
								zIndex: 1000,
								paddingTop: '1.5rem'
							}}
						>
							<Row>
								<Col></Col>
								<Col>
									<h3>
										{payload_id === payload_fraud?.original?.payload?._id?.$oid
											? <span className='badge simple-btn'>Original</span>
											: 'Original'
										}
									</h3>
								</Col>
								<Col>
									<h3>
										{payload_id === payload_fraud?.copy?.payload?._id?.$oid
											? <span className='badge simple-btn'>{t('fraud.false')}</span>
											: t('fraud.false')
										}
									</h3>
								</Col>
							</Row>
							<hr />
						</div>

						<h4 style={{ marginTop: '1rem' }}>{t('payload.locationInfo.title')}</h4>
						<hr />
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-upc me-2'></i> {t('payload.locationInfo.code')}</p></Col>
							<Col><p>{payload_fraud?.original?.location?.code}</p></Col>
							<Col><p>{payload_fraud?.copy?.location?.code}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-shop me-2'></i> {t('payload.locationInfo.name')}</p></Col>
							<Col><p>{payload_fraud?.original?.location?.name}</p></Col>
							<Col><p>{payload_fraud?.copy?.location?.name}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-geo-fill me-2'></i> {t('fraud.data.coordinates')}</p></Col>
							<Col><p>{payload_fraud?.original?.location?.lat}, {payload_fraud?.original?.location?.lon}</p></Col>
							<Col><p>{payload_fraud?.copy?.location?.lat}, {payload_fraud?.copy?.location?.lon}</p></Col>
						</Row>
						
						<h4 style={{ marginTop: '5rem' }}>{t('display.imgInfo')}</h4>
						<hr />
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-file-image me-2'></i> {t('fraud.data.image')}</p></Col>
							<Col>
								<RenderImage
									imageId={payload_fraud?.original?.image?._id?.$oid}
									defaultSrc={process.env.PUBLIC_URL + '/assets/ref-image.svg'}
									alt='Reference image'
									refImage={true}
								/>
							</Col>
							<Col>
								<RenderImage
									imageId={payload_fraud?.copy?.image?._id?.$oid}
									defaultSrc={process.env.PUBLIC_URL + '/assets/ref-image.svg'}
									alt='Reference image'
									refImage={true}
								/>
							</Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-clock me-2'></i> {t('fraud.data.imageDate')}</p></Col>
							<Col><p>{getPictureDate(payload_fraud?.original?.image?.filename)}</p></Col>
							<Col><p>{getPictureDate(payload_fraud?.copy?.image?.filename)}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-geo-fill me-2'></i> {t('fraud.data.coordinates')}</p></Col>
							<Col><p>{getPictureCoordinates(payload_fraud?.original?.image?.filename)}</p></Col>
							<Col><p>{getPictureCoordinates(payload_fraud?.copy?.image?.filename)}</p></Col>
						</Row>

						<h4 style={{ marginTop: '5rem' }}>{t('payload.payloadInfo.title')}</h4>
						<hr />
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-person-fill me-2'></i> {t('payload.payloadInfo.user')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.user?.username}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.user?.username}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-arrow-repeat me-2'></i> {t('payload.payloadInfo.cycle')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.cycle?.name}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.cycle?.name}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-globe2 me-2'></i> {t('payload.payloadInfo.region')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.region?.name}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.region?.name}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-globe2 me-2'></i> {t('payload.payloadInfo.zone')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.zone?.name}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.zone?.name}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-globe2 me-2'></i> {t('payload.payloadInfo.depot')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.depot?.description}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.depot?.description}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-globe2 me-2'></i> {t('payload.payloadInfo.group')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.group?.name}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.group?.name}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-globe2 me-2'></i> {t('payload.payloadInfo.route')}</p></Col>
							<Col><p>{payload_fraud?.original?.payload?.route?.code}</p></Col>
							<Col><p>{payload_fraud?.copy?.payload?.route?.code}</p></Col>
						</Row>
						<Row className='text-center'>
							<Col><p className='fw-bold'><i className='bi bi-clock me-2'></i> {t('payload.payloadInfo.creationDate')}</p></Col>
							<Col><p>{epochToLocalDate(payload_fraud?.original?.payload?.date?.$date)}</p></Col>
							<Col><p>{epochToLocalDate(payload_fraud?.copy?.payload?.date?.$date)}</p></Col>
						</Row>

						{payload_fraud?.status === 1 &&
							<div className='d-grid gap-2 mt-5 mb-3'>
								<button type='button' className='btn simple-btn' onClick={handleConfirmDialog}>
									{t('fraud.validationBtn')}
								</button>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default Fraud;
