import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { useTranslation } from 'react-i18next';
import { Alert, OverlayTrigger, Popover } from 'react-bootstrap';

// Components
import SelectButton from '../../utils/SelectButton';
import TicketsTable from './TicketsTable';

// Actions
import { get_organizations } from '../../../actions/organizationActions';
import { get_all_projects } from '../../../actions/projectsActions';
import { get_all_tickets } from '../../../actions/ticketActions';

const Tickets = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organizations } = useSelector(state => state.organization);

	const { all_projects } = useSelector(state => state.projects);

	const { all_tickets_loading, all_tickets } = useSelector(state => state.ticket);

	const [alert, setAlert] = useState(false);
	const [organizationId, setOrganizationId] = useState('');
	const [projectId, setProjectId] = useState('');
	const [displayTickets, setDisplayTickets] = useState(false);

	const [skip, setSkip] = useState(0);
  const [limit] = useState(10);

	const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		dispatch(get_organizations());

		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, [dispatch]);

	useEffect(() => {
		let lsOrganization = localStorage.getItem('organization');

		if (organizations.length <= 1) {
			setOrganizationId(user.organization);
		}
		else {
			setOrganizationId(lsOrganization === null ? '' : lsOrganization);
		}
	}, [organizations]);

	useEffect(() => {
		if (organizationId !== '') {
			const filters = { organization: organizationId };
			dispatch(get_all_projects(filters));
		}
	}, [organizationId]);

	const searchTickets = (e) => {
		e.preventDefault();

		if (organizationId === '' || projectId === '') {
			setAlert(true);
			window.scroll({ top: 0, behavior: 'smooth' });
			return;
		}
		setAlert(false);

		const filters = { project: projectId, skip, limit };
		dispatch(get_all_tickets(filters));

		setDisplayTickets(true);
	}

	useEffect(() => {
		const filters = { project: projectId, skip, limit };
		dispatch(get_all_tickets(filters));
  }, [skip]);

	return (
		<div id={localStorage.getItem('theme')}>
			<div className='container container-padding my-3'>
				<h2 className='text-center'>Tickets</h2>

				<form onSubmit={searchTickets}>
					<Alert variant='danger' show={alert}>{t('search.errorAlertMsg')}</Alert>

					{organizations.length > 1 &&
						<div className='mb-3'>
							<label className='form-label'>
								<i className='bi bi-house ms-1 me-2'></i> {t('allPayloads.filters.organization')}
							</label>
							<SelectButton
								options={organizations !== undefined ? organizations : ['No organizations']}
								name='organization'
								value={organizationId === '' ? null : organizationId}
								onChange={(e) => setOrganizationId(e.value)}
							/>
							<div id='organizationHelp' className='form-text'>{t('allPayloads.filters.orgLegend')}</div>
						</div>
					}

					<div className='mb-3'>
						<label className='form-label'>
							<i className='bi bi-wrench-adjustable ms-1 me-2'></i> {t('ticketModal.project')}
							<OverlayTrigger
								trigger='hover'
								placement={width < 993 ? 'bottom' : 'right'}
								overlay={
									<Popover id='indicators-info'>
										<Popover.Header as='h3'>{t('tickets.help.title')}</Popover.Header>
										<Popover.Body>
											<p className='my-0'>{t('tickets.help.msg')}</p>
										</Popover.Body>
									</Popover>
								}
							>
								<i className='bi bi-question help-btn circle pulse'></i>
							</OverlayTrigger>
						</label>
						<SelectButton
							options={all_projects !== undefined ? all_projects : ['No projects']}
							name='project'
							value={projectId === '' ? null : projectId}
							onChange={(e) => setProjectId(e.value)}
						/>
						<div id='workspaceHelp' className='form-text'>{t('tickets.filters.projectLegend')}</div>
					</div>

					<div className='d-grid gap-2 col-2 mx-auto text-center mb-5'>
						<button className='btn submit-btn' type='submit'>{t('search.searchBtn')}</button>
					</div>
				</form>
				
				{displayTickets &&
					<TicketsTable
						loading={all_tickets_loading}
						ticketsCount={all_tickets.count}
						tickets={all_tickets.tickets}
						skip={skip}
						setSkip={setSkip}
						limit={limit}
					/>
				}
			</div>
		</div>
	)
}

export default Tickets;
