import {
	RESULT_LOADING_TRUE,
	RESULT_LOADING_FALSE,
	RESULT_INFO_GET,
	RESULT_INFO_ERROR,
	RESULT_TAGS_LOADING,
	RESULT_TAGS_GET,
	RESULT_TAGS_ERROR,
	RESULT_HISTORY_LOADING,
	RESULT_HISTORY_GET,
	RESULT_HISTORY_ERROR
} from '../actions/types';

const initialState = {
	result_loading: false,
	result_info: {},
	result_tags_loading: false,
	result_tags: [],
	result_history_loading: false,
	result_history: [],
	error: { isError: false, type: '', msg: '' }
}

export default function resultReducer(state = initialState, action){
	switch(action.type){
		case RESULT_LOADING_TRUE:
			return {
				...state,
				result_loading: true
			}
		case RESULT_LOADING_FALSE:
			return {
				...state,
				result_loading: false
			}
		case RESULT_INFO_GET:
			return {
				...state,
				result_info: action.payload
			}
		case RESULT_TAGS_LOADING:
			return {
				...state,
				result_tags_loading: true
			}
		case RESULT_TAGS_GET:
			return {
				...state,
				result_tags_loading: false,
				result_tags: action.payload
			}
		case RESULT_HISTORY_LOADING:
			return {
				...state,
				result_history_loading: action.payload
			}
		case RESULT_HISTORY_GET:
			return {
				...state,
				result_history: action.payload
			}
		case RESULT_INFO_ERROR:
		case RESULT_TAGS_ERROR:
		case RESULT_HISTORY_ERROR:
			return {
				...state,
				result_tags_loading: false,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
