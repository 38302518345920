
/**
 * Class to create a Tag
 */
class Tag {
	/**
	 * @param {Number} id Id
	 * @param {String} className Class name
	 * @param {Array<Number>} arrayRGBColor Array RGB
	 * @param {Number} quantity Quantity
	 * @param {Boolean} isVisible 
	 * @param {Number} score Score
	 * @param {Number} price Price
	*/
	constructor(id, className, arrayRGBColor) {
		this.id = id;
		this.className = className;
		this.arrayRGBColor = arrayRGBColor;
		this.quantity = 0;
		this.isVisible = true;
		this.score = 0;
		this.price = 0;
	}
}

export { Tag };
