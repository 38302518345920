import React from 'react';

// Packages
import { Col, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RenderImage from './RenderImage';

// Utils
import { getLocationStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const LocationInfo = (props) => {
	const { locationInfo } = props;

	const { t } = useTranslation();
	
	return (
		<Container className='p-0' fluid>
			<p className='text-center fs-4 my-0'><i className='bi bi-shop ms-1 me-2'></i>{t('payload.locationInfo.title')}</p>
			<p className='form-label text-center my-0'><b>Id: </b>{locationInfo?._id?.$oid}</p>
			<Form className='row gy-3 mb-4 my-0'>
				<Col lg={4} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.code')}</Form.Label>
						<Form.Control defaultValue={locationInfo?.code} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.name')}</Form.Label>
						<Form.Control defaultValue={locationInfo?.name} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.description')}</Form.Label>
						<Form.Control defaultValue={locationInfo?.description} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.status')}</Form.Label>
						<Form.Control defaultValue={getLocationStatusDesc(locationInfo?.status)} disabled />
					</Form.Group>
				</Col>
				<Col lg={2} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.lat')}</Form.Label>
						<Form.Control defaultValue={locationInfo?.lat} disabled />
					</Form.Group>
				</Col>
				<Col lg={2} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.lng')}</Form.Label>
						<Form.Control defaultValue={locationInfo?.lon} disabled />
					</Form.Group>
				</Col>
				<Col lg={4} md={12} sm={12} xs={12}>
					<Form.Group>
						<Form.Label>{t('payload.locationInfo.creationDate')}</Form.Label>
						<Form.Control defaultValue={epochToLocalDate(locationInfo?.date?.$date, localStorage.getItem('i18nextLng'))} disabled />
					</Form.Group>
				</Col>
			</Form>
			<p className='form-label text-center my-0'>{t('payload.locationInfo.referenceImg')}</p>
			<RenderImage
				imageId={locationInfo?.reference ? locationInfo?.reference?.$oid : ''}
				defaultSrc={process.env.PUBLIC_URL + '/assets/ref-image.svg'}
				alt='Reference image'
				refImage={true}
			/>
			<hr/>
		</Container>
	);
}

LocationInfo.propTypes = {
	locationInfo: PropTypes.object
};

export default LocationInfo;
