import isEmpty from '../utils/isEmpty';

import {
	SET_CURRENT_USER,
	USER_INIT,
	USER_INIT_CORRECT,
	USER_INIT_ERROR,
	IS_GOD_USER
} from '../actions/types';

let initialState = {
	isAuthenticated: false,
	user: {},
	first: false,
	isGodUser: false,
	error: { title: '', msg: '', isError: false }
};

export default function reducer (state = initialState, action) {
	switch (action.type) {
		case USER_INIT_ERROR:
			return {
				...state,
				error: {
					title: Object.keys(action.payload)[0],
					msg: Object.values(action.payload)[0],
					isError: true,
				}
			}
		case SET_CURRENT_USER: 
			return {
				...state,
				isAuthenticated: !isEmpty (action.payload),
				user: action.payload,
				error: initialState.error,
			}
		case USER_INIT:
			return {
				...state,
				first: true,
				error: initialState.error,
			}
		case USER_INIT_CORRECT:
			return {
				...state,
				first: false,
				error: initialState.error
			}
		case IS_GOD_USER:
			return {
				...state,
				isGodUser: action.payload
			}

		default: return state;
	}

}
