import React, { Fragment, useEffect, useState } from 'react';

// Packages
import { Table } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import TablePagination from '../utils/TablePagination';

// Utils
import { getPayloadStatusDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const PayloadsTable = (props) => {
	const { loading, payloadsCount, payloads, skip, setSkip, limit } = props;

	const { t } = useTranslation();

	const nPages = Math.ceil(payloadsCount / limit);

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

	const [width, setWidth]   = useState(window.innerWidth);

	const updateDimensions = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	return (
		<div style={{ marginTop: '3rem', minHeight: '510px' }}>
			<Table responsive='lg'>
				<thead className='table-head'>
					<tr>
						{width > 800 && <th scope='col'>Id</th>}
						<th scope='col'>{t('payload.locationInfo.name')}</th>
						<th scope='col'>{t('payloadsTable.headers.code')}</th>
						<th scope='col'>{t('payloadsTable.headers.date')}</th>
						<th scope='col'>{t('payloadsTable.headers.status')}</th>
						<th scope='col'>{t('payloadsTable.headers.action')}</th>
					</tr>
				</thead>
				<tbody>
					{loading
						?	<tr>
								<td className='text-center' colSpan={6}>
									{/* <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span> */}
									<ClipLoader color={spinnerColor} size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': '3px' }}/>
								</td>
							</tr>
						: <Fragment>
								{payloads.length > 0
									? <Fragment>
											{payloads.map((r, idx) => (
												r?.location &&
													<tr key={idx}>
														{width > 800 && <td className='text-break'>{r?._id?.$oid}</td>}
														<td>{r?.location?.name || t('payload.payloadInfo.error')}</td>
														<td>{r?.location?.code || t('payloadsTable.errors.noCode')}</td>
														<td>{epochToLocalDate(r?.date?.$date, localStorage.getItem('i18nextLng'))}</td>
														<td>{getPayloadStatusDesc(r?.status)}</td>
														<td>
															<a href={`/locations/${r?.location?._id?.$oid}/payloads/${r?._id?.$oid}`} target='_blank' rel='noopener noreferrer'>
																{t('payloadsTable.actions.goToPayload')}
															</a>
														</td>
													</tr>
											))}
										</Fragment>
									: <tr>
											<td className='text-center' colSpan={6}>{t('payloadsTable.errors.noPayloads')}</td>
										</tr>
								}
							</Fragment>
					}
				</tbody>
			</Table>
			<TablePagination
				count={payloadsCount}
				arrayLength={payloads.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</div>
	);
}

PayloadsTable.prototypes = {
	loading: PropTypes.bool.isRequired,
	payloadsCount: PropTypes.number.isRequired,
	payloads: PropTypes.array.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	searchPayloads: PropTypes.func.isRequired
}

export default PayloadsTable;
