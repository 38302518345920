import {
	PERMISSIONS_GET,
	PERMISSIONS_ERROR
} from '../actions/types';

const initialState = {
	user_permissions: [],
	error: { isError: false, type: '', msg: '' }
}

export default function permissionsReducer(state = initialState, action){
	switch(action.type){
		case PERMISSIONS_GET:
			return {
				...state,
				user_permissions: action.payload
			}
		case PERMISSIONS_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
