import { roundFloat } from '../utils/numbers'
/**
 * Class to create a Coordinate
 */
class Coordinate {
	/**
	 * @param {Number} id Id
	 * @param {String} className Class name
	 * @param {Number} center_x center_x
	 * @param {Number} center_y center_y
	 * @param {Number} upper_left_x upper_left_x
	 * @param {Number} upper_left_y upper_left_y 
	 * @param {Number} lower_right_x lower_right_x 
	 * @param {Number} lower_right_y lower_right_y 
	 * @param {Number} probability probability 
	 */
	constructor(id, area, center_x, center_y, upper_left_x, upper_left_y, lower_right_x, lower_right_y, probability=0) {
		this.id 					 	= Number(id);
		this.area          	= this.roundToCeroOrOne( roundFloat(Number(area)) );
		this.center_x      	= this.roundToCeroOrOne( roundFloat(Number(center_x)) )
		this.center_y      	= this.roundToCeroOrOne( roundFloat(Number(center_y)) )
		this.upper_left_x  	= this.roundToCeroOrOne( roundFloat(Number(upper_left_x)) );
		this.upper_left_y  	= this.roundToCeroOrOne( roundFloat(Number(upper_left_y)) );
		this.lower_right_x	= this.roundToCeroOrOne( roundFloat(Number(lower_right_x)) );
		this.lower_right_y 	= this.roundToCeroOrOne( roundFloat(Number(lower_right_y)) );
		this.probability   	= this.roundToCeroOrOne( roundFloat(Number(probability)) );
	}

	/**
	 * Valid a imagen extension usising 'extensionsImageValid' if the extension is in it return true, else return false
	 * @param  {int}  imgWidth Parent image width 
	 * @param  {int}  imgHeight Parent image height
	 * @return {Array<Number>}  Number Array with four elements: [left, top , width, height]
	 */
	getParamsForFabricPerceptorTag(imgWidth, imgHeight){ 
		let left  	= this.upper_left_x * imgWidth
		let top   	= this.upper_left_y * imgHeight
		let width 	= Math.abs(this.upper_left_x - this.lower_right_x) * imgWidth
		let height	= Math.abs(this.upper_left_y - this.lower_right_y) * imgHeight
		return [left, top, width, height]
	}

	/**
	 * Round number: if is less to 0 return 0, if is bigger than 1 return 1 else return same number
	 * @param  {int}  num Parent image width
	 * @return {int}  Number between 0 and 1
	 */
	roundToCeroOrOne(num){
		if(num<0)
			return 0
		else if(num>1)
			return 1
		else 
			return num   
	}
}

export { Coordinate };
