import React from 'react';

const NotFound = () => {
	return ( 
		<div>
			<img className='d-flex align-items-center justify-content-center not-found-img' 
				src={process.env.PUBLIC_URL + '/assets/not-found.svg'} alt='Not found'
			/>
		</div>
	);
}
 
export default NotFound;
