/**
 * Async Load image from url param
 * @param {String} url Url image 
 * @returns Image on success or null on error
 */
export async function loadImageFromUrl(url) {
	let img = null;
	const imageLoadPromise = new Promise(resolve => {
		img = new Image();
		img.src = url;
			img.onload = (e) => {
			resolve();
		};
		img.onerror = (e) => {
			// console.error(`Error al cargar la imagen [${url}]`, e)
			img = null; //set images as null
			resolve();
		};
	});

	await imageLoadPromise;

	return img;
}
