import { StyleSheet } from '@react-pdf/renderer';

const colors = {
	geppBlue: '#004B99',
	lightBlue: '#C9DAF8'
}

const normalText = {
	fontSize: 8,
	paddingTop: 3,
	paddingBottom: 3,
};

const boldTex = {
	fontSize: 8,
	fontFamily: 'Helvetica-Bold',
	paddingTop: 3,
	paddingBottom: 3,
};

const columnData = {
	padding: 2,
	width: '50%'
};

const flexWrap = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap'
};

// Create styles
const styles = StyleSheet.create({
	// Page styles
	page: {
		padding: 30
	},

	// View styles
	mainView: {
		borderTop: `1px solid ${colors.geppBlue}`,
		borderLeft: `1px solid ${colors.geppBlue}`,
		borderRight: `1px solid ${colors.geppBlue}`
	},

	// Header styles
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	columnHeader: {
		width: '50%',
		padding: 10
	},
	headerTitle: {
		fontSize: 13,
		fontFamily: 'Helvetica-Bold'
	},
	headerLogo: {
		width: '140px'
	},
	headerDate: {
		...normalText,
		textAlign: 'right'
	},

	// Section styles
	section: {
		backgroundColor: colors.geppBlue,
		color: 'white'
	},
	sectionText: {
		fontSize: 10,
		fontFamily: 'Helvetica-Bold',
		padding: 3
	},

	// Table styles
	flexWrap: {
		...flexWrap
	},

	// Text styles
	normalText: {
		...normalText
	},
	boldTex: {
		...boldTex
	},

	// Column data styles
	columnData: {
		...columnData
	},

	// Store data column styles
	columnStoreData: {
		...flexWrap,
	},
	columnRegionData: {
		padding: 2,
		width: '20%'
	},

	// Indicators styles
	indicators: {
		borderTop: `1px solid ${colors.geppBlue}`
	},
	indicatorsGroup: {
		...boldTex,
		borderBottom: `1px solid ${colors.geppBlue}`,
		backgroundColor: colors.lightBlue,
		padding: 4
	},
	indicatorsTable: {
		...flexWrap,
		paddingVertical: 4
	},
	indicatorsCol: {
		paddingLeft: 3,
		width: '20%'
	},
	indicatorsColName: {
		paddingLeft: 2,
		paddingBottom: 1,
		width: '55%'
	},
	indicatorsColScore: {
		paddingBottom: 1,
		width: '5%'
	},

	// Payload content styles
	table: {
		...flexWrap,
		borderBottom: `1px solid ${colors.geppBlue}`
	},
	tableColumn: {
		width: '50%'
	},
	tableContentHeader: {
		backgroundColor: colors.lightBlue,
		paddingLeft: 3
	},
	tableContentRow: {
		flexDirection: 'row',
		borderTop: `1px solid ${colors.geppBlue}`
	},
	tableContentNameCell: {
		...columnData,
		textAlign: 'left',
		marginLeft: 3
	},
	tableContentCell: {
		...columnData,
		marginTop: 3
	},
	tableContentItemsCell: {
		...flexWrap,
		marginLeft: 3
	}
});

export { styles, colors	};
