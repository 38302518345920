import getSubmoduleInfoByName from './getSubmoduleInfoByName';

const parsePayloadData = (screens = [], data = {}, parent = null) => {
  let payloadData = [];
  
  Object.keys(data).forEach((key, idx) => {
    let payloadObj = {};
    
    if (Array.isArray(data[key])){ // es un array?
      data[key].forEach((element, i) => {
        if (typeof element === 'object') {
          payloadObj[`${key}-${i}`] = {
            items: parsePayloadData(screens, element.item, key)
          }

          payloadObj[`${key}-${i}`]['photos'] = [];
          if (element.photos !== null) {
            element.photos.forEach((photo, ind) => {
              payloadObj[`${key}-${i}`]['photos'].push(parsePayloadData(screens, {image: photo}, key)[0]);
            })
          }
        }
      });

      payloadData.push(payloadObj)
    }
    else if (typeof data[key] === 'object'){ // es un objeto?
      if (data[key] !== null) {
        if (data[key]['photo_types'] !== null){
          let submodule = getSubmoduleInfoByName(
            screens,                                    // screens
            key,                                        // key
            Object.values(data[key]['photo_types'])[0], // photo_types object
            true,                                       // has photo_types
            parent                                      // parent
          );
          
          payloadObj['photo_type'] = submodule;
        }
        if (data[key]['image'] !== null) {
          payloadObj[key] = data[key]['image']['$oid'];
        }

        payloadData.push(payloadObj);
      }
    }
    else{ // es una key
      let submodule = getSubmoduleInfoByName(
        screens,    // screens
        key,        // key
        data[key],  // photo_types object
        false,      // has photo_types
        parent      // parent
      );
      
      payloadObj[key] = submodule
      payloadData.push(payloadObj);
    }
  });

  return payloadData
}

export default parsePayloadData;
