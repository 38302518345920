import React, { useEffect, useState } from 'react';

// Packages
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

const Loading = (props) => {
	const { msg } = props;

	const spinnerColor = localStorage.getItem('theme') === 'dark' ? '#1a4e77' : '#004982';

	const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	return (
		<div className='d-flex align-items-center justify-content-center' style={{ height: '88.7vh' }}>
			{msg && <p className='m-0 spinner-text'>{msg}</p>}
			<div className={msg && 'spinner-margin'}>
				<ClipLoader color={spinnerColor} size={width < 800 ? 40 : 55} speedMultiplier={1} cssOverride={{ 'borderWidth': 'thick' }}/>
			</div>
		</div>
	)
}

Loading.propTypes = {
	msg: PropTypes.string
}

export default Loading;
