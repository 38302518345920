import Axios from 'axios';

import {
	PROJECT_LOADING_TRUE,
	PROJECT_LOADING_FALSE,
	ALL_PROJECTS_GET,
	ALL_PROJECTS_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const get_all_projects = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/projects?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_PROJECTS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_PROJECTS_ERROR,
			payload: { type: 'all_projects', msg: err.message }
		})
	})
}
