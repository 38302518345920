import {
	PROJECT_LOADING_TRUE,
	PROJECT_LOADING_FALSE,
	ALL_PROJECTS_GET,
	ALL_PROJECTS_ERROR
} from '../actions/types';

const initialState = {
	project_loading: false,
	all_projects: [],
	error: { isError: false, type: '', msg: '' }
}

export default function projectReducer(state = initialState, action){
	switch(action.type){
		case PROJECT_LOADING_TRUE:
			return {
				...state,
				project_loading: true
			}
		case PROJECT_LOADING_FALSE:
			return {
				...state,
				project_loading: false
			}
		case ALL_PROJECTS_GET:
			return {
				...state,
				all_projects: action.payload
			}
		case ALL_PROJECTS_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
